import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HashStr } from 'src/app/helper/hash-str';
import { _methods } from 'src/app/Models/NftPrivateSale'
import { PublicSaleService } from './public-sale.service';
@Component({
  selector: 'app-public-sale',
  templateUrl: './public-sale.component.html',
  styleUrls: ['./public-sale.component.scss']
})

export class PublicSaleComponent implements OnInit {
  // BNB Current Price 
  public BnbPrice = 625;

  public connected = false;
  public error: any = null;
  public wallet: string = "";
  public url: string = "";
  public referral: string = ""
  public refUserWallet: string = "";
  public isResponse = false;

  // Total and Remaining  (remianing came from the contract)
  public betaTotal: number = 70000;
  public betaRemaining: number = 70000;
  public alphaTotal: number = 5100;
  public alphaRemaining: number = 5100;
  public genesisTotal: number = 1000;
  public genesisRemaining: number = 1000;
  public immortalTotal: number = 50;
  public immortalRemaning: number = 50;
  // Ending of Total and Remaining

  //increase by Section 
  public betaIncreaseBy = 1;
  public alphaIncreaseBy = 1;
  public genesisIncreaseBy = 1;
  public immortalIncreaseBy = 1;

  //end increase by section
  private BETA: string = "beta";
  private ALPHA: string = "alpha";
  private GENESIS: string = "genesis"
  private IMMORTAl: string = "immortal";
  //count section 
  public betaCount: number = 0;
  public alphaCount: number = 0;
  public genesisCount: number = 0;
  public immortalCount: number = 0;
  //end count section

  //LOA TOKEN calculation
  public loaTokenBeta: number = 0;
  public loaTokenAlpha: number = 0;
  public loaTokenGenesis: number = 0;
  public loaTokenImmortal: number = 0;
  //end LOA TOKEN calculation

  //section to m by 
  public betaMBy = 71.42;
  public alphaMBy = 980.39;
  public genisisMBy = 4000;
  public immortalMBy = 80000;
  // end section m by

  //pricing section 
  public betaPrice = 31.25;
  public alphaPrice = 312.5;
  public genesisPrice = 1000;
  public immortalPrice = 10000;


  //Smart Contract Purchase values
  public betaAmout = 0;
  public alphaAmout = 0;
  public genesisAmout = 0;
  public immortalAmout = 0;

  //LOA TOKEN calculation
  public loaTokenBetaAmount: number = 0;
  public loaTokenAlphaAmount: number = 0;
  public loaTokenGenesisAmount: number = 0;
  public loaTokenImmortalAmount: number = 0;
  //end LOA TOKEN calculation


  // UI Var
  public fiveDigit: string = "";
  public lastSixDigit: string = "";

  //Smart Contract 
  public nftContract: any;

  //countdown vars
  private currentTime: any;
  public stillGoing: boolean = true;

  //items date time
  public betaTime = 1639008000000;
  public alphaTime = 1638835200000;
  public genesisTime = 1638662400000;
  public immortalTime = 1638489600000;
  public targetTime = 0;

  //can sell item 
  public betaCan = false;
  public alphaCan = false;
  public genesisCan = false;
  public immortalCan = false;
  public currentSelling = "";
  public sellingNow = "";
  public canByImmortal = false;
  constructor(private hashStr: HashStr, private service: PublicSaleService, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.betaCount = 1;
    this.alphaCount = 1;
    this.genesisCount = 1;
    this.immortalCount = 1;
    this.loaTokenBeta = this.betaCount * this.betaMBy;
    this.loaTokenAlpha = this.alphaCount * this.alphaMBy;
    this.loaTokenGenesis = this.genesisCount * this.genisisMBy;
    this.loaTokenImmortal = this.immortalCount * this.immortalMBy;
    this.counDown();
    if (this.getUserWallet() != null) {
      this.connected = true;
      this.connectWallet();
      this.wallet = this.getUserWallet();
      this.fiveDigit = this.wallet.slice(0, 7);
      this.lastSixDigit = this.wallet.slice(this.wallet.length - 7, this.wallet.length);

      this.onWalletChangeEvent();
      this.getContract();

    }


  }

  getContract = async () => {
    this.nftContract = await this.service.loadContract();
    await this.requestValues();
  }

  /**
  * @param wallet string User wallet
  * @dev saving the wallet in the localStorage (hashed)
  * @return void
  */
  setUserWallet(wallet: string) {
    localStorage.setItem('__W__', this.hashStr.encrypt(wallet));
  }

  /**
   * @dev getting the wallet from the localStorage
   * and decrypted it and return it as normal string
   * @return String Wallet | null 
   */
  getUserWallet() {
    if (localStorage.getItem('__W__')) {
      var base = atob(localStorage.getItem('__W__'))
      var decrypted = this.hashStr.decrypt(base)
      return decrypted
    }
    return null;
  }

  /**
  * @dev trigger this event only when user change account on metamask
  * @return void
  */
  onWalletChangeEvent = async () => {

    window.ethereum.on('accountsChanged', async (accounts) => {
      this.setUserWallet(accounts[0]);
      this.wallet = this.getUserWallet();
      this.fiveDigit = this.wallet.slice(0, 7);
      this.lastSixDigit = this.wallet.slice(this.wallet.length - 7, this.wallet.length);
      location.reload();
    });

  }
  /**
   * @daprecated we're not using this for now ..
   * @param value Daprecated function for input
   */
  onChange(value: any) {

  }

  /**
    * @param type Determen NFT Type for Plus 
    * @return void
    */
  betaPlus(type: string) {
    switch (type) {
      case this.BETA:
        if (this.betaCount < this.betaRemaining) {
          this.betaCount += this.betaIncreaseBy;
          this.loaTokenBeta = this.betaCount * this.betaMBy;
        } else if (this.betaRemaining == 0) {
          this.betaCount = 0;
          return;
        } break;

      case this.ALPHA:
        if (this.alphaCount < this.alphaRemaining) {
          this.alphaCount += this.alphaIncreaseBy;
          this.loaTokenAlpha = this.alphaCount * this.alphaMBy;
        } else if (this.alphaRemaining == 0) {
          this.alphaCount = 0;
          return;
        } break;

      case this.GENESIS:
        if (this.genesisCount < this.genesisRemaining) {
          this.genesisCount += this.genesisIncreaseBy;
          this.loaTokenGenesis = this.genesisCount * this.genisisMBy;
        } else if (this.genesisRemaining == 0) {
          this.genesisCount = 0;
          return;
        } break;

      case this.IMMORTAl:
        if (this.immortalCount < this.immortalRemaning) {
          this.immortalCount += this.immortalIncreaseBy;
          this.loaTokenImmortal = this.immortalCount * this.immortalMBy;
        } else if (this.immortalRemaning == 0) {
          this.immortalCount = 0;
        } break;
    }
  }

  /**
   * @param type Determen NFT Type for Minus 
   * @return void
   */
  betaMinus(type: string) {
    switch (type) {
      case this.BETA:
        if (this.betaCount > this.betaIncreaseBy) {
          this.betaCount -= this.betaIncreaseBy;
          this.loaTokenBeta = this.betaCount * this.betaMBy;
        } else if (this.betaRemaining == 0) {
          this.betaCount = 0;
        } break;

      case this.ALPHA:
        if (this.alphaCount > this.alphaIncreaseBy) {
          this.alphaCount -= this.alphaIncreaseBy;
          this.loaTokenAlpha = this.alphaCount * this.alphaMBy;
        } else if (this.alphaRemaining == 0) {
          this.alphaCount = 0;
          return;
        } break;

      case this.GENESIS:
        if (this.genesisCount > this.genesisIncreaseBy) {
          this.genesisCount -= this.genesisIncreaseBy;
          this.loaTokenGenesis = this.genesisCount * this.genisisMBy;
        } if (this.genesisRemaining == 0) {
          this.genesisCount = 0;
          return;
        } break;

      case this.IMMORTAl:
        if (this.immortalCount > this.immortalIncreaseBy) {
          this.immortalCount -= this.immortalIncreaseBy;
          this.loaTokenImmortal = this.immortalCount * this.immortalMBy;
        } else if (this.immortalRemaning == 0) {
          this.immortalCount = 0;
        } break;
    }
  }

  /**
   * @dev Cick Buy based on the NFT Type
   * @param type Determen NFT Type
   */

  async onBtnClickListner(type: string) {

    switch (type) {
      case this.BETA:
        if (!this.betaCan) { this.toastr.success('opening soon'); break; }
        if (this.betaRemaining > 0) {
          await this.service.sendContractRequest(this.nftContract, _methods.buyBeta, this.wallet, Number((this.betaPrice / this.BnbPrice) * this.betaCount), [this.betaCount]);
          location.reload()
        }
      case this.ALPHA:
        if (!this.alphaCan) { this.toastr.success('opening soon'); break; }
        if (this.alphaRemaining > 0) {
          await this.service.sendContractRequest(this.nftContract, _methods.buyAlpha, this.wallet, (this.alphaPrice / this.BnbPrice) * this.alphaCount, [this.alphaCount]);
          location.reload()
        }
      case this.GENESIS:
        if (!this.genesisCan) { this.toastr.success('opening soon'); break; }
        if (this.genesisRemaining > 0) {
          await this.service.sendContractRequest(this.nftContract, _methods.buyGenesis, this.wallet, (this.genesisPrice / this.BnbPrice) * this.genesisCount, [this.genesisCount]);
          location.reload()
        }
      case this.IMMORTAl:
        if (!this.canByImmortal) { this.toastr.success('opening soon'); break; }
        if (this.immortalRemaning > 0) {
          await this.service.sendContractRequest(this.nftContract, _methods.buyImmortal, this.wallet, (this.immortalPrice / this.BnbPrice) * this.immortalCount, [this.immortalCount])
          location.reload()
        }
    }
  }


  /**
   * @dev connecting the wallet logic 
   * calling the service to check if the user
   * trying to connect BSC Mainnet
   * if not then just switch to BSC. 
   * @return void 
   */
  async connectWallet() {

    if (this.getUserWallet() != null) { return }
    var res = await this.service.connectWallet();
    if (res.status) {
      this.connected = true;
      this.wallet = res.wallet;
      this.fiveDigit = this.wallet.slice(0, 7);
      this.lastSixDigit = this.wallet.slice(this.wallet.length - 7, this.wallet.length);
      this.setUserWallet(this.wallet)
      location.reload();
    } else {
      this.connected = false;
      console.log('Not Connected.');
    }
  }

  /**
   * @dev get the value from the Smart contract
   */
  requestValues = async () => {
    //Items total suplly
    this.immortalRemaning = await this.service.callContract(this.nftContract, _methods.itemSupply, this.wallet, [0]);
    if (this.immortalRemaning == 0) this.immortalCount = 0;
    this.genesisRemaining = await this.service.callContract(this.nftContract, _methods.itemSupply, this.wallet, [1]);
    if (this.genesisRemaining == 0) this.genesisCount = 0;
    this.alphaRemaining = await this.service.callContract(this.nftContract, _methods.itemSupply, this.wallet, [2]);
    if (this.alphaRemaining == 0) this.alphaCount = 0;
    this.betaRemaining = await this.service.callContract(this.nftContract, _methods.itemSupply, this.wallet, [3]);
    if (this.betaRemaining == 0) this.betaCount = 0;

    //capsule purchased 
    this.betaAmout = await this.service.callContract(this.nftContract, _methods.itemOwned, this.wallet, [3, this.wallet]) ?? 0;
    this.alphaAmout = await this.service.callContract(this.nftContract, _methods.itemOwned, this.wallet, [2, this.wallet]) ?? 0;
    this.genesisAmout = await this.service.callContract(this.nftContract, _methods.itemOwned, this.wallet, [1, this.wallet]) ?? 0;
    this.immortalAmout = await this.service.callContract(this.nftContract, _methods.itemOwned, this.wallet, [0, this.wallet]) ?? 0;

    //LOA Amout 
    this.loaTokenBetaAmount = this.betaAmout * this.betaMBy ?? 0;
    this.loaTokenAlphaAmount = this.alphaAmout * this.alphaMBy ?? 0;
    this.loaTokenGenesisAmount = this.genesisAmout * this.genisisMBy ?? 0;
    this.loaTokenImmortalAmount = this.immortalAmout * this.immortalMBy ?? 0;
  }
  async getCurrentTime() {
    if (+new Date() < this.immortalTime) {
      this.betaCan = false;
      this.genesisCan = false;
      this.alphaCan = false;
      this.immortalCan = true;
      return this.immortalTime;
    } else if (+new Date() >= this.immortalTime && +new Date() < this.genesisTime) {
      this.betaCan = false;
      this.genesisCan = false;
      this.alphaCan = false;
      this.immortalCan = true;
      this.canByImmortal = true;
      return this.genesisTime;
    } else if (+new Date() >= this.genesisTime && +new Date() < this.alphaTime) {

      this.betaCan = false;
      this.genesisCan = true;
      this.alphaCan = false;
      this.immortalCan = true;

      return this.alphaTime;
    } else if (+new Date() >= this.alphaTime && +new Date() < this.betaTime) {

      this.betaCan = false;
      this.genesisCan = true;
      this.alphaCan = true;
      this.immortalCan = true;

      return this.betaTime;
    } else if (+new Date() >= this.betaTime) {
      this.betaCan = true;
      this.genesisCan = true;
      this.alphaCan = true;
      this.immortalCan = true;

    }
    return this.betaTime;

  }
  async counDown() {

    setInterval(async () => {
      if ((+new Date() > this.betaTime)) {
        this.betaCan = true;
        this.genesisCan = true;
        this.alphaCan = true;
        this.immortalCan = true;
        this.stillGoing = false;
        return;
      }
      this.targetTime = await this.getCurrentTime();
      let now = new Date().getTime();
      let timeleft = this.targetTime - now;
      this.currentTime = +new Date()



      // Calculating the days, hours, minutes and seconds left
      let day = Math.floor(timeleft / (1000 * 60 * 60 * 24));
      let hour = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minute = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
      let second = Math.floor((timeleft % (1000 * 60)) / 1000);

      document.getElementById("day").innerText = String(day);
      document.getElementById("hour").innerText = String(hour);
      document.getElementById("minute").innerText = String(minute);
      document.getElementById("second").innerText = String(second);
    }, 1000);
  }
}