import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HashStr } from 'src/app/helper/hash-str';

import * as AOS from "aos";
import { ReferralService } from '../referral/referral.service';

@Component({
  selector: 'app-presaletest',
  templateUrl: './presaletest.component.html',
  styleUrls: ['./presaletest.component.scss']
})
export class PresaletestComponent implements OnInit {
  public connected = false;
  public error: any = null;
  public wallet: string = "";
  public url: string = "";
  public referral: string = ""
  public refUserWallet: string = "";
  public user: User;
  public refs: User[] = [];
  public isResponse = false;
  public LOA: number = 0;
  public follow: number = 0;
  public qulifid: boolean = false;
  public isTrusted: boolean = false;
  public showBox: boolean = false;
  public _decimals: number = 1000000000000000000;
  public target: number = 100000;
  public totalCap: number = 1
  public leftOver: number = 0;
  public myLoa: number = 0;;
  constructor(private hashStr: HashStr, private service: ReferralService, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getTotalCommitted();
    this.getTotalDepositBNB();

  }
  showSuccess() {
    navigator.clipboard.writeText(this.url);
    var input = document.createElement('input');
    input.setAttribute('value', "this.url");
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    this.toastr.success('Link Copied!');
  }


  setUserWallet(wallet: string) {
    localStorage.setItem('__W__', this.hashStr.encrypt(wallet));
  }
  getUserWallet() {
    if (localStorage.getItem('__W__')) {
      var base = atob(localStorage.getItem('__W__'))
      var decrypted = this.hashStr.decrypt(base)
      return decrypted
    }
    return null;
  }

  getUser = async () => {
    let res: any = await this.service.getUserPreslae(this.wallet);
    this.user = res;
    this.refs = this.user.refs;
    this.setUserWallet(this.user.wallet_address)
    if (this.refs.length > 0) {
      this.isResponse = true;
    }
    if (this.refs.length > 9) {
      this.qulifid = true;
    }
    this.url = "https://leagueofancients.com/presale/?viHash=" + this.createUrl();
  }

  createUrl = () => {
    return this.hashStr.encrypt(this.wallet);
  }

  public async resolved(captchaResponse: string) {


  }

  homepage() {
    window.location.href = "https://leagueofancients.com"
  }
  scrolTo() {
    var elmnt = document.getElementById('invite');
    elmnt.scrollIntoView();
  }

  onError(e: any) {
    this.isTrusted = false;
    console.log(e);
  }

  withdow() {
    this.toastr.success('Withdrawal only available after Listing on Pancakeswap. Vesting schedule applies.');
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  connectWallet = async () => {
    // this.scrolTo();
    var res = await this.service.connectAccount();

    if (res.status) {
      this.connected = true;
      this.wallet = res.wallet;
      var obj = {};
      if (this.refUserWallet != "" || this.refUserWallet != undefined || this.refUserWallet != null) {
        obj = {
          wallet_address: res.wallet,
          referral_link: this.createUrl(),
          presale_user_id: this.refUserWallet
        }
      } else {
        obj = {
          wallet_address: res.wallet,
          referral_link: this.createUrl(),
        }
      }
      this.createUser(obj);

    } else {
      console.log(this.error, 'error')
    }
  }
  website() {
    window.location.href = "https://league-of-ancients-v2.gitbook.io/whitepaper/"

  }
  witepaper() {
    window.location.href = "https://leagueofancients.com"
  }
  createUser = async (obj: any) => {
    let res: any = await this.service.createUserPresale(obj)
    if (res.msg == "User already exsist") {
      this.getUser()
    } else if (res.msg == "exceeded referral limit") {
      this.toastr.success('Congratulations! You have been whitelisted');
      await this.delay(5);
      localStorage.clear();
      window.location.href = "https://leagueofancients.com/referral";
      return;
    } else if (res.msg == "Scam Request") {
      this.toastr.error('Your wallets will be deleted for trying to scam');
      await this.delay(5);
      localStorage.clear();
      window.location.href = "https://leagueofancients.com/referral";
      return;
    }

    this.user = res;
    if (this.user.id != undefined) {
      this.setUserWallet(this.user.wallet_address)
      this.url = "https://leagueofancients.com/referral/?vHash=" + this.createUrl();
    }
  }
  home() {
    window.location.href = "https://leagueofancients.com/";
  }
  showUrl() {
    this.showBox = true;
  }

  async getBalance() {
    // let res: any = await this.service.contract()
    // console.log(res);
  }


  /**
   *                          ====TEST CODE ====
   *                     LOA TEST PAGE LOGIC GOES HERE
   *
   *
   */

  public targetAchive: number = 0;
  public bnbAmout: string = "";
  public totalCommitted: number = 0;
  public LOAToken: number = 0;
  public totalDepositBNB: number = 0;
  public totalUnlocked: number = 0;
  public totalRefunded: number = 0;
  public totalDays: number = 0;
  public convertToLoa: number = 0;

  //Deposit BNB to contract
  async depositBNB() {
    try {
      await this.service.deposit(this.bnbAmout);
      this.toastr.show('Deposit ' + this.bnbAmout + ' Successfully');
      this.LOAToken = Number(this.bnbAmout) / 0.05
    } catch (e) {
      console.log(e);
    }

  }

  // var max = 100;
  // var deposit = 1000;
  // var de2 = 10;


  // var total = de2 + deposit;

  // var leftOver = total - max;

  // var overPaid = (deposit * leftOver) / total;

  // console.log('overpaid', overPaid);

  async getTotalCommitted() {
    try {
      let response: any = await this.service.getTotalDepositByAddress();
      this.totalCommitted = response / this._decimals;

    } catch (e) {
      console.log(e);
    }
  }

  async claimLoa() {
    try {
      let response = await this.service.claimLoa();
      console.log(response);
    } catch (e) {
      console.log(e);
    }
  }


  onSearchChange(value) {
    console.log(value)
    this.convertToLoa = (Number(value) * 600) / 0.05;
  }

  async getTotalDepositBNB() {
    try {
      let response: any = await this.service.getTotalDeposit();
      this.totalDepositBNB = response / this._decimals;
      this.LOAToken = (Number(this.totalDepositBNB) * 600) / 0.05
      //4 - 1
      this.leftOver = this.totalDepositBNB - this.totalCap;
      var overPaid = (this.totalCommitted * this.leftOver) / this.totalDepositBNB;
      console.log(overPaid, 'over paid X___');
      var myBnB = this.totalCommitted - overPaid;
      this.myLoa = myBnB * 12000;
      console.log(this.myLoa, 'myLoa X___X');

      console.log(this.LOAToken);
      if (this.totalDepositBNB > this.target) {
        this.targetAchive = this.totalDepositBNB / this.target * 100;

      }
    } catch (e) {
      console.log(e);
    }
  }

  cancel() {
    this.bnbAmout = "";
  }


}
interface User {
  id: number;
  wallet_address: string;
  ref_user_id: string;
  referral_link: string;
  refs: User[];
  created_at: string;
  event_3x: number;
}

class User implements User {


}
