import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  isShown = false;
  constructor() { }

  ngOnInit(): void {
  }
  home() {
    window.location.href = 'https://leagueofancients.com';
  }
}
