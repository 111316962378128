import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HashStr } from 'src/app/helper/hash-str';
import { _methods } from 'src/app/Models/NftPrivateSale'
import { PrivateSaleService } from './private-sale.service';
import * as AOS from 'aos';

@Component({
  selector: 'app-private-sale',
  templateUrl: './private-sale.component.html',
  styleUrls: ['./private-sale.component.scss']
})
export class PrivateSaleComponent implements OnInit {
  // BNB Current Price
  public BnbPrice = 625;

  public connected = false;
  public error: any = null;
  public wallet: string = "";
  public url: string = "";
  public referral: string = ""
  public refUserWallet: string = "";
  public isResponse = false;

  // Total and Remaining  (remianing came from the contract)
  public betaTotal: number = 15100;
  public betaRemaining: number = 15100;
  public alphaTotal: number = 825;
  public alphaRemaining: number = 825;
  public genesisTotal: number = 670;
  public genesisRemaining: number = 670;
  public immortalTotal: number = 0;
  public immortalRemaning: number = 0;
  // Ending of Total and Remaining

  //increase by Section
  public betaIncreaseBy = 1;
  public alphaIncreaseBy = 1;
  public genesisIncreaseBy = 1;
  public immortalIncreaseBy = 1;

  //end increase by section
  private BETA: string = "beta";
  private ALPHA: string = "alpha";
  private GENESIS: string = "genesis"
  private IMMORTAl: string = "immortal";
  //count section
  public betaCount: number = 0;
  public alphaCount: number = 0;
  public genesisCount: number = 0;
  public immortalCount: number = 0;
  //end count section

  //LOA TOKEN calculation
  public loaTokenBeta: number = 0;
  public loaTokenAlpha: number = 0;
  public loaTokenGenesis: number = 0;
  public loaTokenImmortal: number = 0;
  //end LOA TOKEN calculation

  //section to m by
  public betaMBy = 125;
  public alphaMBy = 1500;
  public genisisMBy = 5000;
  public immortalMBy = 50000;
  // end section m by

  //pricing section
  public betaPrice = 0.1;
  public alphaPrice = 1;
  public genesisPrice = 3.2;
  public immortalPrice = 10000;


  //Smart Contract Purchase values
  public betaAmout = 0;
  public alphaAmout = 0;
  public genesisAmout = 0;
  public immortalAmout = 0;

  //LOA TOKEN calculation
  public loaTokenBetaAmount: number = 0;
  public loaTokenAlphaAmount: number = 0;
  public loaTokenGenesisAmount: number = 0;
  public loaTokenImmortalAmount: number = 0;
  //end LOA TOKEN calculation


  //can sell item
  public betaCan = true;
  public alphaCan = true;
  public genesisCan = true;
  public immortalCan = true;

  // UI Var
  public fiveDigit: string = "";
  public lastSixDigit: string = "";

  //Smart Contract
  public nftContract: any;

  //countdown vars
  private currentTime: any;
  public stillGoing: boolean = true;


  @ViewChild("second") second: ElementRef;
  @ViewChild("day") day: ElementRef;
  @ViewChild("hour") hour: ElementRef;
  @ViewChild("minute") minute: ElementRef;
  constructor(private hashStr: HashStr, private service: PrivateSaleService, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit(): void {
    AOS.init();
    this.betaCount = 1;
    this.alphaCount = 1;
    this.genesisCount = 1;
    this.immortalCount = 1;
    this.loaTokenBeta = this.betaCount * this.betaMBy;
    this.loaTokenAlpha = this.alphaCount * this.alphaMBy;
    this.loaTokenGenesis = this.genesisCount * this.genisisMBy;
    this.loaTokenImmortal = this.immortalCount * this.immortalMBy;
    this.counDown();
    if (this.getUserWallet() != null) {
      this.connected = true;
      this.connectWallet();
      this.wallet = this.getUserWallet();
      this.fiveDigit = this.wallet.slice(0, 7);
      this.lastSixDigit = this.wallet.slice(this.wallet.length - 7, this.wallet.length);

      this.onWalletChangeEvent();
      this.getContract();
    }


  }

  getContract = async () => {
    this.nftContract = await this.service.loadContract();
    await this.requestValues();
  }

  /**
  * @param wallet string User wallet
  * @dev saving the wallet in the localStorage (hashed)
  * @return void
  */
  setUserWallet(wallet: string) {
    localStorage.setItem('__W__', this.hashStr.encrypt(wallet));
  }

  /**
   * @dev getting the wallet from the localStorage
   * and decrypted it and return it as normal string
   * @return String Wallet | null
   */
  getUserWallet() {
    if (localStorage.getItem('__W__')) {
      var base = atob(localStorage.getItem('__W__'))
      var decrypted = this.hashStr.decrypt(base)
      return decrypted
    }
    return null;
  }

  /**
  * @dev trigger this event only when user change account on metamask
  * @return void
  */
  onWalletChangeEvent = async () => {

    window.ethereum.on('accountsChanged', async (accounts) => {
      this.setUserWallet(accounts[0]);
      this.wallet = this.getUserWallet();
      this.fiveDigit = this.wallet.slice(0, 7);
      this.lastSixDigit = this.wallet.slice(this.wallet.length - 7, this.wallet.length);
      location.reload();
    });

  }
  /**
   * @daprecated we're not using this for now ..
   * @param value Daprecated function for input
   */
  onChange(value: any) {

  }

  /**
    * @param type Determen NFT Type for Plus
    * @return void
    */
  betaPlus(type: string) {
    switch (type) {
      case this.BETA:
        if (this.betaCount < this.betaRemaining) {
          this.betaCount += this.betaIncreaseBy;
          this.loaTokenBeta = this.betaCount * this.betaMBy;
        } else if (this.betaRemaining == 0) {
          this.betaCount = 0;
          return;
        } break;

      case this.ALPHA:
        if (this.alphaCount < this.alphaRemaining) {
          this.alphaCount += this.alphaIncreaseBy;
          this.loaTokenAlpha = this.alphaCount * this.alphaMBy;
        } else if (this.alphaRemaining == 0) {
          this.alphaCount = 0;
          return;
        } break;

      case this.GENESIS:
        if (this.genesisCount < this.genesisRemaining) {
          this.genesisCount += this.genesisIncreaseBy;
          this.loaTokenGenesis = this.genesisCount * this.genisisMBy;
        } else if (this.genesisRemaining == 0) {
          this.genesisCount = 0;
          return;
        } break;

      case this.IMMORTAl:
        if (this.immortalCount < this.immortalRemaning) {
          this.immortalCount += this.immortalIncreaseBy;
          this.loaTokenImmortal = this.immortalCount * this.immortalMBy;
        } else if (this.immortalRemaning == 0) {
          this.immortalCount = 0;
        } break;
    }
  }

  /**
   * @param type Determen NFT Type for Minus
   * @return void
   */
  betaMinus(type: string) {
    switch (type) {

      case this.BETA:
        if (this.betaCount > this.betaIncreaseBy) {
          this.betaCount -= this.betaIncreaseBy;
          this.loaTokenBeta = this.betaCount * this.betaMBy;
        } else if (this.betaRemaining == 0) {
          this.betaCount = 0;
        } break;

      case this.ALPHA:
        if (this.alphaCount > this.alphaIncreaseBy) {
          this.alphaCount -= this.alphaIncreaseBy;
          this.loaTokenAlpha = this.alphaCount * this.alphaMBy;
        } else if (this.alphaRemaining == 0) {
          this.alphaCount = 0;
          return;
        } break;

      case this.GENESIS:
        if (this.genesisCount > this.genesisIncreaseBy) {
          this.genesisCount -= this.genesisIncreaseBy;
          this.loaTokenGenesis = this.genesisCount * this.genisisMBy;
        } if (this.genesisRemaining == 0) {
          this.genesisCount = 0;
          return;
        } break;

      case this.IMMORTAl:
        if (this.immortalCount > this.immortalIncreaseBy) {
          this.immortalCount -= this.immortalIncreaseBy;
          this.loaTokenImmortal = this.immortalCount * this.immortalMBy;
        } else if (this.immortalRemaning == 0) {
          this.immortalCount = 0;
        } break;
    }
  }

  /**
   * @dev Cick Buy based on the NFT Type
   * @param type Determen NFT Type
   */

  async onBtnClickListner(type: string) {
    
    if (this.stillGoing) { this.toastr.success('NFT public sales haven\'t started yet!'); return; }
    switch (type) {
      case this.BETA:
        if (this.betaRemaining > 0) {
          await this.service.sendContractRequest(this.nftContract, _methods.buyBeta, this.wallet, Number(this.betaPrice * this.betaCount), [this.betaCount]);
          location.reload()
        }
      case this.ALPHA:
        if (this.alphaRemaining > 0) {
          await this.service.sendContractRequest(this.nftContract, _methods.buyAlpha, this.wallet, Number(this.alphaPrice * this.alphaCount), [this.alphaCount]);
          location.reload()
        }
      case this.GENESIS:
        if (this.genesisRemaining > 0) {
          await this.service.sendContractRequest(this.nftContract, _methods.buyGenesis, this.wallet, Number(this.genesisPrice * this.genesisCount), [this.genesisCount]);
          location.reload()
        }
      case this.IMMORTAl: if (this.immortalRemaning > 0) {
        await this.service.sendContractRequest(this.nftContract, _methods.buyImmortal, this.wallet, (this.immortalPrice / this.BnbPrice) * this.immortalCount, [this.immortalCount])
        location.reload()
      }
    }
  }


  /**
   * @dev connecting the wallet logic
   * calling the service to check if the user
   * trying to connect BSC Mainnet
   * if not then just switch to BSC.
   * @return void
   */
  async connectWallet() {
    if (this.getUserWallet() != null) { return }
    var res = await this.service.connectWallet();
    if (res.status) {
      this.connected = true;
      this.wallet = res.wallet;
      this.fiveDigit = this.wallet.slice(0, 7);
      this.lastSixDigit = this.wallet.slice(this.wallet.length - 7, this.wallet.length);
      this.setUserWallet(this.wallet)
      location.reload();
    } else {
      this.connected = false;
      console.log('Not Connected.');
    }
  }

  /**
   * @dev get the value from the Smart contract
   */
  requestValues = async () => {
    //Items total suplly
    this.immortalRemaning = await this.service.callContract(this.nftContract, _methods.itemSupply, this.wallet, [0]);
    if (this.immortalRemaning == 0) this.immortalCount = 0;
    this.genesisRemaining = await this.service.callContract(this.nftContract, _methods.itemSupply, this.wallet, [1]);
    if (this.genesisRemaining == 0) this.genesisCount = 0;
    this.alphaRemaining = await this.service.callContract(this.nftContract, _methods.itemSupply, this.wallet, [2]);
    if (this.alphaRemaining == 0) this.alphaCount = 0;
    this.betaRemaining = await this.service.callContract(this.nftContract, _methods.itemSupply, this.wallet, [3]);
    if (this.betaRemaining == 0) this.betaCount = 0;

    //capsule purchased
    this.betaAmout = await this.service.callContract(this.nftContract, _methods.itemOwned, this.wallet, [3, this.wallet]) ?? 0;
    this.alphaAmout = await this.service.callContract(this.nftContract, _methods.itemOwned, this.wallet, [2, this.wallet]) ?? 0;
    this.genesisAmout = await this.service.callContract(this.nftContract, _methods.itemOwned, this.wallet, [1, this.wallet]) ?? 0;
    this.immortalAmout = await this.service.callContract(this.nftContract, _methods.itemOwned, this.wallet, [0, this.wallet]) ?? 0;

    //LOA Amout
    this.loaTokenBetaAmount = this.betaAmout * this.betaMBy ?? 0;
    this.loaTokenAlphaAmount = this.alphaAmout * this.alphaMBy ?? 0;
    this.loaTokenGenesisAmount = this.genesisAmout * this.genisisMBy ?? 0;
    this.loaTokenImmortalAmount = this.immortalAmout * this.immortalMBy ?? 0;
  }
  counDown() {

    setInterval(() => {
      let targetTime = 1648728000000;
      let now = new Date().getTime();
      let timeleft = targetTime - now;
      this.currentTime = +new Date()
      if (this.currentTime > targetTime) {
        this.stillGoing = false;
        return;
      }

      // Calculating the days, hours, minutes and seconds left
      let day = Math.floor(timeleft / (1000 * 60 * 60 * 24));
      let hour = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minute = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
      let second = Math.floor((timeleft % (1000 * 60)) / 1000);

      this.day.nativeElement.innerText = String(day);
      this.hour.nativeElement.innerText = String(hour);
      this.minute.nativeElement.innerText = String(minute);
      this.second.nativeElement.innerText = String(second);
    }, 1000);
  }
}
