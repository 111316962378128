<section>
    <div id="pre-home" class="col-12 m-0 row">

        <!-- Social Media -->
        <div class="col-md-4 col-4 text-center pt-4 head">



            <a class="m-1" href="https://t.me/leagueofancients">
                <img class="w-5" src="../../../assets/png/Asset-SMTG.png" alt="" />
            </a>
            <a class="m-1" href="https://discord.gg/pkuVvrhucR">
                <img class="w-5" src="../../../assets/png/Asset-SMDC.png" alt="" />
            </a>
            <a class="m-1" href="https://web.facebook.com/leagueofancientsofficial/">
                <img class="w-5" src="../../../assets/png/Asset-SMFB.png" alt="" />
            </a>
            <a class="m-1" href="https://www.instagram.com/leagueofancientsofficial/">
                <img class="w-5" src="../../../assets/png/Asset-SMIG.png" alt="" />
            </a>
            <a class="m-1" href="https://twitter.com/LOAOfficialMOBA/">
                <img class="w-5" src="../../../assets/png/Asset-SMTW.png" alt="" />
            </a>
            <a class="m-1" href="  https://www.youtube.com/c/LeagueofAncientsOfficial">
                <img class="w-5" src="../../../assets/png/Asset-SMYT.png" alt="" />
            </a>

        </div>
        <!-- Logo -->
        <div class=" col-md-4 col-6 pt-2 text-center">
            <img (click)="home()" class="w-100 logo" src="../../../assets/png/Asset_Header_Logo.png" alt="" />
        </div>
        <!-- Connect wallet -->
        <div class="col-md-4 col-6 text-center pt-4 btnDiv">

            <button *ngIf="!connected" (click)="connectWallet()" class="btn walletButton"><i
                    class="fas fa-wallet walletIcon">
                </i> Connect
                wallet</button>
            <!-- <div *ngIf="isTrusted" class="col-6 re-captcha text-center">

                <re-captcha (error)="onError($event)" (resolved)="resolved($event)"
                    siteKey="6LeqRvgcAAAAAK7CsxTFZg-MVg8rUABPVrEpvkCx" errorMode="handled">
                </re-captcha>
            </div> -->
            <button *ngIf="connected" (click)="connectWallet()" class="btn walletButtonConnected"><i
                    class="fas fa-wallet walletIconConnected">
                </i> {{wallet| slice:0:9}}....</button>

        </div>

        <div class="text-center">
            <img class="w-50" src="assets\png\Presale_Asset-Title.png" alt="">
            <h6 class="font-sm __font mobile_ds">2,000,000 $LOA Token at $0.05, now open for registration </h6>

            <a href="presale#inviteFriends" *ngIf="!connected">
                <button class="btn walletButton my-5" data-aos="zoom-in" data-aos-duration="2000">
                    LEARN HOW</button>
            </a>

        </div>
    </div>

</section>
<section *ngIf="!connected" id="inviteFriends">
    <div class="col-12 text-center header_text pt-4">
        <h1 class="header_para" data-aos="fade-up" data-aos-duration="1000">JOIN THE PRESALE <br> TODAY!</h1>

        <h6 class="font-sm __font mobile_ds" data-aos="fade-up" data-aos-duration="2000">Participate in the greatest
            event of the year by completing the simple
            steps below . Qualifiers
            <br> will be selected from those who have completed the taske!
        </h6>
    </div>

    <div class="row justify-content-center mt-5">
        <div class="col-md-4 col-12  mt-2">
            <div class="border-card">
                <div class="col-12 text-center">
                    <p class="position-absolute m-0">1</p>
                    <img class="w-75" src="assets\png\ASSET_How To-3.png" alt="">
                    <h5 class="text-white text-capitalize" style="font-size:18px !important;">
                        Perform tasks and connect Wallet
                    </h5>
                    <h6 class="font-sm __font mobile_ds">
                        Connect a wallet and generate your referral link in the Referral section
                    </h6>
                </div>
            </div>
        </div>

        <div class="col-md-4 col-12  mt-2">
            <div class="border-card">
                <div class="col-12 text-center">
                    <p class="position-absolute m-0">2</p>
                    <img class="w-75" src="assets\png\ASSET_How To-4.png" alt="">
                    <h5 class="text-white text-capitalize">
                        Get A Referral link
                    </h5>
                    <h6 class="font-sm __font mobile_ds">
                        Invite your friends to register via <br> your referral link
                    </h6>
                </div>
            </div>
        </div>

    </div>

    <div class="text-center">
        <button class="btn walletButton mt-5" data-aos="zoom-in" data-aos-duration="2000"><i
                class="fas fa-wallet walletIcon">
            </i> GET STARTED</button>
    </div>

</section>


<section *ngIf="connected" id="summary">

    <div class="col-12 text-center header_text pt-4">
        <h1 class="header_para">START INVITING</h1>
        <h6 class="font-sm __font mobile_ds">Participate in the greatest event of the year by completing the simple
            steps below.
        </h6>

    </div>


    <div>
        <div class="row  justify-content-center mt-5">
            <div class="col-12 col-md-6 mt-2">
                <div class="border-card">
                    <h5 class="text-white text-capitalize">
                        My Referral Link
                    </h5>


                    <button id="link" (click)="showSuccess()" class="btn inviteButtonConnected mt-1 mb-2">
                        {{url | slice: 0:33}}...<i class="far fa-2x fa-copy align-right"></i></button><br>
                    <span class=" xs-font font-sm __font">Start inviting your friends & family to connect wallet in <br>
                        order
                        to be qualified for our Pre-Sale Event !</span>

                    <h5 class="text-white text-capitalize mt-3">
                        My Status
                    </h5>
                    <button *ngIf="!qulifid" class="btn buttonStartInv mt-1 mb-2">
                        NOT QUALIFIED
                    </button>
                    <button *ngIf="qulifid" class="btn buttonStartInv mt-1 mb-2" style="background-color: #22B573;">
                        QUALIFIED</button>

                </div>

            </div>
            <div class="col-12 col-md-6 mt-2">
                <div class="border-card ">
                    <div class="row">
                        <div class="col-md-6">
                            <h5 class="text-white text-capitalize">
                                total Friends
                            </h5>
                            <h1 class="l-font">{{refs.length}}/10</h1>
                        </div>
                        <div class="col-md-6 col-scroll">

                            <ol *ngIf="isResponse">
                                <li *ngFor="let ref of refs; index as i">{{ref.wallet_address|
                                    slice:0:6}}...{{ref.wallet_address| slice:4:10}}</li>
                            </ol>
                            <span *ngIf="!isResponse">Start inviting with your unique referral code!</span>
                        </div>


                    </div>


                </div>
            </div>
            <div class="text-center pt-3">
                <h2 (click)="showUrl()" class="font_a"> having issue getting the referral code?</h2>
                <div *ngIf="showBox">
                    <textarea name="" id="" cols="30" rows="10">
                        {{url}}
                    </textarea>
                </div>
            </div>
        </div>

    </div>




</section>
<section id="date">
    <div class="col-12 text-center header_text pt-4">
        <h1 class="header_para" data-aos="fade-up" data-aos-duration="1000">DATE OF PRE-SALE WILL BE <br> ANNOUNCED IN
            OUR<br>
            OFFICIAL CHANNELS</h1>
    </div>


    <div class="col-md-4 col-12 text-center pt-4 m-auto ">



        <a class="m-1" href="https://t.me/leagueofancients">
            <img class="w-5" src="../../../assets/png/Asset-SMTG.png" alt="" />
        </a>
        <a class="m-1" href="https://discord.gg/pkuVvrhucR">
            <img class="w-5" src="../../../assets/png/Asset-SMDC.png" alt="" />
        </a>
        <a class="m-1" href="https://web.facebook.com/leagueofancientsofficial/">
            <img class="w-5" src="../../../assets/png/Asset-SMFB.png" alt="" />
        </a>
        <a class="m-1" href="https://www.instagram.com/leagueofancientsofficial/">
            <img class="w-5" src="../../../assets/png/Asset-SMIG.png" alt="" />
        </a>
        <a class="m-1" href="https://twitter.com/LOAOfficialMOBA/">
            <img class="w-5" src="../../../assets/png/Asset-SMTW.png" alt="" />
        </a>
        <a class="m-1" href="  https://www.youtube.com/c/LeagueofAncientsOfficial">
            <img class="w-5" src="../../../assets/png/Asset-SMYT.png" alt="" />
        </a>

    </div>

</section>

<section id="official">
    <div class="row">
        <div class="col-12 col-md-6">

            <iframe class="video m-0 w-100 p-0 " src="https://www.youtube.com/embed/h1NFYM13_qo"></iframe>

        </div>
        <div class="col-12 col-md-6">
            <div class=" header_text mt-5">
                <h1 class="header_para ">THE LEAGUE <br> OF ANCIENTS <br>OFFICIAL TRAILER </h1>

                <h6 class="font-sm __font mobile_ds mt-4">
                    BSC's First #FreetoPlay 5v5 MOBA<br> with NFT marketplace. Running on <br>$BinanceSmartChain
                </h6>

                <button (click)="witepaper();" data-aos="zoom-in" data-aos-duration="2000"
                    class="btn walletButton mt-4">
                    LEARN MORE</button>
            </div>
        </div>

    </div>

    <div class="" style="padding: 66px;"></div>
    <div class="row">
        <div class="col-12 col-md-6  order-2 order-md-1 ">
            <div class=" header_text mt-5">
                <h1 class="header_para ">MEET THE DOERS <br> & BUILDERS</h1>

                <h6 class="font-sm __font mobile_ds mt-4">
                    The team always plays an important role in the success of a project. As a team, we have been working
                    with each other for more than 6 years, with everyone being highly experienced in their own expertise
                </h6>

                <button class="btn walletButton mt-4" data-aos="zoom-in" data-aos-duration="2000">
                    LEARN MORE</button>
            </div>
        </div>
        <div class="col-12 col-md-6  order-1 order-md-2">

            <iframe class="video m-0 w-100 p-0 bo" src="https://www.youtube.com/embed/PMjeWA0cIxI"></iframe>

        </div>


    </div>
</section>
<app-footer></app-footer>