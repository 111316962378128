<div id="main">
  <div class="col-12 row m-0 justify-content-between">
    <div class="col-md-3 col-6">
      <img class="w-100" src="assets/png/Asset_Header_Logo.png" alt="LOA" />
    </div>
    <div class="col-md-3 text-center col-6">
      <button *ngIf="!connected" (click)="connectWallet()" class="btn button-border mt-4">
        <i class="fas fa-wallet walletIcon"> </i> Connect wallet
      </button>
      <button *ngIf="connected" class="btn button-border mt-4">
        <i class="fas fa-wallet walletIcon"> {{fiveDigit}}...{{lastSixDigit}} </i>
      </button>
    </div>
  </div>
  <section>
    <div class="mainBlock col-md-10 col-12 text-center m-auto">
      <img class="w-100" src="assets/png/tokenPresaleAsset/PresaleAsset_Title.png" alt="" />
      <h6 class="main-sub-title mt-5">
        2,000,000 $LOA TOKENS AT $0.05,<br />
        NOW UP FOR GRABS!
      </h6>
    </div>
  </section>
</div>

<section id="detail">
  <canvas [ngStyle]="{'display': !showCelebrate ? 'none' : '' }" id="confetti"></canvas>
  <div class="card text-center">
    <div class="row">
      <h5 class="display-5 card__desc--orange text-center fw-bolder">
        Whitelist Checker
      </h5>

      <div class="card__divider"></div>
      <div class="col-12">
        <input (input)="searchWhileList(whiteList)" [(ngModel)]="whiteList" type="text"
          placeholder="eg: 0x1111111111111111111111.." id="search" name="Search">
      </div>
      <div class="col-12 mt-2">
        <h4 [ngStyle]="{'color': !showCelebrate ? 'red' : 'green' }">{{_result}}</h4>
      </div>
      <div class="col-12 text-center ">
          <h4><a target="_blank" href="https://leagueofancients.medium.com/how-to-get-loa-during-whitelist-presale-3be9ec6c56fe">How to participate</a></h4>
      </div>
      
    </div>
  </div>

  <div class="card text-center mt-4">
    <div class="row">
      <div class="col-12 col-md-6 text-center">
        <span>Total Commited </span>
        <h5 class="display-5 card__desc--orange text-center fw-bolder">
          ${{totalCommitted}}
        </h5>
        <span>/ ${{tryingToRaise}}</span>
      </div>
      <div class="col-12 col-md-6 text-center">
        <span>% Target Achieved</span>
        <h5 class="card__desc--orange display-5 text-center fw-bolder">
          {{targetAchivePercentage}}%
        </h5>
      </div>
    </div>
    <div class="card__divider mt-2"></div>
    <div class="row">
      <div class="col-md-6 col-12">
        <span> Open Time :</span>
        <h5 class="card__desc--orange d-md-inline">
          21 Nov 2021 UTC 00:00
        </h5>
      </div>
      <div class="col-md-6 col-12 text-md-center">
        <span> End Time :</span>
        <h5 class="card__desc--orange d-md-inline">
          22 Nov 2021 UTC 00:00
        </h5>
      </div>
    </div>
  </div>


  <div *ngIf="!connected" class="card-img position-relative card mt-5">
    <!-- <div class="row justify-content-between">
      <h3 class="col-12 col-md-8 card__title--bold">Your Investment</h3>
    </div>
    <div class="card__divider"></div> -->

    <!-- ===OR=== -->
    <div class="d-md-flex d-block justify-content-between">
      <h3 class="col-12 col-md-8 card__title--bold">24,653 Whitelisted</h3>

      <button (click)="viewAll()" class="col-10 col-md-4 w-auto btn button-fill-white mb-3">
        View All
      </button>
    </div>
    <div class="card__divider"></div>

    <h5 class="display-5 card__desc--orange text-center fw-bolder">
      1BNB = 12800 $LOA
    </h5>

    <span class="italic text-center">(1BNB = 640 USD as per 21 November 2021)</span>

    <div class="card__divider"></div>

    <div class="row justify-content-end">
      <div class="ml-auto col-12 col-md-6">
        <div>
          <h5 class="card__desc--orange margin_rem text-h text-center text-md-start">
            Please connect your wallet<br />
            and set network to <br />
            Binance smart chain.
          </h5>
        </div>

      </div>
    </div>

    <img class="img-back" src="assets/png/tokenPresaleAsset/PresaleAsset-06.png" alt="" />
  </div>


  <!--  ! =========if after =================-->
  <div *ngIf="connected" class="card-img position-relative card mt-5">
    <!-- <div class="row justify-content-between">
      <h3 class="col-12 col-md-8 card__title--bold">Your Investment</h3>
    </div>
    <div class="card__divider"></div> -->

    <!-- ===OR=== -->
    <div class="d-md-flex d-block justify-content-between">
      <h3 class="col-12 col-md-8 card__title--bold">24,653 Whitelisted</h3>

      <button (click)="viewAll()" class="col-10 col-md-4 w-auto btn button-fill-white mb-3">
        View All
      </button>
    </div>
    <div class="card__divider"></div>

    <h5 class="display-5 card__desc--orange text-center fw-bolder">
      1BNB = 12800 $LOA
    </h5>

    <span class="italic text-center">(1BNB = 640 USD as per 21 November 2021)</span>

    <div class="card__divider"></div>

    <div class="row col-12 m-0  justify-content-end">
      <div class="ml-auto col-12 col-md-6">


        <!-- ! =========if after ================= -->

        <div>
          <div class="row mt-4">
            <div class="col-md-6 col-12">
              <div class="d-flex">
                <img class="card__logo" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />

                <div class="col">
                  <span>$LOA Subscribed</span>
                  <h5 class="card__desc--orange">{{myLoa}}</h5>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="d-flex">
                <img class="card__logo" src="assets/png/tokenPresaleAsset/PresaleAsset_BNB_Icon.png" alt="" />

                <div class="col">
                  <span>$BNB Deposited</span>
                  <h5 class="card__desc--orange">{{myBnb}}</h5>
                </div>
              </div>
            </div>

            <div class="mt-3 card__box--white">


              <div class="d-flex justify-content-between mt-3">
                <h5 class="card__desc--orange mt-auto">{{convertedLoaToBnB}}</h5>
                <div class="d-flex">
                  <img class="card__logo" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />
                  <h5 class="bold m-auto">$LOA</h5>
                </div>

              </div>
            </div>

            <div class="mt-3 card__box--white">
              <div class="d-flex justify-content-between">


                <strong> Balance: {{walletBalance}} BNB</strong>
                <a (click)="useMaxBalance()" class="ml-auto pointer text-black fw-bolder">MAX</a>
              </div>
              <div class=" d-flex justify-content-between mt-3">
                <input (input)="onSearchChange(bnbAmout)" [(ngModel)]="bnbAmout" type="number" placeholder="0.5 BNB"
                  id="LOA" name="LOATOKEN">
                <div class="d-flex">
                  <img class="card__logo" src="assets/png/tokenPresaleAsset/PresaleAsset_BNB_Icon.png" alt="" />
                  <h5 class="bold m-auto">BNB</h5>
                </div>

              </div>
            </div>

            <div class="d-flex mt-3 justify-content-center mobile_margin ">
              <button (click)="cancel()" class="btn button-border btn-border-orange mobile_btn_hight">
                Cancel
              </button>
              <button (click)="deposit()" class=" m-3 btn button-fill-white btn-fill-orange">
                Deposit
              </button>
            </div>

          </div>
        </div>

        <!-- !==========end -->
      </div>
    </div>

    <img class="img-back" src="assets/png/tokenPresaleAsset/PresaleAsset-06.png" alt="" />
  </div>

  <section *ngIf="connected" class="card mt-5">
    <div class="row">
      <div class="col-12 col-md-6 text-center">
        <h3 class="card__title--bold text-center">Balance </h3>
        <h5 class="card__desc--orange "> total $LOA purchased {{loaLeft}}<br>  Total $LOA Claimed:  {{totalClaim}}  <br> over 200 days <br> Available to Claim: {{avilableToClaim}}</h5>

        <!-- <h5 class="card__desc--orange ">Day {{daily}}/200</h5> -->

        <button (click)="claimLoa()" class="btn button-fill-white button-mutte mt-2">Claim</button>


      </div>
      <div class="col-12 col-md-6 text-center d-grid border-left">

        <h3 class="card__title--bold text-center">BNB Refunded</h3>
        <h1 class="card__desc--orange display-4">{{this.overPaid}}</h1>






      </div>
    </div>
  </section>


  <div class="card-img position-relative card mt-5">
    <!-- <div class="row justify-content-between">
      <h3 class="col-12 col-md-8 card__title--bold">Your Investment</h3>
    </div>
    <div class="card__divider"></div> -->

    <!-- ===OR=== -->


    <h5 class="display-5 card__desc--orange text-center fw-bolder">
      Instructions & Rules
    </h5>



    <div class="card__divider"></div>

    <div class="row justify-content-end">
      <div class="ml-auto col-12 col-md-6">
        <div class="pb">
          <span class="card__desc--orange margin_rem text-center">
            1. Only wallet addresses that have been whitelisted are able to participate in this LOA Pre-Sale event.<br>
            2. Users will need to connect their Metamask wallets on the “Binance Smart Chain” network to
            participate.<br>
            3. There is no maximum limit for the amount of BNB to be deposited for subscription during the Pre-Sale
            event.<br>
            4. League of Ancients reserves the rights to end the Pre-Sale event earlier than the stipulated end date and
            time.<br>
            5. Oversubscription refunds will be made available after the LOA Pre-Sale event ends.<br>
            6. Participants are not allowed to withdraw their BNB after deposits during the Pre-Sale event.<br>
            7. No refunds will be made (except for oversubscriptions) on deposits.<br>
            8. Number of $LOA Tokens successfully subscribed by user based on deposit made, will only be confirmed after
            the LOA Pre-Sale event.<br>
            9. $LOA Tokens will only be available for claim by user (if successfully subscribed) after the $LOA Token
            Generation Event (TGE).<br>
            10. $LOA Tokens that are obtained via Pre-Sale will be vested linearly for 200 days and to be released daily
            after $LOA Token Generation Event (TGE).<br>
          </span>
        </div>

      </div>
    </div>

    <img class="img-back" src="assets/png/tokenPresaleAsset/PresaleAsset-06.png" alt="" />
  </div>

  <div class="card mt-5">
    <div class="row justify-content-between">
      <h3 class="col-12 col-md-8 card__title--bold">Important Links</h3>
    </div>

    <div class="card__divider"></div>
    <div class="row">
      <div class="col-md-6 col-12">
        <div>
          <span> Token Contract Address </span>
          <h5 class="card__desc--orange">
            0x94b69263FCA20119Ae817b6f783Fc0F13B02ad50
          </h5>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div>
          <span> Presale Contract Address </span>
          <h5 class="card__desc--orange">
            0x9e36107edA8e9Ce9EEb5d3D34c13CACE25bc4f12
          </h5>
        </div>
      </div>
    </div>
    <div class="row justify-content-between">
      <h3 class="col-12 col-md-8 card__title--bold">Audit Report</h3>
    </div>
    <div class="card__divider"></div>
    <div class="row">
      <div class="col-md-6 col-12">
        <div>
          <img class="w-100" src="assets/png/tokenPresaleAsset/PresaleAsset_Certik.png" alt="" />
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="mt-3">
          <span> Verified By Certik </span>
          <h5 class="card__desc--orange">
           <a href=" https://league-of-ancients-v2.gitbook.io/whitepaper/pre-sales-and-nft-sales">Audit Report</a>
          </h5>
        </div>
      </div>
    </div>
  </div>
  <div class="card mt-5">
    <div class="row justify-content-between">
      <h3 class="col-12 col-md-8 card__title--bold">Important Links</h3>
    </div>

    <div class="card__divider"></div>
    <div class="row">
      <div class="col-md-6 col-12">
        <div>
          <span> Token Contract Address </span>
          <h5 class="card__desc--orange">
            0x94b69263FCA20119Ae817b6f783Fc0F13B02ad50
          </h5>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div>
          <span> Presale Contract Address </span>
          <h5 class="card__desc--orange">
            0x9e36107edA8e9Ce9EEb5d3D34c13CACE25bc4f12
          </h5>
        </div>
      </div>
    </div>
    <div class="row justify-content-between">
      <h3 class="col-12 col-md-8 card__title--bold">Audit Report</h3>
    </div>
    <div class="card__divider"></div>
    <div class="row">
      <div class="col-md-6 col-12">
        <div>
          <img class="w-100" src="assets/png/callisto-badge.png" alt="" />
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="mt-3">
          <span> Verified By Callisto Security Audit </span>
          <h5 class="card__desc--orange">
           <a href=" https://gist.github.com/yuriy77k/b29cddc54c6db11f43be6f8af20b42f9">Audit Report</a>
          </h5>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>