<div id="main">
  <app-nav></app-nav>
  <section>

    <div class="text-center">
      <h1 class="header_para mt-5">
        MEET THE DOERS <br />
        & BUILDERS
      </h1>
      <h6 class="font-sm __font mobile_ds">
        Meet our team of warriors & professional advisors, ready to harness the
        force for good.
      </h6>
    </div>
  </section>
</div>




<section id="coderTeam">
  <div class="text-center">
    <h1 class="header_para">THE CORE TEAM</h1>

    <div class="row">
      <div class="item col-md-4 col-12" *ngFor="let item of listTeam">
        <img class="w-100" [src]="item.img" alt="" />

        <h3 class="item__name--bold">{{item.name}}</h3>

        <p class="item__desc--bold">
          {{item.des}}
        </p>
      </div>

    </div>
  </div>
</section>
<section id="advisors">
  <div class="text-center">
    <h1 class="header_para">THE ADVISORS</h1>

    <div class="row">
      <div class="item col-md-4 col-12">
        <img class="w-100" src="assets/png/Advisor/Asset_advisor-4.png" alt="" />

        <h3 class="item__name--bold">Jin Tan</h3>

        <p class="item__desc--bold">
          Chief Executive Officer <br>of Monsta Infinite
        </p>
      </div>
      <div class=" item col-md-4 col-12">
        <img class="w-100" src="assets/png/Advisor/Asset_advisor-5.png" alt="" />

        <h3 class="item__name--bold">Jef Ong
        </h3>

        <p class="item__desc--bold">
          Founder & Chief Innovative <br>
          Officer of Flexiroam Limited
        </p>
      </div>
      <div class="item col-md-4 col-12">
        <img class="w-100" src="assets/png/Advisor/Asset_advisor-6.png" alt="" />

        <h3 class="item__name--bold">Fabian Tan

        </h3>

        <p class="item__desc--bold">
          Principal Architect at <br>
          AMAZON Web Services
        </p>
      </div>
    </div>
  </div>
</section>
<section id="join" class="text-center">
  <div class="col-12 text-center header_text pt-4">
    <h1 class="header_para">JOIN THE ARMY TODAY!</h1>
    <h6 class="font-sm __font mobile_ds">Building the largest MOBA-Verse NFT Game</h6>
  </div>

  <button (click)="sendCv()" class="btn button-border mt-5"> SEND RESUME</button>


</section>

<app-footer></app-footer>