<section>
    <div id="pre-home" class="col-12 m-0 row">

        <!-- Social Media -->
        <div class="col-md-4 col-4 text-center pt-4 head">



            <a class="m-1" href="https://t.me/leagueofancients">
                <img class="w-5" src="../../../assets/png/Asset-SMTG.png" alt="" />
            </a>
            <a class="m-1" href="https://discord.gg/pkuVvrhucR">
                <img class="w-5" src="../../../assets/png/Asset-SMDC.png" alt="" />
            </a>
            <a class="m-1" href="https://web.facebook.com/leagueofancientsofficial/">
                <img class="w-5" src="../../../assets/png/Asset-SMFB.png" alt="" />
            </a>
            <a class="m-1" href="https://www.instagram.com/leagueofancientsofficial/">
                <img class="w-5" src="../../../assets/png/Asset-SMIG.png" alt="" />
            </a>
            <a class="m-1" href="https://twitter.com/LOAOfficialMOBA/">
                <img class="w-5" src="../../../assets/png/Asset-SMTW.png" alt="" />
            </a>
            <a class="m-1" href="  https://www.youtube.com/c/LeagueofAncientsOfficial">
                <img class="w-5" src="../../../assets/png/Asset-SMYT.png" alt="" />
            </a>

        </div>
        <!-- Logo -->
        <div class=" col-md-4 col-6 pt-2 text-center">
            <img class="w-100 logo" src="../../../assets/png/Asset_Header_Logo.png" alt="" />
        </div>
        <!-- Connect wallet -->
        <div class="col-md-4 col-6 text-center pt-4 btnDiv">

            <button *ngIf="!connected" (click)="connectWallet()" class="btn walletButton"><i
                    class="fas fa-wallet walletIcon">
                </i> Connect
                wallet</button>
            <!-- <div *ngIf="isTrusted" class="col-6 re-captcha text-center">

                <re-captcha (error)="onError($event)" (resolved)="resolved($event)"
                    siteKey="6LeqRvgcAAAAAK7CsxTFZg-MVg8rUABPVrEpvkCx" errorMode="handled">
                </re-captcha>
            </div> -->


        </div>



        <div class="col-md-4 col-6 text-center pt-4 btnDiv">
            <button *ngIf="connected" (click)="getBalance()" class="btn walletButtonConnected"><i
                    class="fas fa-wallet walletIconConnected">
                </i> {{wallet| slice:0:9}}....</button>
        </div>

        <div class="text-center">
            <img class="w-50" src="assets\png\Presale_Asset-Title.png" alt="">
            <h6 class="font-sm __font mobile_ds">2,000,000 $LOA Token at $0.05, now open for registration </h6>

            <a href="presale#inviteFriends" *ngIf="!connected">
                <button class="btn walletButton my-5" data-aos="zoom-in" data-aos-duration="2000">
                    LEARN HOW</button>
            </a>

        </div>
    </div>

</section>

<section class="pt-5 pb-5">
    <div class="container text-center">
        <div class="row text-center">
            <div class="col-12 demoDiv">
                <h4 class="demoFont">2,000,000 $LOA TOKEN PRE-SALE EVENT @ $0.05/token</h4>
            </div>
            <div class="col-6 demoDiv">
                <div class="text-center">
                    <h4 class="demoFont"> TOTAL COMMITTED</h4>
                </div>
                <div class="text-center ">
                    <h4 class="demoFont">${{totalDepositBNB * 600}}/$600</h4>
                </div>
            </div>
            <div class="col-6 demoDiv">
                <div class="text-center">
                    <h4 class="demoFont">% TARGET ACHIEVED
                    </h4>
                </div>
                <div class="text-center">
                    <h4 class="demoFont">{{targetAchive}}%
                    </h4>
                </div>
            </div>
            <div class="col-12 demoDiv">
                <h4 class="demoFont">13,000 WHITELISTED PARTICIPANTS (link to results)</h4>
            </div>
            <div class="col-12 demoDiv">
                <h4 class="demoFont">0.1BNB = 1200 $LOA <br>
                    (price quoted as at 14/11/2021 1BNB=$600)</h4>
            </div>

            <div class="col-6 demoDiv pt-1">
                <div class="demoDiv text-center ">
                    <h4 class="demoFont">{{LOAToken}}</h4>
                </div>

                <div class="text-center demoDiv pt-1">
                    <h4 class="demoFont">
                        $LOA SUBSCRIBED
                    </h4>
                </div>
                <div class="demoDiv text-center ">
                    <h4 class="demoFont">{{totalCommitted}}</h4>
                </div>

                <div class="text-center demoDiv pt-1">
                    <h4 class="demoFont">
                        $BNB DEPOSITED
                    </h4>
                </div>
            </div>
            <div class="col-6 demoDiv pt-1">
                <div class="text-center demoDiv">
                    <div class="text-center">
                        <h4 class="demoFont">{{convertToLoa}} &nbsp;&nbsp;&nbsp; LOA</h4>
                    </div>

                </div>
                <div class="text-center demoDiv">
                    <div class="text-center">
                        <div class="text-center">
                            <input (input)="onSearchChange(bnbAmout)" [(ngModel)]="bnbAmout" type="number"
                                placeholder="0.5 BNB" id="LOA" name="LOATOKEN">
                            <h4 class="demoFont">BNB</h4>
                        </div>
                    </div>

                </div>
                <div>
                    <button (click)="cancel()" type="button" class="btn btn-secondary">CANCEL</button>
                    &nbsp;
                    <button (click)="depositBNB()" type="button" class="btn btn-warning">DEPOSIT</button>
                </div>
            </div>
            <div class="col-6 demoDiv">
                <div class="text-center ">
                    <h4 class="demoFont">OPEN TIME 18/11//2021 UTC 00:00:00</h4>
                </div>
            </div>
            <div class="col-6 demoDiv">
                <div class="text-center ">
                    <div class="text-center ">
                        <h4 class="demoFont">END TIME 19/11/2021 UTC 00:00:00</h4>
                    </div>
                </div>

            </div>
            <div class="col-6 demoDiv">
                <div class="text-center ">
                    <h4 class="demoFont">TOTAL: $LOA {{myLoa}}
                    </h4>
                </div>
                <div class="text-center ">
                    <h4 class="demoFont">{{totalUnlocked}}/300 UNLOCKED
                    </h4>
                </div>
            </div>
            <div class="col-6 demoDiv">
                <div class="text-center ">
                    <div class="text-center ">
                        <h4 class="demoFont">TOTAL BNB REFUNDED
                        </h4>
                    </div>
                    <div class="text-center ">
                        <h4 class="demoFont">{{totalRefunded}}
                        </h4>
                    </div>
                </div>

            </div>
            <div class="col-6 demoDiv">
                <div>
                    <h4 class="demoFont">DAY {{totalDays}}/200</h4>


                    <h4 class="demoFont">{{totalUnlocked}} $LOA</h4>


                    <button (click)="claimLoa()" type="button" class="btn btn-warning">CLAIM</button>
                </div>

            </div>
        </div>
    </div>

</section>
<app-footer></app-footer>