<!-- <p class="text-black">referral works!</p> -->
<section class="overflow-hidden" id="ref">

  <a href="../" class="m-auto d-flex">
    <img role="button" src="../../../assets/png/Asset_Header_Logo.png" alt="" class="m-auto logo" width="263px !important">

  </a>
  <section>
    <div class="container main-sec ">
      <div class="row">
        <div class="col-md-8 col-12 d-flex align-items-center index">

          <div>
            <h1 class=" display-4 font-weight-bold cinzel-bold ">REFERRAL PROGRAM</h1>
            <P class="font-sm-g mt-3">Refer friends and earn LOA tokens! Connect your wallet to begin.</P>

            <!-- <button class="btn btn-gold  mt-3" (click)="onConnectWallet()">Connect wallet</button> -->
            <div class="button-container-1" *ngIf="!wallet" (click)="btnConnectWallet()">
              <span class="mas">Connect wallet</span>
              <button id='work' type="button" name="Hover">Connect wallet</button>
            </div>

            <div *ngIf="wallet">
              <div class="copy-wallet">
                <p class="font-sm-g mb-0"> {{ url | slice: 0:50 }}...</p>


                <div (click)="copy() ; ">
                  <i class="fa fa-clone hvr-pulse-shrink ms-2 text-gold" ngxClipboard [cbContent]="url" #tooltip="matTooltip" [matTooltip]="isDisabled?'copy link':'copyed!'" disabled role="button"
                    aria-hidden="true"></i>
                </div>


              </div>
              <div class="button-container-1" (click)="showSumm()">
                <span class="mas">VIEW SUMMARY</span>
                <button id='summ' type="button" name="Hover">VIEW SUMMARY</button>
              </div>
            </div>

          </div>

        </div>
        <div class="col-md-4 col-12 position-relative">
          <img class="main-img" src="assets\images\referral\coin.png" alt="">
        </div>
      </div>
    </div>
  </section>

  <section class="join-sec" *ngIf="!wallet" id="followUsDiscord">

    <div class="container pt-md-5 text-center">
      <h5 class="text-white  cinzel-bold " data-aos="fade-up" data-aos-duration="1000">
        Need help?

      </h5>
      <h1 class="display-5 font-weight-bold cinzel-bold " data-aos="fade-up" data-aos-duration="1000">
        Join our Discord and speak to our friendly moderators!

      </h1>

      <div class=" col-12" data-aos="fade-up" data-aos-duration="2000">

        <!-- <img src="assets\png\Asset-SMDC.png" alt=""> -->
        <img width="289px" class="faa-float animated" src="assets\images\referral\discord icon.png" alt="">
        <br>
        <button (click)="dis() ; " class="btn-outline btn mt-2"> Join Discord!</button>

        <!-- <div class="mt-4"></div> -->
        <!-- <button  class="btn-outline btn mt-2" (click)="btnConnectWallet()"> Get Referral code</button> -->


      </div>

    </div>
  </section>
  <section *ngIf="wallet" class="summary-sec " id="summary">

    <div class="container py-md-5 text-center">

      <h1 class="display-3  font-weight-bold cinzel-bold" data-aos="fade-up" data-aos-duration="1000">SUMMARY</h1>

      <div class="row mt-5">

        <div class="col-md-6 col-12  overflow-hidden" data-aos="fade-up" data-aos-duration="1500">
          <div class="card-sum">


            <h6>TOTAL REFERRAL REWARD</h6>

            <div>
              <h2 class="display-3 font-weight-bold">{{refs.length*10}}</h2>
              <h6>$LOA</h6>

              <button class="btn btn-black mt-3" (click)="withdraw()"> WITHDRAW</button>


            </div>
          </div>
        </div>
        <div class="col-md-6 col-12 overflow-hidden" data-aos="fade-up" data-aos-duration="3000">
          <div class="card-sum">
            <div class="row">
              <div class="col-md-6 ">
                <h6> TOTAL Friends:</h6>
                <h2 class="display-3 font-weight-bold">{{refs.length}}</h2>

              </div>

              <div class="col-md-6 col-scroll">


                <ol *ngIf="isResponse" class="text-white ">
                  <li class="text-start" *ngFor="let ref of refs; index as i">{{ref.wallet_address|
                    slice:0:6}}...{{ref.wallet_address| slice:4:10}}</li>
                </ol>
                <span *ngIf="!isResponse">Start inviting with your unique referral code!</span>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="invite-sec">

    <div class="container pt-md-5 text-center">
      <h5 class="text-white  cinzel-bold " data-aos="fade-up" data-aos-duration="1000">
        HOW TO INVITE
      </h5>
      <h1 class="display-3  font-weight-bold cinzel-bold " data-aos="fade-up" data-aos-duration="1000">FRIENDS</h1>

      <div class="row mt-5">
        <div class="col-md-4 col-12 text-white ">

          <div class="card card-border" data-aos="zoom-in-up" data-aos-easing="linear" data-aos-duration="1000">
            <div class="card-body">
              <img width="80px" class="mb-4 mt-5 faa-float animated" src="assets\images\referral\WEBP\step-1.webp" alt="">

              <h4 class="text-gradient cinzel-bold ">STEP1</h4>

              <h6>Perform Tasks And Connect Wallet</h6>
              <p class="font-sm-g mt-3">Connect a wallet and generate your referral link in the Referral section</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 text-white ">

          <div class="card card-border" data-aos="zoom-in-up" data-aos-easing="linear" data-aos-duration="1000">
            <div class="card-body">
              <img class="mb-4 mt-5 faa-float animated" src="assets\images\referral\WEBP\step-2.webp" alt="">

              <h4 class="text-gradient cinzel-bold ">STEP2</h4>

              <h6>Get A Referral Link</h6>
              <p class="font-sm-g mt-3">Invite your friends to register via
                your referral link</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 text-white ">

          <div class="card card-border" data-aos="zoom-in-up" data-aos-easing="linear" data-aos-duration="1000">
            <div class="card-body">
              <img width="80px" class="mb-4 mt-5 faa-float animated" src="assets\images\referral\WEBP\step-3.webp" alt="">

              <h4 class="text-gradient cinzel-bold ">STEP3</h4>

              <h6>Share And Earn $LOA</h6>
              <p class="font-sm-g mt-3">
                Receive $LOA tokens for every friend who connects their wallet!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>




  <div>

    <button class="btn btn-discord " (click)="dis()">

      <img width="20px" src="../../../assets/png/Asset-SMDC.png" alt="" />
      <br>
      JOIN OUR DISCORD</button>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Your Account</h4>

      <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body modal-sec">
      <div class="text-center" *ngIf="loadingSendEmail">
        <i class=" position-init text-white fa-li fa fa-spinner  faa-spin animated faa-fast"></i>

      </div>
      <form #fEmail="ngForm">
        <label class="text-white mb-2">Gmail account</label>

        <div class="d-flex">
          <div class="w-75 me-2">

            <input class="form-control" type="email" name="email" id="email" placeholder="xxx@gmail.com" [(ngModel)]="email" #emailerror="ngModel" [disabled]="isSendEmail"
              pattern="[a-zA-Z0-9_\.\+-]+@gmail+\.com+" required />
            <div *ngIf="emailerror.invalid && (emailerror.dirty || emailerror.touched)">
              <div *ngIf="emailerror.errors?.required">
                <span class="text-danger">Email is required </span>
              </div>
              <div *ngIf="emailerror.errors?.pattern">
                <span class="text-danger">Invalid EmailL </span>
              </div>
            </div>
          </div>
          <button [disabled]="email=='' ||email==null ||isSendEmail||emailerror.errors?.pattern " (click)="onSendEmail()" class="btn btn-white btn-gold" type="button">Send</button>

        </div>




      </form>


      <ng-container *ngIf="isSendEmail">
        <div class="mt-3">
          <h6>check your email and discord</h6>

        </div>

        <div class="">
          <div class="mt-4 ">
            <label class="text-white mb-2">Enter email code</label>

            <input [(ngModel)]="codeEmail" #codeEmailC="ngModel" [disabled]="loadingSendEmail||isSendCode" class="form-control input-num " type="number">
            <p class="text-muted" style="font-size: 11px !important;
              margin-top: 4px;">
              Note: if you didn’t see the email in the inbox, please check your spam.
            </p>
          </div>
          <div class=" mt-4 ">
            <label class="text-white mb-2">Enter Discord code</label>
            <input [(ngModel)]="codeDiscord" #codeDiscordC="ngModel" [disabled]="loadingSendEmail||isSendCode" class="form-control input-num " type="number">
            <a class="text-white" role="button" (click)="showHowDis=!showHowDis">How to get code?</a>
          </div>
        </div>
        <ng-container *ngIf="showHowDis">
          <img class="box-img" src="assets\images\referral\cod.jpg" alt="">

        </ng-container>



        <div class="text-center mt-4">
          <button [disabled]="!(codeDiscordC.value&&codeEmailC.value) || isSendCode" type="button" class="btn btn-white  btn-gold" (click)="onSaveCode()">Verify</button>

        </div>


      </ng-container>
    </div>

  </ng-template>
</section>


<section style="height: auto !important;background: #16171b;" id="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-lg-4 col-12 col-sm-12 col-xs-12">
        <p class="d-flex justify-content-center mt-3">
          <img class="w-75" src="../../../assets/png/Asset_Footer_Logo.png" alt="" />
        </p>
      </div>
      <div class="col-md-8 col-lg-8 col-12 col-sm-12 col-xs-12 text-left
            m-auto">
        <!-- <div class="row">
                          <div class="col-md-12 col-lg-12 col-12 col-sm-12 col-xs-12">

                          </div>
                      </div> -->
        <h4 class="mt-3 mb-4 footer">FOLLOW US & JOIN THE COMMUNITY!</h4>
        <a class="m-1" href="https://t.me/leagueofancients">
          <img class="w-5" src="../../../assets/png/Asset-SMTG.png" alt="" />
        </a>
        <a class="m-1" href="https://discord.gg/pkuVvrhucR">
          <img class="w-5" src="../../../assets/png/Asset-SMDC.png" alt="" />
        </a>
        <a class="m-1" href="https://web.facebook.com/leagueofancientsofficial/">
          <img class="w-5" src="../../../assets/png/Asset-SMFB.png" alt="" />
        </a>
        <a class="m-1" href="https://www.instagram.com/leagueofancientsofficial/">
          <img class="w-5" src="../../../assets/png/Asset-SMIG.png" alt="" />
        </a>
        <a class="m-1" href="https://twitter.com/LOAOfficialMOBA/">
          <img class="w-5" src="../../../assets/png/Asset-SMTW.png" alt="" />
        </a>
        <a class="m-1" href=" https://www.youtube.com/c/LeagueofAncientsOfficial">
          <img class="w-5" src="../../../assets/png/Asset-SMYT.png" alt="" />
        </a>

        <p class="small-text-footer text-mute mt-4">
          copyright &#169; 2021 - 2023 League Of Ancients All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</section>