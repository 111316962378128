import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partnership',
  templateUrl: './partnership.component.html',
  styleUrls: ['./partnership.component.scss']
})
export class PartnershipComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  listLogo = [
    "assets/png/Logos/investor_logo-01.jpg",
    "assets/png/Logos/investor_logo-02.jpg",
    "assets/png/Logos/investor_logo-03.jpg",
    "assets/png/Logos/investor_logo-04.jpg",
    "assets/png/Logos/investor_logo-05.jpg",
    "assets/png/Logos/investor_logo-06.jpg",
    "assets/png/Logos/investor_logo-07.jpg",
    "assets/png/Logos/investor_logo-08.jpg",
    "assets/png/Logos/investor_logo-09.jpg",
    "assets/png/Logos/investor_logo-10.jpg",

    "assets/png/Logos/investor_logo-11.jpg",
    "assets/png/Logos/investor_logo-12.jpg",
    "assets/png/Logos/investor_logo-13.jpg",
    "assets/png/Logos/investor_logo-14.jpg",
    "assets/png/Logos/investor_logo-15.jpg",
    "assets/png/Logos/investor_logo-16.jpg",
    "assets/png/Logos/investor_logo-17.jpg",
    "assets/png/Logos/investor_logo-18.jpg",
    "assets/png/Logos/investor_logo-19.jpg",
    "assets/png/Logos/investor_logo-20.jpg",


    "assets/png/Logos/investor_logo-21.jpg",
    "assets/png/Logos/investor_logo-22.jpg",
    "assets/png/Logos/investor_logo-23.jpg",
    "assets/png/Logos/investor_logo-24.jpg",
    "assets/png/Logos/investor_logo-25.jpg",
    "assets/png/Logos/investor_logo-26.jpg",
    "assets/png/Logos/investor_logo-27.jpg",
    "assets/png/Logos/investor_logo-28.jpg",
    "assets/png/Logos/investor_logo-29.jpg",
    "assets/png/Logos/investor_logo-30.jpg",



    "assets/png/Logos/investor_logo-31.jpg",
    "assets/png/Logos/investor_logo-32.jpg",
    "assets/png/Logos/investor_logo-33.jpg",
    "assets/png/Logos/investor_logo-34.jpg",
    "assets/png/Logos/investor_logo-35.jpg",
    "assets/png/Logos/investor_logo-36.jpg",
    "assets/png/Logos/investor_logo-37.jpg",
    "assets/png/Logos/investor_logo-38.jpg",
    "assets/png/Logos/investor_logo-39.jpg",
    "assets/png/Logos/investor_logo-40.jpg",


    "assets/png/Logos/investor_logo-41.jpg",
    "assets/png/Logos/investor_logo-42.jpg",
    "assets/png/Logos/investor_logo-43.jpg",
    "assets/png/Logos/investor_logo-44.jpg",
    "assets/png/Logos/investor_logo-45.jpg",
    "assets/png/Logos/investor_logo-46.jpg",
    "assets/png/Logos/investor_logo-47.jpg",
    "assets/png/Logos/investor_logo-48.jpg",
    "assets/png/Logos/investor_logo-49.jpg",
    "assets/png/Logos/investor_logo-50.jpg",



    "assets/png/Logos/investor_logo-51.jpg",
    "assets/png/Logos/investor_logo-52.jpg",
    "assets/png/Logos/investor_logo-53.jpg",
    "assets/png/Logos/investor_logo-54.jpg",
    "assets/png/Logos/investor_logo-55.jpg",
    "assets/png/Logos/investor_logo-56.jpg",
    "assets/png/Logos/investor_logo-57.jpg",
    "assets/png/Logos/investor_logo-58.jpg",
    "assets/png/Logos/gateio.jpeg",
    "assets/png/Logos/cup.png",
    "assets/png/investors/investor_logo-32.jpeg"

  ]
}
