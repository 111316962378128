import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import * as AOS from "aos";
import { DomSanitizer } from '@angular/platform-browser';

declare var Rellax: any;
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  @ViewChild('videoPlayer') videoPlayer: ElementRef;
  current_url

  videoClicked = false;
  isShown = false;
  heros: { id: number, imgCard: string, img: string }[] = [
    { "id": 0, "imgCard": "../../../assets/png/Asset_Hero_Thumbnail-20.png", "img": "../../../assets/png/Asset_Hero_Card.png" },
    { "id": 1, "imgCard": "../../../assets/png/Asset_Hero_Thumbnail-21.png", "img": "../../../assets/png/Asset_Hero_Card2.png" },
    { "id": 2, "imgCard": "../../../assets/png/Asset_Hero_Thumbnail-22.png", "img": "../../../assets/png/Asset_Hero_Card4.png" },
    { "id": 3, "imgCard": "../../../assets/png/Asset_Hero_Thumbnail-23.png", "img": "../../../assets/png/Asset_Hero_Card3.png" }

  ];

  heroText: { id: number, title: string, disc: string, ability: string }[] = [
    {
      "id": 0, title: "KEK THE TOAD", disc: `This Strange character is on the path of revenge, he set on a path to
    invent a mechanical Toad. He will be driving his pet in an attempt to
    destroy the planet and take over Guba. However, he was captured and
    defeated. As punishment, he was exiled into space. He drifted for
    centuries and wound up on the Ancient Realm. Now his reign of
    terror begins again to take over the Ancient Realm.`, ability: 'Melee, Durable, Disabler, Initiator'
    },

    {
      "id": 1, title: "LEON FIST", disc: `Leon was destined to rule the Kingdom of the Wilds after his father passed. He was an honorable man, who only dreamed to continue providing peace, prosperity and harmony to his realm. That is, until the “Red Coronation” occurred. Leon Fist’s world was forever changed, he was struck down, his arm severed and his body discarded, left to die in the sewers of his kingdom. But he was saved, his arm restored and empowered by a raging curse. Now, Leon is hellbent on revenge and nothing will stand in his way. `, ability: 'Melee, Initiator, Durable, Disabler'
    },
    { "id": 2, title: "NEEKOMANI", disc: `Neekomani is quite a mystery, rumor has it that this maiden is an avatar of Dolos, the God of Trickery. To the naked eye, Neekomani is a woman of unrivaled beauty, and therein lies her power. Both a fighter and a lover, if she can’t seduce you to death, she’ll rip you to shreds. Beneath the facade is a feline huntress of the deadliest kind.`, ability: 'Melee, Escape, Nuker' },

  ]
  heroImg: string = "../../../assets/png/Asset_Hero_Card.png";
  heroName: string = "KEK THE TOAD"
  heroDisc: string = `A strange character, seeking for revenge by setting a path to invent a mechanical Toad.
  He will be driving his pet in an attempt to
  destroy the planet and take over Guba. However, he was captured and
  defeated. As punishment he was exiled into space. He drifted for
  centuries and wound up on the Realm of Ancients. Now his reign of
  terror begins again to take over the Realm of Ancients.`
  heroAbility: string = "Melee, Durable, Disabler, Initiator"
  constructor(private sanitizer:DomSanitizer) {
    this.current_url=this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/TlASMfIcaWw?autoplay=1")

    this.heros.forEach((hero) => {
      this.heroImg = hero.img;
    })
    this.heroImg = this.heros[0].img;

  }

  changHero(id: number) {
    if (id == 3)
      return;
    this.heroImg = this.heros[id].img;
    this.heroDisc = this.heroText[id].disc
    this.heroName = this.heroText[id].title
    this.heroAbility = this.heroText[id].ability;
  }


  ngOnInit(): void {
    AOS.init();
    var rellax = new Rellax('.rellax');
    this.initHorizontalScroll();
  }






  goTo() {
    window.location.href = "https://bscscan.com/address/0x94b69263FCA20119Ae817b6f783Fc0F13B02ad50"
  }
  whitePaper() {
    window.location.href = " https://league-of-ancients-v2.gitbook.io/whitepaper/"
  }
  referral() {
    window.location.href = 'https://leagueofancients.com/referral'
  }


  slide(direction) {
    let container = document.getElementById('timeline-container');
    let scrollCompleted = 0;
    var slideVar = setInterval(function () {
      if (direction == 'left') {
        container.scrollLeft -= 10;
      } else {
        container.scrollLeft += 10;
      }
      scrollCompleted += 10;
      if (scrollCompleted >= 450) {
        window.clearInterval(slideVar);
      }
    }, 10);
  }

  initHorizontalScroll() {
    const slider: any = document.getElementById('timeline-container');
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e: any) => {
      isDown = true;
      slider.classList.add('active');
      startX = e?.pageX - slider?.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e: any) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e?.pageX - slider?.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      console.log(walk);
    });
  }
  bs() {
    window.location.href = "https://exchange.biswap.org/#/swap?outputCurrency=0x94b69263FCA20119Ae817b6f783Fc0F13B02ad50";
  }
  abs() {
    window.location.href = "https://app.apeswap.finance/add/ETH/0x94b69263FCA20119Ae817b6f783Fc0F13B02ad50";
  }
  ps() {
    window.location.href = "https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x94b69263FCA20119Ae817b6f783Fc0F13B02ad50"
  }

}
