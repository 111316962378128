



import { Inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { APP_CONFIG, MyAppConfig } from 'src/app/config/config';
import { HttpHelper } from 'src/app/helper/http-helper';
import { Web3HelperPro } from 'src/app/Helper/web3-helper_pro';
import { LocalstorageService } from 'src/app/Service/localstorage.service';


@Injectable({
  providedIn: 'root'
})
export class ReferralSerService {

  constructor(private httpHelper: HttpHelper, @Inject(APP_CONFIG) private config: MyAppConfig,
  private toastr: ToastrService, private web3Helper: Web3HelperPro ,private localstorageService :LocalstorageService){ }


  /**
   * @dev connect wallet using Helper TS file
   */
   connectWallet = async (): Promise<any> => this.web3Helper.connectWallet();

  // connectWallet = async () => await this.web3Helper.connectWallet();
  // wallet = this.walletService.getUserWallet()

  /**
    *
    * @param abi string[] determin contract functions
    * @param address string contract address
    * @return Contract Object
    */
  //  loadContract = async () => await this.web3Helper.contract(stake2Abi, stake2Play);

  sendEmail = async (obj: any) => {
    return this.httpHelper.post(this.config.baseUrl + this.config.paths.send_email, obj, "", "");
  }
  checkEmailCode = async (obj: any) => {
    return this.httpHelper.post(this.config.baseUrl + this.config.paths.send_code, obj, "", "");
  }
  getUser = async (wallet: string) => {
    console.log('wallet :>> ', wallet);
  let  token = this.localstorageService.getItemlocalStorage("__t__");

    return this.httpHelper.get(this.config.baseUrl + this.config.paths.getUserR+ '?wallet_address=' + wallet , token, [])
  }

}
