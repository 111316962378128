import { ActivatedRoute } from '@angular/router';
import { left, listHero, right } from './data';
import { Component, HostListener, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { NgZone } from '@angular/core';
import {
  trigger,
  transition,
  query,
  style,
  animate,
  group,
  state,
} from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-hero-detail',
  templateUrl: './hero-detail.component.html',
  styleUrls: ['./hero-detail.component.scss'],
  animations: [
    trigger('animImageSlider', [
      transition(':increment', right),
      transition(':decrement', left),
    ]),
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      transition('void <=> *', [animate('0.9s ease-in-out')]),
    ]),
  ],
})
export class HeroDetailComponent implements OnInit {
  imgClass1: string = 'list__img--zoomActive';
  imgClass2: string = 'list__img';
  imgClass3: string = 'list__img';
  counter: number = 0;
  counterSlid: number = 0;

  listHeroA = listHero;

  previousId: number = 1;
  imgId: number = 1;
  backGcolor: string = '';
  backGIm: string = '';
  innerWidth;
  closeResult: string;
  constructor(private route: ActivatedRoute, private zone: NgZone, private toastr: ToastrService, private modalService: NgbModal) { }


  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit(): void {
    // this.route.params.subscribe((params) => (this.counterSlid = params['id']));
    this.counterSlid = Number(JSON.parse(localStorage.getItem('id')))

    // this.innerWidth = window.innerWidth;
    // this.listHeroA.forEach((item) => {
    //   if (item.id == this.counterSlid) {
    //     this.backGIm = item.mainImg;
    //   }
    // });
    // if (this.innerWidth <= 767) {
    //   this.listHeroA.forEach((item) => {
    //     if (item.id == this.counterSlid) {
    //       this.backGIm = item.mainImg;
    //     }
    //   });
    // } else {
    //   this.backGIm = '';
    // }
    this.checkBG();
    AOS.init();
  }
  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   this.innerWidth = window.innerWidth;
  //   if (this.innerWidth <= 767) {
  //     this.listHeroA.forEach((item) => {
  //       if (item.id == this.counterSlid) {
  //         this.backGIm = item.mainImg;
  //       }
  //     });
  //   } else {
  //     this.backGIm = '';
  //   }
  // }
  checkBG() {
    this.listHeroA.forEach((item) => {
      if (item.id == this.counterSlid) {
        this.backGcolor = item.bgColor;
        this.backGIm = item.mainImg;
      }
    });
  }
  showNextHero() {
    this.counter = 0;
    console.log('showNextHero::', this.counterSlid);
    console.log('listHeroA.length::', this.listHeroA.length - 1);
    if (this.counterSlid < this.listHeroA.length - 1) {
      this.counterSlid++;
      this.checkBG();
    }
  }

  showPreviousHero() {
    this.counter = 0;

    if (this.counterSlid >= 1) {
      this.counterSlid = this.counterSlid - 1;
      this.checkBG();
    }
  }
  // ===============================

  onNext() {
    // if (this.counter != this.images.length - 1) {
    this.counter++;
    // }
  }

  onPrevious() {
    // if (this.counter > 0) {
    this.counter--;
    // }
  }
  onClickImg(id: number) {
    this.imgId = id;
    if (this.previousId != id) {
      // this.onNextP()
      console.log('id::', id);
      if (id == 1) {
        if (this.previousId == 3) {
          this.counter = 2;
          this.onPrevious();
        } else {
          this.onPrevious();
        }

        this.imgClass1 = 'list__img--zoomActive';
        this.imgClass2 = 'list__img';
        this.imgClass3 = 'list__img';
        // this.onPrevious()
      } else if (id == 2) {
        if (this.previousId == 1) {
          this.onNext();
        } else {
          this.onPrevious();
        }

        this.imgClass1 = 'list__img';
        this.imgClass2 = 'list__img--zoomActive';
        this.imgClass3 = 'list__img';
        // this.onNext()
      } else if (id == 3) {
        if (this.previousId == 2) {
          this.onNext();
        } else {
          this.counter = 2;
          this.onNext();
        }

        this.imgClass1 = 'list__img';
        this.imgClass3 = 'list__img--zoomActive';

        this.imgClass2 = 'list__img';
        // this.onNext()
      }
      this.previousId = id;
    }
  }
  story() {
    this.toastr.success('Lore Coming Soon!')
  }

}
