<section id="footer">
  <div class="justify-content-center py-3 d-block d-sm-none d-md-none">
    <section id="SECURED_BY">
      <div class="container">
        <h3 class="text-center sub-title-sec">AUDITED BY </h3>

        <div class="col-12 m-auto d-flex justify-content-center">
          <img class="asset_secured" src="assets\png\Asset-Secured_By.png" alt="" />
        </div>
      </div>
    </section>
    <div class="col-12">
      <div class="row justify-content-center">
        <div class="mt-3 mb-4 col-12 footer d-flex justify-content-center">FOLLOW US & JOIN THE COMMUNITY!</div>
        <div class="col-md-8 col-lg-8 col-12 col-sm-12 col-xs-12 text-left m-auto d-flex justify-content-center">
          <a class="m-1" href="https://t.me/leagueofancients">
            <img class="w-5" src="../../../assets/png/Asset-SMTG.png" alt="" />
          </a>
          <a class="m-1" href="https://discord.gg/pkuVvrhucR">
            <img class="w-5" src="../../../assets/png/Asset-SMDC.png" alt="" />
          </a>
          <a class="m-1" href="https://web.facebook.com/leagueofancientsofficial/">
            <img class="w-5" src="../../../assets/png/Asset-SMFB.png" alt="" />
          </a>
          <a class="m-1" href="https://www.instagram.com/leagueofancientsofficial/">
            <img class="w-5" src="../../../assets/png/Asset-SMIG.png" alt="" />
          </a>
          <a class="m-1" href="https://twitter.com/LOAOfficialMOBA/">
            <img class="w-5" src="../../../assets/png/Asset-SMTW.png" alt="" />
          </a>
          <a class="m-1" href=" https://www.youtube.com/c/LeagueofAncientsOfficial">
            <img class="w-5" src="../../../assets/png/Asset-SMYT.png" alt="" />
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-lg-4 col-12 col-sm-12 col-xs-12">
          <p class="d-flex justify-content-center mt-3">
            <img class="w-50" src="../../../assets/png/Asset_Footer_Logo.png" alt="" />
          </p>
        </div>
      </div>
    </div>


    <p class="small-text-footer text-mute mt-4 justify-content-center">
      copyright &#169; 2021 - 2023 League Of Ancients. All Rights Reserved.
    </p>
  </div>

  <div class="justify-content-center py-2 d-none d-sm-block d-md-block">
    <div class="row justify-content-center">
      <div class="col-3">
        <p class="d-flex justify-content-center mt-3">
          <img class="w-75 my-3" src="../../../assets/png/Asset_Footer_Logo.png" alt="" />
        </p>
      </div>
      <div class="col-9">
        <div class="row justify-content-start container">
          <div class="col-12 m-auto d-flex justify-content-start">
            <h4 class="text-center me-3 my-1">AUDITED BY:</h4>
            <img class="h-30" src="assets\png\Asset-Secured_By.png" alt="" />
          </div>
          <div class="col-12 footer d-flex justify-content-start">FOLLOW US & JOIN THE COMMUNITY!</div>
          <div class="col-12 d-flex justify-content-start">
            <a class="mt-1 me-1" href="https://t.me/leagueofancients">
              <img class="w-5" src="../../../assets/png/Asset-SMTG.png" alt="" />
            </a>
            <a class="m-1" href="https://discord.gg/pkuVvrhucR">
              <img class="w-5" src="../../../assets/png/Asset-SMDC.png" alt="" />
            </a>
            <a class="m-1" href="https://web.facebook.com/leagueofancientsofficial/">
              <img class="w-5" src="../../../assets/png/Asset-SMFB.png" alt="" />
            </a>
            <a class="m-1" href="https://www.instagram.com/leagueofancientsofficial/">
              <img class="w-5" src="../../../assets/png/Asset-SMIG.png" alt="" />
            </a>
            <a class="m-1" href="https://twitter.com/LOAOfficialMOBA/">
              <img class="w-5" src="../../../assets/png/Asset-SMTW.png" alt="" />
            </a>
            <a class="m-1" href=" https://www.youtube.com/c/LeagueofAncientsOfficial">
              <img class="w-5" src="../../../assets/png/Asset-SMYT.png" alt="" />
            </a>
          </div>
          <p class="small-text-footer text-mute mt-4 justify-content-start">
            copyright &#169; 2021 - 2023 League Of Ancients. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>