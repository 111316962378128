<div id="main">
  <app-nav></app-nav>
  <section>

    <div class="text-center">
      <h1 class="header_para mt-5">
        CHOOSE YOUR HERO
      </h1>
      <h6 class="font-sm __font mobile_ds">
        Master one hero and dominate the Ancient Realm
      </h6>
    </div>
  </section>
</div>




<section id="hero-img">
  <div class="text-center">
    <div class="containerImg " data-aos="fade-up" data-aos-duration="2000">
      <div class="containerImg__item " *ngFor="let item of   listImgHero">
        <img class="w-100 containerImg__img--zoomOut" (click)="onSelectHero(item.id)" role="button" [src]="item.img"
          alt="">
        <div class="overlay">
          <div class="containerImg__text">{{item.name}}</div>
        </div>
      </div>
    </div>

    <h2 class="text-uppercase text-center m-4 sub-title-sec" data-aos="fade-up" data-aos-duration="2000">
      <span class="larg-font">M</span>ORE <span class="larg-font">H</span>EROES <span class="larg-font">C</span>OMING
      <span class="larg-font">S</span>OON!
    </h2>
    <h6 class="font-sm __font" data-aos="fade-up" data-aos-duration="3000">
      Stay tuned in our official channels for more heroes release!
    </h6>

  </div>
</section>

<section id="capsule" class="text-left">
  <div class="row">
    <div class="col-md-4 col-12">
      <img class="w-100 faa-float animated faa-slow" src="assets/png/IMG-hero/HeroListingPage/Capsules_Banner.png"
        alt="">

    </div>
    <div class="col-md-8 col-12 d-flex align-items-center">

      <div class="col-12 text-md-start text-center header_text pt-4">
        <h1 class="header_para">THE LOA CAPSULES</h1>
        <h6 class="font-sm __font mobile_ds">It's the best opportunity to own as many NFT Skins as you can! <br> Get
          your
          Beta, Alpha
          & Genesis capsules to summon the rarest skins across the Ancient Realm.</h6>

        <button (click)="onBuyClickListener()" class="btn button-border mt-3">OWN A CAPSULE NOW!</button>

      </div>



    </div>
  </div>


</section>

<app-footer></app-footer>
