<div id="main">
  <div class="col-12 row m-0 justify-content-between">
    <div class="col-md-3 col-6">
      <a href="https://leagueofancients.com"><img class="w-100" src="assets/png/Asset_Header_Logo.png" alt="LOA" /></a>
    </div>
    <div class="col-md-4 text-center col-6">
      <button *ngIf="!connected" (click)="connectWallet()" class="btn button-border mt-4">
        <i class="fas fa-wallet walletIcon"> </i> Connect wallet
      </button>
      <button *ngIf="connected" class="btn button-border mt-4">
        <i class="fas fa-wallet walletIcon"> {{fiveDigit}}...{{lastSixDigit}} </i>
      </button>
    </div>
  </div>
  <section>
    <div class="mainBlock col-md-10 col-12 text-center m-auto">

    </div>
  </section>
</div>
<section *ngIf="stillGoing" id=detail>
  <div class="container">
    <div class="countdown">
      <div class="countdown-item">
        <span id="day" #day>-</span>
        <span>Day</span>
      </div>
      :
      <div class="countdown-item">
        <span id="hour" #hour>-</span>
        <span>Hour</span>
      </div>:

      <div class="countdown-item">
        <span id="minute" #minute>-</span>
        <span>Minute</span>
      </div>:

      <div class="countdown-item">
        <span id="second" #second>-</span>
        <span>Second</span>
      </div>

      <div class="pulse"></div>
    </div>
  </div>
</section>
<!-- <section *ngIf="stillGoing" id=detail>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h1>Time Left Before NFT Sales End</h1>
      </div>
      <div class=" col-12">
        <div class="countdown">
          <div class="countdown-item">
            <span id="day">-</span>
            <span>Day</span>
          </div>

          <div class="countdown-item">
            <span id="hour">-</span>
            <span>Hour</span>
          </div>

          <div class="countdown-item">
            <span id="minute">-</span>
            <span>Minute</span>
          </div>

          <div class="countdown-item">
            <span id="second">-</span>
            <span>Second</span>
          </div>

          <div class="pulse"></div>
        </div>
      </div>
    </div>
  </div>
</section> -->
<section id="detail">
  <div class="container">
    <div class="row " data-aos="fade-up" data-aos-duration="2000">


      <!-- Beta -->
      <div *ngIf="betaCan" class="col-md-4 col-12 pt-2">
        <div class="card card_color card-shape">
          <img class="card-img-top" src="assets/png/GG/beta.png" alt="Beta">
          <div class="card-body">
            <h2 class="card-title font_title_size">Beta Capsule</h2>
            <p class="card-text subText">55% chance Basic <br> 30% chance Epic <br> 15% chance Elemental<br>

            </p>
            <div class="text-center">

            </div>
          </div>

          <div class="card-body">
            <!-- <div class="justify-content-center d-flex text-center LOA_border">
              <img class="card__logo img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />
              <span> {{loaTokenBeta.toFixed(2)}}</span>
            </div> -->
            <div class="justify-content-center d-flex text-center  ">


              <button (click)="betaMinus('beta')" type="button" class="btn btn-default btn-number minus_style">
                -
              </button>



              <input (ngModelChange)="onChange($event)" [(ngModel)]="betaCount" type="number" value="{{betaCount}}"
                id="LOA" name="LOATOKEN" class="text-cetner" min="200" max="80000" disabled>



              <button (click)="betaPlus('beta')" type="button" class="btn btn-default btn-number plus_style"> +
              </button>




            </div>
          </div>
          <div class="card-footer text-muted justify-content-center text-center ">
            <div class="col-12 justify-content-center pt-1">
              <h5 class="orang-bnb">BNB: {{betaPrice}}</h5>

            </div>
            <div class="col-12 justify-content-center ">
              <span class="text-center">{{betaRemaining}} of {{betaTotal}} Remaining </span>
            </div>


          </div>
          <div class="col-12">
            <button *ngIf="betaRemaining>0" (click)="onBtnClickListner('beta')" type="button"
              class="btn btn-primary btnBuy">{{betaRemaining>0?'BUY NOW':'SOLD OUT'}}</button>
            <button *ngIf="betaRemaining<=0" (click)="onBtnClickListner('beta')" type="button"
              class="btn btn-primary btnBuy finished">{{betaRemaining>0?'BUY NOW':'SOLD OUT'}}</button>
          </div>
        </div>
      </div>

      <!-- Alpha -->
      <div *ngIf="alphaCan" class="col-md-4 col-12 pt-2">
        <div class="card card_color card-shape">
          <img class="card-img-top" src="assets/png/GG/alpha.png" alt="Beta">
          <div class="card-body">
            <h2 class="card-title font_title_size">Alpha Capsule</h2>
            <p class="card-text subText">55% chance Champion <br> 30% chance Legendary <br> 15% chance
              Mythical<br>

            </p>

            <div class="text-center">

            </div>
          </div>

          <div class="card-body">
            <!-- <div class="justify-content-center d-flex text-center LOA_border">
              <img class="card__logo img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />
              <span> {{loaTokenAlpha.toFixed(2)}}</span>
            </div> -->
            <div class="justify-content-center d-flex text-center  ">

              <button (click)="betaMinus('alpha')" type="button" class="btn btn-default btn-number minus_style">
                -
              </button>

              <input (ngModelChange)="onChange($event)" [(ngModel)]="alphaCount" type="number" value="{{alphaCount}}"
                id="LOA" name="LOATOKEN" class="text-cetner" min="200" max="80000" disabled>

              <button (click)="betaPlus('alpha')" type="button" class="btn btn-default btn-number plus_style"> +
              </button>


            </div>
          </div>
          <div class="card-footer text-muted justify-content-center text-center ">
            <div class="col-12 justify-content-center pt-1">
              <h5 class="orang-bnb">BNB: {{alphaPrice}}</h5>

            </div>
            <div class="col-12 justify-content-center ">
              <span class="text-center">{{alphaRemaining}} of {{alphaTotal}} Remaining </span>
            </div>


          </div>
          <div class="col-12">
            <button *ngIf="alphaRemaining>0" (click)="onBtnClickListner('alpha')" type="button"
              class="btn btn-primary btnBuy">{{alphaRemaining>0?'BUY NOW':'SOLD OUT'}}</button>
            <button *ngIf="alphaRemaining<=0" (click)="onBtnClickListner('alpha')" type="button"
              class="btn btn-primary btnBuy finished">{{alphaRemaining>0?'BUY NOW':'SOLD OUT'}}</button>
          </div>
        </div>
      </div>

      <!-- Genisis -->
      <div *ngIf="genesisCan" class="col-md-4 col-12 pt-2">
        <div class="card card_color card-shape">
          <img class="card-img-top" src="assets/png/GG/genesis.png" alt="Beta">
          <div class="card-body">
            <h2 class="card-title font_title_size">Genesis Capsule</h2>
            <p class="card-text subText">100% chance Genesis
              <br>
              <br>
              <br>
            </p>
            <div class="text-center">

            </div>
          </div>

          <div class="card-body">
            <!-- <div class="justify-content-center d-flex text-center LOA_border">
              <img class="card__logo img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />
              <span>{{loaTokenGenesis}}</span>
            </div> -->
            <div class="justify-content-center d-flex text-center  ">


              <button (click)="betaMinus('genesis')" type="button" class="btn btn-default btn-number minus_style">
                -
              </button>



              <input (ngModelChange)="onChange($event)" [(ngModel)]="genesisCount" type="number"
                value="{{genesisCount}}" id="LOA" name="LOATOKEN" class="text-cetner" min="200" max="80000" disabled>



              <button (click)="betaPlus('genesis')" type="button" class="btn btn-default btn-number plus_style"> +
              </button>




            </div>
          </div>
          <div class="card-footer text-muted justify-content-center text-center ">
            <div class="col-12 justify-content-center pt-1">
              <h5 class="orang-bnb">BNB: {{genesisPrice}}</h5>

            </div>
            <div class="col-12 justify-content-center ">
              <span class="text-center">{{genesisRemaining}} of {{genesisTotal}} Remaining </span>
            </div>


          </div>
          <div class="col-12">
            <button *ngIf="genesisRemaining>0" (click)="onBtnClickListner('genesis')" type="button"
              class="btn btn-primary btnBuy">{{genesisRemaining>0?'BUY NOW':'SOLD OUT'}}</button>
            <button *ngIf="genesisRemaining<=0" (click)="onBtnClickListner('genesis')" type="button"
              class="btn btn-primary btnBuy finished">{{genesisRemaining>0?'BUY NOW':'SOLD OUT'}}</button>
          </div>
        </div>
      </div>





    </div>
  </div>
</section>



<!-- Roles  -->
<section id="detail">
  <div class="card-img position-relative card mt-5">

    <h5 class="display-5 card__desc--orange text-center fw-bolder new-color">
      Instructions & Rules
    </h5>



    <div class="card__divider"></div>

    <div class="row justify-content-end">
      <div class="ml-auto col-12 col-md-6">
        <div class="pb">
          <span class="card__desc--orange margin_rem text-center">
            1- The date and time of the NFT Presales are as follows:
            Start: 31st March 2022, 12:01 UTC
            End: 1st April 2022, 12:00 UTC

            <br>
            2- All Pre-Sales whitelist winners will be given 1 minute early access, starting from 31st March 2022 12:00
            UTC
            until 31st March 2022 12:01 UTC for the NFT Sale above.

            <br>
            3- The public will be able to start purchasing in the NFT Sales from 31st March 2022 12:01 UTC onwards.
            <br>
            4-League Of Ancients reserves the rights to end all NFT Public Sales earlier than the stipulated end date
            and
            time or when all units are sold out.
            <br>

            4- Users will need to connect their Metamask wallet on the “Binance Smart Chain” network to participate.
            <br>
            5- There is no maximum limit for the amount of purchases and will be on a first-come, first-served basis
            during
            this NFT Public Sale.
            <br>

            6- No refunds will be made on any purchase.
            <br>
            7- Each unique wallet which holds at least ONE Alpha Capsule during the Alpha Testing Registration will be
            GUARANTEED a spot for Alpha Access.
            <br>
            8- Each unique wallet which holds at least ONE Beta Capsule during the Beta Testing Registration will be
            GUARANTEED a spot for Beta Access.
            <br>
          </span>
        </div>

      </div>
    </div>

    <img class="img-back" src="assets/png/tokenPresaleAsset/PresaleAsset-06.png" alt="" />
  </div>
</section>





<!-- What you Get -->
<section id="detail">
  <div class="container">
    <div class="row ">
      <div class="col-12 text-center mb-5" data-aos="fade-up" data-aos-duration="1000">
        <h1>Purchases Breakdown</h1>
      </div>
      <!-- Beta Purchase -->
      <div class="col-md-4 col-12 pt-2" data-aos="fade-up" data-aos-duration="1500">
        <div class="card card_color card-shape">
          <img class="card-img-top" src="assets/png/GG/beta.png" alt="Beta">
          <div class="card-body text-center">
            <h2 class="card-title font_title_size">Beta Capsule</h2>
          </div>

          <div class="card-body">
            <!-- <div class="justify-content-center d-flex text-center LOA_border">
              <img class="card__logo img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />
              <span>{{betaAmout * betaMBy}}</span>
            </div> -->
            <div class="justify-content-center d-flex text-center LOA_border">
              <h4>Capsule: {{betaAmout}}</h4>

            </div>
            <div>

            </div>

          </div>


        </div>
      </div>

      <!-- Alpha Purchase -->
      <div class="col-md-4 col-12 pt-2" data-aos="fade-up" data-aos-duration="2000">
        <div class="card card_color card-shape">
          <img class="card-img-top" src="assets/png/GG/alpha.png" alt="Beta">
          <div class="card-body text-center">
            <h2 class="card-title font_title_size">Alpha Capsule</h2>
          </div>

          <div class="card-body">
            <!-- <div class="justify-content-center d-flex text-center LOA_border">
              <img class="card__logo img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />
              <span>{{alphaMBy * alphaAmout}}</span>
            </div> -->
            <div class="justify-content-center d-flex text-center LOA_border">
              <h4>Capsule: {{alphaAmout}}</h4>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>

      <!-- Genisis Purchase -->
      <div class="col-md-4 col-12 pt-2" data-aos="fade-up" data-aos-duration="2500">
        <div class="card card_color card-shape">
          <img class="card-img-top" src="assets/png/GG/genesis.png" alt="Beta">
          <div class="card-body text-center">
            <h2 class="card-title font_title_size">Genesis Capsule</h2>
          </div>
          <div class="card-body">
            <!-- <div class="justify-content-center d-flex text-center LOA_border">
              <img class="card__logo img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />
              <span>{{genesisAmout * genisisMBy}}</span>
            </div> -->
            <div class="justify-content-center d-flex text-center LOA_border">
              <h4>Capsule: {{genesisAmout}}</h4>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>


</section>

<app-footer></app-footer>
