import { HeroDetailComponent } from './components/hero-page/hero-detail/hero-detail.component';
import { HeroPageComponent } from './components/hero-page/hero-page.component';
import { PartnershipComponent } from './components/partnership/partnership.component';
import { TokenPresaleComponent } from './components/token-presale/token-presale.component';
import { OurTeamComponent } from './components/our-team/our-team.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { PresaleComponent } from './components/presale/presale.component';
// import { ReferralComponent } from './components/referral/referral.component';
import { PresaletestComponent } from './components/presaletest/presaletest.component';
import { PrivateSaleComponent } from './components/private-sale/private-sale.component';
import { PublicSaleComponent } from './components/public-sale/public-sale.component';
import { ReferralComponent } from './components/referral/referral.component';



const routes: Routes = [
  {
    path: '',
    component: MainComponent,
  },
  // {
  //   path: 'referral',
  //   component: ReferralComponent
  // },
  {
    path: 'presale',
    component: TokenPresaleComponent
  },
  // {
  //   path: 'token-presale',
  //   component: TokenPresaleComponent
  // },
  // {
  //   path: 'our-team',
  //   component: OurTeamComponent
  // },

  {
    path: 'heroes',
    component: HeroPageComponent
  },
  {
    path: 'hero-detail',
    component: HeroDetailComponent
  },
  {
    path: 'presaleTest',
    component: PresaletestComponent
  },
  {
    path: 'partnership',
    component: PartnershipComponent
  },
  {
    path: 'public-sale',
    component: PrivateSaleComponent
  },
  { path: 'referral-test', loadChildren: () => import('./pages/referral-page/referral-page.module').then(m => m.ReferralPageModule) },
  // {
  //   path: 'public-sale',
  //   component: PublicSaleComponent
  // }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
