import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit {
  @ViewChild("second") second: ElementRef;
  @ViewChild("day") day: ElementRef;
  @ViewChild("hour") hour: ElementRef;
  @ViewChild("minute") minute: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.counDown()

    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }
  public targetTime = 0;
  async counDown() {

    setInterval(async () => {

      this.targetTime = 1647576000000;
      // this.targetTime =  new Date("Jan 5, 2024 15:37:25").getTime();
      let now = new Date().getTime();
      let timeleft = this.targetTime - now;

      // this.currentTime = +new Date()
      // if ((+new Date() > this.targetTime)) {

      //   this.targetTime = 1641859200000;

      //   return;
      // }


      // Calculating the days, hours, minutes and seconds left
      let day = Math.floor(timeleft / (1000 * 60 * 60 * 24));
      let hour = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minute = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
      let second = Math.floor((timeleft % (1000 * 60)) / 1000);


      this.day.nativeElement.innerText =String(day);
      this.hour.nativeElement.innerText =String(hour);
      this.minute.nativeElement.innerText =String(minute);
      this.second.nativeElement.innerText =String(second);
    }, 1000);
  }

}
