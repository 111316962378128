import {
  trigger,
  transition,
  query,
  style,
  animate,
  group,
  state,
} from '@angular/animations';
export const listHero = [

  {
    name: 'LEON FIST',
    mainImg: 'assets/png/IMG-hero/LeonFist/Header_IMG_LeonFist.png',
    imgList: [
      'assets/png/IMG-hero/LeonFist/NFTSKIN_LeonFist 01.png',
      'assets/png/IMG-hero/LeonFist/NFTSKIN_LeonFist 02.png',
      'assets/png/IMG-hero/LeonFist/NFTSKIN_LeonFist 03.png',
    ],

    img1Card: 'assets/png/IMG-hero/LeonFist/NFT_Card _LeonFist01.png',
    img2Card: 'assets/png/IMG-hero/LeonFist/NFT_Card _LeonFist02.png',
    img3Card: 'assets/png/IMG-hero/LeonFist/NFT_Card _LeonFist03.png',
    icon: 'assets/png/IMG-hero/LeonFist/ICON_TANK.png',
    iconName: 'TANK',
    bgColor: '#11293c',
    subTitle: 'Initiator, Durable, Disable',
    type: ' Melee',
    story: `Leon was destined to rule the Kingdom of the Wilds after his father passed. He was
        an honorable man, who only dreamed to continue providing peace, prosperity and
        harmony to his realm. That is, until the “Red Coronation” occurred. Leon Fist’s world
        was forever changed, he was struck down, his arm severed and his body discarded,
        left to die in the sewers of his kingdom. But he was saved, his arm restored and
        empowered by a raging curse. Now, Leon is hellbent on revenge and nothing will
        stand in his way`,
    id: 0,
  },
  {
    name: 'LORIE',
    mainImg: 'assets/png/IMG-hero/Lorie-Page/Header_IMG_Lorie.png',
    imgList: [
      'assets/png/IMG-hero/Lorie-Page/NFTSKIN_Lorie 01.png',
      'assets/png/IMG-hero/Lorie-Page/NFTSKIN_Lorie 02.png',
      'assets/png/IMG-hero/Lorie-Page/NFTSKIN_Lorie 03.png',
    ],
    img1Card: 'assets/png/IMG-hero/Lorie-Page/NFT_Card _Lorie01.png',
    img2Card: 'assets/png/IMG-hero/Lorie-Page/NFT_Card _Lorie02.png',
    img3Card: 'assets/png/IMG-hero/Lorie-Page/NFT_Card _Lorie03.png',
    icon: 'assets/png/IMG-hero/Lorie-Page/ICON_Mage.png',
    bgColor: 'linear-gradient(180deg, rgba(56,34,8,1) 18%, rgba(57,34,8,1) 20%, rgba(77,36,5,1) 61%)',
    iconName: 'MAGE',
    subTitle: 'Disabler, Nuker, AOE',
    type: 'Ranged',
    story: `One story tells of a unique rainbow colored girl born of a forbidden union between
        god and mortal. They say her childhood was one filled with misfortune. It all began
        when Lorie’s birth took her mother’s life. Lorie’s father drowned his sorrows away in
        his drunkenness and her village was not kind to her either. This special girl was born
        into an ordinary, simple world that was not prepared for what she truly was`,
    id: 1,
  },
  {
    name: 'KEK THE TOAD',
    mainImg: 'assets/png/IMG-hero/KekTheTOAD/Header_IMG_Kek.png',
    imgList: [
      'assets/png/IMG-hero/KekTheTOAD/NFTSKIN_Kek the TOAD01.png',
      'assets/png/IMG-hero/KekTheTOAD/NFTSKIN_Kek the TOAD02.png',
      'assets/png/IMG-hero/KekTheTOAD/NFTSKIN_Kek the TOAD03.png',
    ],

    img1Card: 'assets/png/IMG-hero/KekTheTOAD/NFT_Card _KektheTOAD01.png',
    img2Card: 'assets/png/IMG-hero/KekTheTOAD/NFT_Card _KektheTOAD02.png',
    img3Card: 'assets/png/IMG-hero/KekTheTOAD/NFT_Card _KektheTOAD03.png',
    icon: 'assets/png/IMG-hero/KekTheTOAD/ICON_TANK.png',
    iconName: 'TANK',
    bgColor: '#11293c',
    subTitle: 'Durable, Disabler, Initiator',
    type: 'Melee',
    story: `The creation of TOAD was to fulfill one purpose: destroy all those who stood
        between him and ruling Guba. With such power, Kek quickly rose to infamy and
        was known as “Kek the Toad”. Nations quickly crumbled before him and legions
        succumbed to his power. After many years of war, total domination over Guba was
        finally within his grasp. However, one fateful evening, Kek the Toad was betrayed.
        He was captured and imprisoned`,
    id: '2',
  },
  {
    name: 'MASKED YASUKE',
    mainImg: 'assets/png/IMG-hero/MaskedYasukePage/Header_IMG_MaskedYasuke.png',
    imgList: [
      'assets/png/IMG-hero/MaskedYasukePage/NFTSKIN_MaskedYasuke01.png',
      'assets/png/IMG-hero/MaskedYasukePage/NFTSKIN_MaskedYasuke02.png',
      'assets/png/IMG-hero/MaskedYasukePage/NFTSKIN_MaskedYasuke03.png',
    ],

    img1Card: 'assets/png/IMG-hero/MaskedYasukePage/NFT_Card _MaskedYasuke01.png',
    img2Card: 'assets/png/IMG-hero/MaskedYasukePage/NFT_Card _MaskedYasuke02.png',
    img3Card: 'assets/png/IMG-hero/MaskedYasukePage/NFT_Card _MaskedYasuke03.png',
    icon: 'assets/png/IMG-hero/MaskedYasukePage/ICON_Assassin.png',
    iconName: 'ASSASSIN',
    bgColor: 'linear-gradient(180deg, rgba(36,11,11,1) 18%, rgba(57,1,1,1) 67%)',
    subTitle: 'Carry, Escape',
    type: 'Melee',
    story: `A Utopian child of war and slavery, no one really knows his true name. At an early
    age he was taken from his family and sold to the kingdom of Noria where dark
    skinned utopians were commonly used for inhumane entertainment. However, this
    Utopian boy had a destiny; and it was to be forged in the fires of the Norian Arena`,
    id: '3',
  },
  {
    name: 'NEEKOMANI',
    mainImg: 'assets/png/IMG-hero/NeekomaniPage/Header_IMG_Neekomani.png',
    imgList: [
      'assets/png/IMG-hero/NeekomaniPage/NFTSKIN_Neekomani01.png',
      'assets/png/IMG-hero/NeekomaniPage/NFTSKIN_Neekomani02.png',
      'assets/png/IMG-hero/NeekomaniPage/NFTSKIN_Neekomani03.png',
    ],

    img1Card: 'assets/png/IMG-hero/NeekomaniPage/NFT_Card _Neekomani01.png',
    img2Card: 'assets/png/IMG-hero/NeekomaniPage/NFT_Card _Neekomani02.png',
    img3Card: 'assets/png/IMG-hero/NeekomaniPage/NFT_Card _Neekomani03.png',
    icon: 'assets/png/IMG-hero/NeekomaniPage/ICON_Mage.png',
    iconName: 'MAGE',
    bgColor: 'linear-gradient(180deg, rgba(35,11,36,1) 18%, rgba(48,1,57,1) 67%)',
    subTitle: 'Escape, Nuker, Carry',
    type: 'Melee',
    story: `Though Legends have it that she was once a young beautiful maiden who caught
    the eye of Dolos, the daemon of trickery. Dolos, as per his nature, attempted to
    deceive the young beautiful maiden. Yet, much to his surprise, his deception failed
    and his godlike arrogance demanded Neekomani’s suffering. Thus, Neekomani was
    cursed by Dolos to forever be his avatar of deception`,
    id: '4',
  },
  {
    name: 'ATALANTA',
    mainImg: 'assets/png/IMG-hero/AtalantaPage/Header_IMG_Atalanta.png',
    imgList: [
      'assets/png/IMG-hero/AtalantaPage/NFTSKIN_Atalanta01.png',
      'assets/png/IMG-hero/AtalantaPage/NFTSKIN_Atalanta02.png',
      'assets/png/IMG-hero/AtalantaPage/NFTSKIN_Atalanta03.png',
    ],

    img1Card: 'assets/png/IMG-hero/AtalantaPage/NFT_Card_Atalanta01.png',
    img2Card: 'assets/png/IMG-hero/AtalantaPage/NFT_Card_Atalanta02.png',
    img3Card: 'assets/png/IMG-hero/AtalantaPage/NFT_Card_Atalanta03.png',
    icon: 'assets/png/IMG-hero/AtalantaPage/ICON_Marksman.png',
    iconName: 'MARKSMAN',
    bgColor: 'linear-gradient(180deg, rgba(11,36,15,1) 12%, rgba(1,57,5,1) 58%)',
    subTitle: 'Carry, Pusher',
    type: 'Ranged',
    story: ``,
    id: '5',
  },
  {
    name: 'Tarlos',
    mainImg: 'assets/png/IMG-hero/TarlosPage/Header_IMG_Tarlos.png',
    imgList: [
      'assets/png/IMG-hero/TarlosPage/NFTSKIN_Tarlos01.png',
      'assets/png/IMG-hero/TarlosPage/NFTSKIN_Tarlos02.png',
      'assets/png/IMG-hero/TarlosPage/NFTSKIN_Tarlos03.png',
    ],
    img1Card: 'assets/png/IMG-hero/TarlosPage/NFT_Card_Tarlos01.png',
    img2Card: 'assets/png/IMG-hero/TarlosPage/NFT_Card_Tarlos02.png',
    img3Card: 'assets/png/IMG-hero/TarlosPage/NFT_Card_Tarlos03.png',
    icon: 'assets/png/IMG-hero/TarlosPage/ICON_TANK.png',
    iconName: 'TANK',
    bgColor: 'linear-gradient(180deg, rgba(11,17,36,1) 18%, rgba(1,46,57,1) 70%)',
    subTitle: 'Disabler, Durable, Initiator, AOE',
    type: 'Melee',
    story: ``,
    id: '6',
  },
  {
    name: 'NIGHTSHADE',
    mainImg: 'assets/png/IMG-hero/NightshadePage/Header_IMG_Nightshade.png',
    imgList: [
      'assets/png/IMG-hero/NightshadePage/NFTSKIN_Nightshade01.png',
      'assets/png/IMG-hero/NightshadePage/NFTSKIN_Nightshade02.png',
      'assets/png/IMG-hero/NightshadePage/NFTSKIN_Nightshade03.png',
    ],
    img1Card: 'assets/png/IMG-hero/NightshadePage/NFT_Card_Nightshade01.png',
    img2Card: 'assets/png/IMG-hero/NightshadePage/NFT_Card_Nightshade02.png',
    img3Card: 'assets/png/IMG-hero/NightshadePage/NFT_Card_Nightshade03.png',
    icon: 'assets/png/IMG-hero/NightshadePage/ICON_Assassin.png',
    iconName: 'ASSASSIN',
    bgColor: 'linear-gradient(180deg, rgba(36,11,11,1) 18%, rgba(57,1,1,1) 67%)',
    subTitle: 'Carry, Escape',
    type: 'Melee',
    story: ``,
    id: '7',
  },
  {
    name: 'Mortus',
    mainImg: 'assets/png/IMG-hero/MortusPage/Header_IMG_Mortus.png',
    imgList: [
      'assets/png/IMG-hero/MortusPage/NFTSKIN_Mortus01.png',
      'assets/png/IMG-hero/MortusPage/NFTSKIN_Mortus02.png',
      'assets/png/IMG-hero/MortusPage/NFTSKIN_Mortus03.png',
    ],
    img1Card: 'assets/png/IMG-hero/MortusPage/NFT_Card_Mortus01.png',
    img2Card: 'assets/png/IMG-hero/MortusPage/NFT_Card_Mortus02.png',
    img3Card: 'assets/png/IMG-hero/MortusPage/NFT_Card_Mortus03.png',
    icon: 'assets/png/IMG-hero/MortusPage/ICON_Mage.png',
    iconName: 'MAGE',
    bgColor: 'linear-gradient(180deg, rgba(35,11,36,1) 18%, rgba(48,1,57,1) 67%)',
    subTitle: 'Nuker, Disabler, DoT',
    type: 'Ranged',
    story: ``,
    id: '8',
  },
  {
    name: 'CALANTHA',
    mainImg: 'assets/png/IMG-hero/CalanthaPage/Header_IMG_Calantha.png',
    imgList: [
      'assets/png/IMG-hero/CalanthaPage/NFTSKIN_Calantha01.png',
      'assets/png/IMG-hero/CalanthaPage/NFTSKIN_Calantha02.png',
      'assets/png/IMG-hero/CalanthaPage/NFTSKIN_Calantha03.png',
    ],
    img1Card: 'assets/png/IMG-hero/CalanthaPage/NFT_Card_Calantha01.png',
    img2Card: 'assets/png/IMG-hero/CalanthaPage/NFT_Card_Calantha02.png',
    img3Card: 'assets/png/IMG-hero/CalanthaPage/NFT_Card_Calantha03.png',
    icon: 'assets/png/IMG-hero/CalanthaPage/ICON_Support.png',
    iconName: 'SUPPORT',
    bgColor: 'linear-gradient(180deg, rgba(56,34,8,1) 18%, rgba(77,36,5,1) 67%)',
    subTitle: 'Support, Healer, Disabler',
    type: 'Ranged',
    story: ``,
    id: '9',
  },


];

// ================animat
export const left = [
  query(':enter, :leave', style({ width: '300px' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(-300px)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(300px)' }))], {
      optional: true,
    }),
  ]),
];

export const right = [
  query(':enter, :leave', style({ width: '300px' }), { optional: true }),
  group([
    query(':enter', [style({ transform: 'translateX(300px)' }), animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
      optional: true,
    }),
    query(':leave', [style({ transform: 'translateX(0%)' }), animate('.3s ease-out', style({ transform: 'translateX(-300px)' }))], {
      optional: true,
    }),
  ]),
];

export const nftSkinRarity = [
  "Basic",
  "Epic",
  "Elemental"
]
