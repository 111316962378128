

    <div class="row justify-content-center">
      <div class="countdown" style=" border-radius: 10px;   background: #00000087;
      width: auto;
      padding-top: 13px">
        <div class="text-center">
          <div class="countdown-item">
            <span id="day" #day>-</span>

          </div>
          <span class="text-g">Day</span>
        </div>


        <div class="text-center">
          <div class="countdown-item">
            <span id="hour" #hour>-</span>

          </div>
          <span class="text-g">Hour</span>
        </div>

        <div class="text-center">
        <div class="countdown-item">
          <span id="minute" #minute>-</span>

        </div>
        <span class="text-g">Minute</span>
      </div>
        <div class="text-center">
          <div class="countdown-item">
            <span id="second" #second>-</span>

          </div>
          <span class="text-g">Second</span>
        </div>

        <!-- <div class="pulse"></div> -->
      </div>
    </div>
