<section>
    <div id="ref" class="col-12 m-0 row">

        <!-- Social Media -->
        <div class="col-md-4 col-4 text-center pt-4 head">



            <a class="m-1" href="https://t.me/leagueofancients">
                <img class="w-5" src="../../../assets/png/Asset-SMTG.png" alt="" />
            </a>
            <a class="m-1" href="https://discord.gg/pkuVvrhucR">
                <img class="w-5" src="../../../assets/png/Asset-SMDC.png" alt="" />
            </a>
            <a class="m-1" href="https://web.facebook.com/leagueofancientsofficial/">
                <img class="w-5" src="../../../assets/png/Asset-SMFB.png" alt="" />
            </a>
            <a class="m-1" href="https://www.instagram.com/leagueofancientsofficial/">
                <img class="w-5" src="../../../assets/png/Asset-SMIG.png" alt="" />
            </a>
            <a class="m-1" href="https://twitter.com/LOAOfficialMOBA/">
                <img class="w-5" src="../../../assets/png/Asset-SMTW.png" alt="" />
            </a>
            <a class="m-1" href="  https://www.youtube.com/c/LeagueofAncientsOfficial">
                <img class="w-5" src="../../../assets/png/Asset-SMYT.png" alt="" />
            </a>

        </div>
        <!-- Logo -->
        <div class=" col-md-4 col-6 pt-2 text-center">
            <img (click)="home()" role="button" class="w-100 logo" src="../../../assets/png/Asset_Header_Logo.png" alt="" />
        </div>
        <!-- Connect wallet -->
        <div class="col-md-4 col-6 text-center pt-4 btnDiv">

            <button *ngIf="!connected" (click)="connectWallet()" class="btn walletButton"><i
                    class="fas fa-wallet walletIcon">
                </i> Connect
                wallet</button>
            <div *ngIf="isTrusted" class="col-6 re-captcha text-center">

                <re-captcha (resolved)="resolved($event)" siteKey="6LeqRvgcAAAAAK7CsxTFZg-MVg8rUABPVrEpvkCx"
                    (error)="onError($event)" errorMode="handled">
                </re-captcha>
            </div>
            <button *ngIf="connected" (click)="connectWallet()" class="btn walletButtonConnected"><i
                    class="fas fa-wallet walletIconConnected">
                </i> {{wallet| slice:0:12}}...</button>
        </div>

        <div class="col-md-6 col-12 imgDiv order-1 order-md-2">
            <img class="image_height text-right" src="assets/png/ASSET-CoverToken.png">
        </div>
        <div class="col-md-6 col-12 header_text order-2 order-md-1 p-screen">
            <h1 class="header_para">
                THE REFERRAL PROGRAM HAS<br> OFFICIALLY ENDED
            </h1>
            <h6 class="font-sm __font mobile_ds">
                Connect your wallet to check your referral reward
            </h6>

            <button (click)="scrolTo()" *ngIf="!connected" class="btn inviteButton mt-1 mb-2">
                LEARN HOW </button>
            <button (click)="homepage()" *ngIf="!connected" class="btn inviteButton mt-1 mb-2 homepage_btn">
                VISIT HOMEPAGE</button>
            <button id="link" (click)="showSuccess()" *ngIf="connected" class="btn inviteButtonConnected mt-1 mb-2">
                {{url | slice: 0:33}}... <i class="far fa-2x fa-copy align-right"></i></button>

        </div>

    </div>

</section>
<section *ngIf="connected" id="summary">

    <div class="col-12 text-center header_text pt-4">
        <h1 class="header_para">SUMMARY</h1>

    </div>


    <div>
        <div class="row  justify-content-center mt-5">
            <div class="col-12 col-md-6 mt-2">
                <div class="border-card border-card-img ">
                    <h5 class="text-white text-capitalize">
                        total Referral Reward
                    </h5>

                    <h1 class="l-font">{{this.LOA}} <span class="xs-font">$LOA</span></h1>
                    <button (click)="withdow()" class="btn buttonInv mt-1 mb-2">
                        WITHDRAW</button>

                </div>

            </div>
            <div class="col-12 col-md-6 mt-2">
                <div class="border-card ">
                    <div class="row">
                        <div class="col-md-6">
                            <h5 class="text-white text-capitalize">
                                total Friends
                            </h5>
                            <h1 class="l-font">{{refs.length}}</h1>
                        </div>
                        <div class="col-md-6 col-scroll">

                            <ol *ngIf="isResponse">
                                <li *ngFor="let ref of refs; index as i">{{ref.wallet_address|
                                    slice:0:6}}...{{ref.wallet_address| slice:4:10}}</li>
                            </ol>
                            <span *ngIf="!isResponse">Start inviting with your unique referral code!</span>
                        </div>


                    </div>


                </div>
            </div>

        </div>

    </div>



</section>


<section id="inviteFriends">
    <div class="col-12 text-center header_text pt-4">
        <h1 class="header_para">HOW TO INVITE FRIENDS</h1>

    </div>

    <div class="row justify-content-center mt-5">
        <div class="col-md-3 col-12  mt-2">
            <div class="border-card">
                <div class="col-12 text-center">
                    <p class="position-absolute m-0">1</p>
                    <img class="w-100" src="assets\png\ASSET_How To-3.png" alt="">
                    <h5 class="text-white text-capitalize" style="font-size:18px !important;">
                        Perform tasks and connect Wallet
                    </h5>
                    <h6 class="font-sm __font mobile_ds">
                        Connect a wallet and generate your referral link in the Referral section
                    </h6>
                </div>
            </div>
        </div>

        <div class="col-md-3 col-12  mt-2">
            <div class="border-card">
                <div class="col-12 text-center">
                    <p class="position-absolute m-0">2</p>
                    <img class="w-100" src="assets\png\ASSET_How To-4.png" alt="">
                    <h5 class="text-white text-capitalize">
                        Get A Referral link
                    </h5>
                    <h6 class="font-sm __font mobile_ds">
                        Invite your friends to register via <br> your referral link
                    </h6>
                </div>
            </div>
        </div>

        <div class="col-md-3 col-12  mt-2">
            <div class="border-card">
                <div class="col-12 text-center">
                    <p class="position-absolute m-0">3</p>
                    <img class="w-100" src="assets\png\ASSET_How To-5.png" alt="">
                    <h5 class="text-white text-capitalize">
                        Share and Earn $LOA
                    </h5>
                    <h6 class="font-sm __font mobile_ds">
                        Receive referral rewards in $LOA <br> tokens from your friends
                    </h6>
                </div>
            </div>
        </div>
    </div>

</section>

<section id="official">
    <div class="row">
        <div class="col-12 col-md-6">

            <iframe class="video m-0 w-100 p-0 " src="https://www.youtube.com/embed/h1NFYM13_qo"></iframe>

        </div>
        <div class="col-12 col-md-6">
            <div class=" header_text mt-5">
                <h1 class="header_para ">THE LEAGUE <br> OF ANCIENTS <br>OFFICIAL TRAILER </h1>

                <h6 class="font-sm __font mobile_ds mt-4">
                    BSC's First #FreetoPlay 5v5 MOBA<br> with NFT marketplace. Running on <br>$BinanceSmartChain
                </h6>

                <button (click)="witepaper();" class="btn walletButton mt-4">
                    LEARN MORE</button>
            </div>
        </div>

    </div>

    <div class="" style="padding: 66px;"></div>
    <div class="row">
        <div class="col-12 col-md-6  order-2 order-md-1 ">
            <div class=" header_text mt-5">
                <h1 class="header_para ">MEET THE DOERS <br> & BUILDERS</h1>

                <h6 class="font-sm __font mobile_ds mt-4">
                    The team always plays an important role in the success of a project. As a team, we have been working
                    with each other for more than 6 years, with everyone being highly experienced in their own expertise
                </h6>

                <button (click)="website()" class="btn walletButton mt-4">
                    LEARN MORE</button>
            </div>
        </div>
        <div class="col-12 col-md-6  order-1 order-md-2">

            <iframe class="video m-0 w-100 p-0 bo" src="https://www.youtube.com/embed/PMjeWA0cIxI"></iframe>

        </div>


    </div>
</section>
<app-footer></app-footer>