<div id="main" [ngStyle]="{ 'background': backGcolor }">
  <app-nav></app-nav>

  <div>
    <div class="text-end position-relative" style="margin-top: 10px">
      <button class="border-icon" [disabled]="counterSlid == 0" (click)="showPreviousHero()">
        <i class="fa fa-caret-left fa-sm"></i>
      </button>
      <a href="../heroes">
        <span class="border-icon m-0 px-2" role="button">
          <i class="fa fa-th-large fa-sm"></i><i class="fa fa-th-large fa-sm" style="margin-left: 1px"></i> </span></a>

      <button class="border-icon" [disabled]="this.counterSlid == this.listHeroA.length - 1" (click)="showNextHero()">
        <i class="fa fa-caret-right fa-sm"></i>
      </button>
    </div>
    <div *ngFor="let item of listHeroA; let i = index">
      <div *ngIf="i == counterSlid" @fade>
        <section class="pb-4 pt-0 h-100 overflow-hidden we" [ngStyle]="{ 'background-image': 'url(' +backGIm+ ')'}">
          <div class="col-12 m-0 row" data-aos="fade-up" data-aos-duration="2000">
            <!-- <div class="col-md-6 col-12 position-relative d-md-block d-none">
              <img class="w-100 img-header" [src]="item.mainImg" alt="" />
            </div> -->
            <div class="ml-auto col-md-6 col-12 mt-sm ">
              <p class="pt-5 mb-0">
                <img class="w-15" [src]="item.icon" alt="" />
                <strong>{{ item.iconName }}</strong>
              </p>
              <div>
                <h1 class="header_para mb-0 pb-0">{{ item.name }}</h1>
                <h6 class="font-sm __font fw-bolder text-white">
                  {{ item.subTitle }}
                </h6>
                <small class="fw-bolder text-white">Attack Type: {{ item.type }}</small>

                <p class="text-story mt-3 small fa-sm d-md-block d-none" *ngIf="i<=4">
                  {{ item.story }}.
                </p>

                <br>
                <button *ngIf="item.name=='LEON FIST'" (click)="open(mymodal)" class="btn button-border mt-3">READ FULL
                  STORY</button>
                <button (click)="story()" class="btn button-border mt-3" *ngIf="i<=4 && item.name!='LEON FIST'">READ
                  FULL STORY</button>
                <button class="btn button-border mt-3" *ngIf="i>4">FULL STORY<br>
                  COMING SOON
                </button>
              </div>
            </div>
          </div>
        </section>

        <section id="nft" class="position-relative">
          <div class="b-img">
            <div class="text-center">
              <h1 class="header_para mb-0">nft skins available</h1>
            </div>

            <div class="text-center h-img-center ">
              <div [@animImageSlider]="counter">
                <ng-container *ngFor="let img of item.imgList; let i = index">
                  <img [src]="img" class="w-img-center faa-float animated faa-slow" *ngIf="i === imgId - 1" />
                </ng-container>
              </div>
            </div>
            <div class="row justify-content-center m-top">
              <div class="col-md-2 col-4 text-center" [ngClass]="imgClass1" role="button" (click)="onClickImg(1)">
                <img class="w-75 mx-auto" [src]="item.img1Card" alt="" />
                <div class="text-center">
                  <h4 class="capsule-font">Basic</h4>
                </div>

              </div>
              <div class="col-md-2 col-4 text-center" [ngClass]="imgClass2" role="button" (click)="onClickImg(2)">
                <img class="w-75" [src]="item.img2Card" alt="" />
                <div class="text-center">
                  <h4 class="capsule-font">Epic</h4>
                </div>
              </div>
              <div class="col-md-2 col-4 text-center" [ngClass]="imgClass3" role="button" (click)="onClickImg(3)">
                <img class="w-75 mx-auto" [src]="item.img3Card" alt="" />
                <div class="text-center">
                  <h4 class="capsule-font">Elemental</h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="capsule" class="text-left">
          <div class="row">
            <div class="col-md-4 col-12">
              <img class="w-100 faa-float animated faa-slow"
                src="assets/png/IMG-hero/HeroListingPage/Capsules_Banner.png" alt="" />
            </div>
            <div class="col-md-8 col-12 d-flex align-items-center">
              <div class="col-12 text-md-start text-center header_text pt-4">
                <h1 class="header_para">THE LOA CAPSULES</h1>
                <h6 class="font-sm __font mobile_ds">
                  It's the best opportunity to own as many NFT Skins as you can! <br> Get your
                  Beta, Alpha
                  & Genesis capsules to summon the rarest skins across the Ancient Realm.
                </h6>

                <button class="btn button-border mt-3">OWN A CAPSULE NOW!</button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
  <ng-template #mymodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" style="color: black;" id="modal-basic-title">LEON FIST</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="col-12">
        <img class="w-15" style="width: 100% !important;
        height: 50% !important;" width="100" height="100" src="assets/png/IMG-hero/LeonFist/Header_IMG_LeonFist.png"
          alt="" />
      </div>
      <div class="col-12">
        <br>
        <h3 style="color: black;  text-align: justify;">LEON FIST STORY: <br></h3>
        <p class="text-justify" style="color: black;  text-align: justify;">
          Leon Is the eldest son of Mufasa, the ancient ruler of Zamunda: Kingdom of the wilds. With over 1000 years of serving his kingdom, Mufasa was finally ready to begin his eternal rest. On the evening of his passing, the ancient king lay on his deathbed with Leon by his side. He took his eldest son’s hand and whispered. “Leon, you shall be a great king, of this I have no doubt. Yet, I require one more kindness of you, take good care of your brother, his flame is great but his wisdom is lacking” Leon responded…”Father, rest and find peace. I promise, I will take care of my brother. I will pass on all you have taught me and you shall live forever — through us. I shall make you proud.” Mufasa’s old eyes glimmered with emotion.. As he uttered his last words, “My son, I am proud…” <br>

And so ended the 1000 year reign of Mufasa the Beast-King. Leon, his successor, was set to be a great king. He cherished all the wisdom his father gifted and possessed a passion for his people that could fuel the sun. After Mufasa’s funeral, All the tribal leaders from across Zamunda gathered to witness the coronation of the new King. Leon stood upon the altar of ascension, with all the leaders of his kingdom bearing witness. At his side stood his family, his 2 younger brothers, and his half-brother Anarch. <br>

As tradition demanded, Leon raised the radiant crown to the sky in preparation to recite the rites of ascension. It was at this moment that everything changed…. Leon’s eyes went wide, his arm still clutching the radiant crown, fell to the ground — parted from his body. To his side, stood a wickedly smiling Anarch holding a bloodied blade. Leon fell to the ground and watched in horror what would forever be known as the “Red Coronation”. <br>

 Anarch massacred everyone who wasn’t part of his plan. Both of Leon’s younger brothers, advisors and all loyalists were mercilessly executed. Leon lay on the cold stone floor, blood seeping from his body leaving him near lifeless. Anarch kneeled down and locked eyes with the fallen king, a wicked grin wide across his face… “Oh Leon, Leon, Leon, I have waited too long for this, I hope it didn’t ruin your special day, who’s the wiser one now?” With Leon’s lifeblood near depleted — he slipped into darkness. <br>

Anarch the Betrayer threw Leon’s lifeless body into the sewers amongst the fecal matter of his people. But Leon was not dead, he awoke in a dark room…around him hung trinkets of the forbidden arts…..before him stood a shadowy figure, with eyes of crimson. Leon gathered the strength to whisper… “who…. are…. you…?” The figure responded with a voice that sounded more akin to a hiss.. “Catalyst I am, your peoplessss call me the Witch Doctor, you are the son of Mufassssa, are you not?” “Yes… I am” Leon responded. “You arm, lossst…. Your crown…. Ssstolen, want it back do you?” asked the witch doctor. “What are you saying?” Leon asked impatiently. “Mufassssa was a good king, you are his chossssen, I can help you”. Replied Catalyst. “How could you possibly help me? He has taken the kingdom and slaughtered all who loved my father” Leon responded hopelessly “In you, I feel your father’sss sssoul, in you, I feel immeasurable rage. I will give you a new arm powered by the souls of your ancestors and cursed by the rage of your heart. With this arm you shall reforge your destiny. It will take time… and it will be unpleasssssssant.”. <br>

Leon stared at the witch doctor with burning eyes. “Do it” One morning, after weeks of suffering… Leon awoke, but this morning was different. It hadn’t begun with the witchery of Catalyst, or the anguish of his sorcery. This morning, Leon’s missing arm was no longer missing. The void was filled and he could fill the power of its presence. When Leon formed a fist, the arm took on a crimson glow, the color of Catalyst eyes. He could feel the rage pulsing through him. The witch doctor approached… “Sssoo, Leon Fist, what shall you do now?” Leon locked eyes with Catalyst “I am going to keep my promise” “promissse?” asked Catalyst curiously. With clenched teeth, Leon uttered “I am going to take good care of my brother”  <br>

In-game Role Leon Fist is a Hero with high durability. He is able to disrupt fights with his high CC engage KIT. When he activates Lion’s Rage he takes reduced damage from all sources of damage. <br>

        </p>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
    </div>
  </ng-template>
</div>
<app-footer></app-footer>
