import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HashStr } from 'src/app/helper/hash-str';
import * as data from '../../../json/wallets.json';
import { ReferralService } from '../referral/referral.service';


@Component({
  selector: 'app-token-presale',
  templateUrl: './token-presale.component.html',
  styleUrls: ['./token-presale.component.scss']
})
export class TokenPresaleComponent implements OnInit {

  public connected: boolean = false;
  private wallet: string = "";
  public fiveDigit: string = "";
  public lastSixDigit: string = "";
  public tryingToRaise: number = 100000; //TODO need to change to actul number (BNB to USD)
  public targetAchive: number = 0;
  public targetAchivePercentage: number = 0;
  public bnbAmout: string = "";
  public totalCommitted: number = 0; // (From BNB to USD)
  public convertedLoaToBnB: number = 0;
  public bnbDeposited: number = 0;
  public walletBalance: number = 0;
  public bnbCurrentPrice: number = 640;
  public _decimals: number = 1000000000000000000;
  public myBnb: number = 0;
  public myLoa: number = 0;
  public daily: number = 0;
  public leftOver: number = 0;
  public overPaid: number = 0;
  public loaLeft: number = 0;
  public whiteList: string = "";
  public showCelebrate: boolean = false;
  private walletArr: string[] = [];
  public showYay: string = "none";
  public _result: string = "";
  public _cssResult: string = "";
  public totalClaim: number = 0;
  public outOf: number = 0;
  public presaleDistributionDate: number = 1_639_26_7200;
  public lastClaimDate: any;
  public todayDate: any;
  public avilableToClaim: number;
  public dates: number[] = [];

  constructor(private hashStr: HashStr, private service: ReferralService, private toastr: ToastrService) {

  }


  ngOnInit(): void {


    this.walletArr = data.wallets;
    this.toLowerCase();
    if (this.getUserWallet() != null) {
      this.connected = true;
      this.connectWallet();
      this.wallet = this.getUserWallet();
      this.fiveDigit = this.wallet.slice(0, 7);
      this.lastSixDigit = this.wallet.slice(this.wallet.length - 7, this.wallet.length);


    }
    this.getWalletBalance();
    this.onWalletChangeEvent();
  }

  /**
   * @dev trigger this event only when user change account on metamask
   * @return void
   */
  onWalletChangeEvent = async () => {

    window.ethereum.on('accountsChanged', async (accounts) => {
      this.setUserWallet(accounts[0]);
      this.wallet = this.getUserWallet();
      this.fiveDigit = this.wallet.slice(0, 7);
      this.lastSixDigit = this.wallet.slice(this.wallet.length - 7, this.wallet.length);
      location.reload();
    });

  }

  /**
   * @dev connecting the wallet logic
   * calling the service to check if the user
   * trying to connect BSC Mainnet
   * if not then just switch to BSC.
   * @return void
   */
  connectWallet = async () => {

    if (this.getUserWallet() != null) { return }
    var res = await this.service.connectAccount();
    if (res.status) {
      this.connected = true;
      this.wallet = res.wallet;
      this.fiveDigit = this.wallet.slice(0, 7);
      this.lastSixDigit = this.wallet.slice(this.wallet.length - 7, this.wallet.length);
      this.setUserWallet(this.wallet)
    } else {
      this.connected = false;
      console.log('Not Connected.');
    }
  }
  /**
   * @param wallet string User wallet
   * @dev saving the wallet in the localStorage (hashed)
   * @return void
   */
  setUserWallet(wallet: string) {
    localStorage.setItem('__W__', this.hashStr.encrypt(wallet));
  }

  /**
   * @dev getting the wallet from the localStorage
   * and decrypted it and return it as normal string
   * @return String Wallet | null
   */
  getUserWallet() {
    if (localStorage.getItem('__W__')) {
      var base = atob(localStorage.getItem('__W__'))
      var decrypted = this.hashStr.decrypt(base)
      return decrypted
    }
    return null;
  }

  /**
   * @dev fetch the information from the smart
   * contract if the user connect the wallet (Required)
   * @return void
   */
  async fetchContractInfo() {
    if (!this.connected) { return }
    let totalCommited = await this.service.getTotalDeposit() / this._decimals;
    this.totalCommitted = totalCommited * this.bnbCurrentPrice;
    if (this.totalCommitted > this.tryingToRaise) { this.targetAchivePercentage = (this.totalCommitted / this.tryingToRaise) * 100; this.targetAchivePercentage = Math.round(this.targetAchivePercentage) }
    this.myBnb = await this.service.getTotalDepositByAddress() / this._decimals;
    this.myLoa = (Number(this.myBnb) * this.bnbCurrentPrice) / 0.05
  }

  /**
   *
   * @param value number BNB vale
   * @dev calculate LOA token based on BNB count
   * @return void
   */
  onSearchChange(value: any) {
    this.convertedLoaToBnB = (Number(value) * this.bnbCurrentPrice) / 0.05;
  }

  /**
   * @dev get user wallet balance and show it
   * if wallet connect only
   * @return void
   */
  async getWalletBalance() {
    if (!this.connected) return;
    let balance = await this.service.getWalletBalance();
    let rounded = ((balance / this._decimals).toFixed(2));
    this.walletBalance = Number(rounded);

    this.fetchContractInfo();
    this.getDailyLOA();
  }

  /**
   * @dev use max user wallet balance
   * from the walletBalance var and assign it to
   * input var.
   * @return void
   */
  useMaxBalance() {
    this.bnbAmout = String(this.walletBalance);
    this.convertedLoaToBnB = (Number(this.bnbAmout) * this.bnbCurrentPrice) / 0.05;
  }

  /**
   * @dev deposit bnb using transaction web3 function
   * @return void
   */
  async deposit() {
    this.toastr.success('Presale has ended.')
    return;
    if (!this.connected) { return }
    if (this.bnbAmout != "")
      await this.service.deposit(this.bnbAmout);
    else
      this.toastr.error("BNB can't be empty");

  }

  cancel() {
    this.bnbAmout = "";
    this.convertedLoaToBnB = 0;
  }

  /**
   * @dev get Daily claim token
   * check if it null means the presale didn't end yet
   * @return void
   */
  async getDailyLOA() {
    if (!this.connected) { return }
    this.daily = await this.service.getEcr20AwardedDay();
    let totalCommited = await this.service.getTotalDeposit() / this._decimals;
    this.totalCommitted = totalCommited * this.bnbCurrentPrice;


    this.leftOver = this.totalCommitted - this.tryingToRaise;

    let myCurrnetBnb = this.myBnb * this.bnbCurrentPrice;
    let toReturn = (myCurrnetBnb * this.leftOver) / this.totalCommitted;
    let currentBalance = myCurrnetBnb - toReturn;

    // this.loaLeft = (currentBalance / 0.05)
    let over = toReturn / this.bnbCurrentPrice;
    var rounded = (Math.round(over * 100) / 100).toFixed(2);
    this.overPaid = Number(rounded);

    if (this.daily > 0) {
      var claimed = await this.service.getTotalClaim()

      var claimDecimal = claimed / this._decimals;
      var roundedClaim = (claimDecimal).toFixed(2);

      this.totalClaim = Number(roundedClaim) * this.daily;
      var presaleDate = new Date(this.presaleDistributionDate * 1000);
      var currentDate = new Date();
      var diff = Math.abs(currentDate.getTime() - this.addDays(presaleDate, this.daily).getTime());


      var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

      // this.avilableToClaim = diffDays * Number(roundedClaim);
      this.outOf = claimDecimal * 200;
      this.totalCommitted = totalCommited * this.bnbCurrentPrice;
      this.leftOver = this.totalCommitted - this.tryingToRaise;

      let myCurrnetBnb = this.myBnb * this.bnbCurrentPrice;
      let toReturn = (myCurrnetBnb * this.leftOver) / this.totalCommitted;
      let currentBalance = myCurrnetBnb - toReturn;

      // this.loaLeft = (currentBalance / 0.05)
      let over = toReturn / this.bnbCurrentPrice;
      var rounded = (Math.round(over * 100) / 100).toFixed(2);
      this.overPaid = Number(rounded);
    }
  }

  async claimLoa() {
    window.open('https://dashboard.leagueofancients.com/presale', '_blank');
    return;
    if (!this.connected) { return }
    if (this.daily == null) { return }
    await this.service.claimLoa();
  }

  searchWhileList(wallet: string) {
    if (wallet.length == 0) {
      this._result = "";
      this.showCelebrate = false;
      return;
    }
    if (this.walletArr.includes(wallet.toLowerCase())) {
      this.showCelebrate = true;
      this._cssResult = "green";
      this._result = "Congratulations! You are whitelisted.";
    } else {
      this._cssResult = "red"
      this.showCelebrate = false;
      this._result = "Sorry! This wallet is not whitelisted.";
    }
  }

  viewAll() {
    window.location.href = "https://docs.google.com/spreadsheets/d/1NJb4e2mgHANK692_qSOnOeMhemjaWc2Uuo-i002r7q8/edit#gid=0"
  }

  toLowerCase() {
    for (var i = 0; i < this.walletArr.length; i++) {
      this.walletArr[i] = this.walletArr[i].toLowerCase();
    }
  }
  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

}
