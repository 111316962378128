import { Injectable } from '@angular/core';
import { Web3HelperPro } from 'src/app/Helper/web3-helper_pro';
import { nftPublicSaleAddress, nftPublicSaleAbi } from '../../../../src/abis.js'
@Injectable({
  providedIn: 'root'
})

export class PublicSaleService {
  private contract: any;
  private _wallet: string;

  constructor(private web3Helper: Web3HelperPro) { }

  /**
   * @dev connect wallet using Helper TS file
   */
  connectWallet = async (): Promise<any> => this.web3Helper.connectWallet();

  /**
   * 
   * @param abi string[] determin contract functions
   * @param address string contract address
   * @return Contract Object
   */
  loadContract = async () => await this.web3Helper.contract(nftPublicSaleAbi, nftPublicSaleAddress);


  /**
   * @dev get Connected Wallet
   */
  getConnectedWallet = () => this.web3Helper.getConnectedWallet();


  /**
   * 
   * @param contract 
   * @param T 
   * @param address 
   * @param _value 
   * @param _param 
   */
  sendContractRequest = async (contract: any, T: any, address: string, _value: number, _param: any[]) => {
    if (_value == null) {
      return this.web3Helper.contractSend(contract, T, address, null, _param);
    }
    var roundedValue = _value.toFixed(2);
    return this.web3Helper.contractSend(contract, T, address, this.web3Helper.convertToWei(String(roundedValue)), _param);
  }

  /**
   * 
   * @param contract 
   * @param T 
   * @param address 
   * @param _param 
   */
  callContract = async (contract: any, T: any, address: string, _param: any[]) => this.web3Helper.contractCall(contract, T, address, _param);

}
