import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as AOS from 'aos';
import { ToastrService } from 'ngx-toastr';
import { PrivateSaleService } from 'src/app/components/private-sale/private-sale.service';
import { ReferralService } from 'src/app/components/referral/referral.service';
import { HashStr } from 'src/app/helper/hash-str';
import { LocalstorageService } from 'src/app/Service/localstorage.service';
import { WalletService } from 'src/app/Service/wallet.service';
import { ReferralSerService } from './referralSer.service';



@Component({
  selector: 'app-referral-page',
  templateUrl: './referral-page.component.html',
  styleUrls: ['./referral-page.component.scss']
})
export class ReferralPageComponent implements OnInit {

  isResponse: boolean = true;

  refs: any[] = [];


  scroll;
  email;
  url: string = "";
  wallet: string | any;
  referral: string = "";
  refUserWallet: string;
  codeEmailString: string;
  //validat code
  codeDiscord: number;
  codeEmail: number;


  user: User;
  loadingSendEmail: boolean = false;

  follow: number = 0;
  refId: number;

  isConnect: boolean = false;
  isDisabled: boolean = true;
  isResponseRefs: boolean = true;

  isSendEmail: boolean = false;
  isSendCode: boolean = false;
  showHowDis: boolean = false;

  isFollow = { dis: false};
  @ViewChild("tooltip") tooltip: MatTooltip;
  @ViewChild("content") modalConnect: NgbModal;
  @ViewChild("fEmail") public fEmail: NgForm;


  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private service: ReferralSerService,
    private route: ActivatedRoute,
    private localStorageObj: LocalstorageService,
    private walletService: WalletService,
    private cd: ChangeDetectorRef,
    private hashStr: HashStr,
    private serviceR: PrivateSaleService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.referral = params["vHash"];
    });
  }

  ngOnInit(): void {
    AOS.init();
    // if user follow as on discord  before
    this.initIsFollow();
    // get user vHash
    this.getRefUserWallet();
    //get user wallet and url
    this.getUserOnInit();
  }

  getRefUserWallet() {
    if (this.referral != undefined || this.referral != null) {
      var base = atob(this.referral);
      let obj = JSON.parse(this.hashStr.decrypt(base))
      this.refUserWallet = obj.wallet;
      this.refId = obj.ref_id;

    }

    console.log(' this.refUserWallet :>> ',  this.refUserWallet);
    console.log(' this.refId :>> ',  this.refId);
  }
  initIsFollow() {
    if (this.getCounter() == null) {
      this.setCounter(this.isFollow);
    } else {
      this.isFollow = this.getCounter();
    }
  }
  getUserOnInit() {
    if (this.walletService.getUserWallet() != null) {
      this.wallet = this.walletService.getUserWallet();
      this.getUser();
    }
  }
  getUser = async () => {

    try {

      console.log('res :>> ', this.wallet);


     await this.service.getUser(this.wallet).then((res: any) => {

      console.log('res :>> ', res);
      this.user = res.data;
      this.url = "https://leagueofancients.com/referral/?vHash=" + this.createUrl(this.user.id);
      this.refs = this.user.referrel_users;
      this.localStorageObj.setItemlocalStorage("__U__", this.user);
    })

  } catch (error) {
    console.log('res :>> ', error);
  }
  };

  createUrl = (id) => {
    let obj = {
      wallet: this.wallet,
      ref_id: id,
    };

    return this.hashStr.encrypt(JSON.stringify(obj));
  };
  getCounter() {

    if (this.localStorageObj.getItemlocalStorage("__F__")) {
      return this.localStorageObj.getItemlocalStorage("__F__");
    }
    return null;
  }
  async dis() {
    window.open("https://discord.gg/pkuVvrhucR", "_blank");
    this.isFollow.dis = true;
    this.setCounter(this.isFollow);
  }


  setCounter(followUs: any) {
    this.localStorageObj.setItemlocalStorage("__F__", followUs);
  }
  async btnConnectWallet() {
    if ( this.isFollow.dis) {
      this.open(this.modalConnect);
    } else {
      this.toastr.error(
        "Join our Discord ",
        "Complete your tasks first!"
      );

      var elmnt = document.getElementById('followUsDiscord');
      elmnt.scrollIntoView();
      return;
    }
  }
  showSumm(){
  var elmnt = document.getElementById('summary');
  elmnt.scrollIntoView();

}
  withdraw() {
    this.toastr.success('Withdrawal only available after Listing on Pancakeswap. Vesting schedule applies.');
  }
  onSendEmail() {
    if (this.isValidEmail(this.email)) {
      this.loadingSendEmail = true;

      let obj = {
        email: this.email,
        ref_id: this.refId,
      };

      console.log('obj :>> ', obj);

      this.sendEmail(obj);
    }
  }
  sendEmail = async (obj: any) => {
    try {
      // TODO  send email
      let res: any = await this.service.sendEmail(obj);

      console.log('res :>> ', res);
      this.loadingSendEmail = false;
      this.isSendEmail = true;
      // await this.delay(5);

    } catch (error) {
      this.loadingSendEmail = false;
      this.toastr.error("Internal Error");
    }
  };
  async onSaveCode() {
    this.loadingSendEmail = true;
    try {
      let obj = {
        email: this.email,
        code: this.codeEmail,

      };

      console.log('onSaveCode :>> ', obj);
      let res: any = await this.service.checkEmailCode(obj);
      this.codeEmailString = String(this.codeEmail);
      this.codeEmailString = this.codeEmailString.split("").reverse().join("");

      if (this.codeDiscord == Number(this.codeEmailString) - 117) {

        this.modalService.dismissAll();
        this.toastr.success("successfully done");
        this.localStorageObj.setItemlocalStorage("__t__", res.data.token_api);
        this.loadingSendEmail = false;
        this.isSendCode = true;
        this.onConnectWallet();

      } else {
        this.loadingSendEmail = false;
        this.toastr.error("Invalid discord code, please make sure to copy the right code.");
      }


    } catch (error) {
      this.loadingSendEmail = false;
      this.toastr.error("Invalid code Gmail code or dicord.");
      // console.log("res :>> ", error);
    }
  }
  async onConnectWallet() {

    try {



    // let res: any = await this.service.connectWallet();
    var res = await this.serviceR.connectWallet();
    console.log('res :>> ',res );
    this.wallet = res.wallet;
    this.walletService.setUserWallet(this.wallet);

    this.getUser();
  } catch (error) {

    console.log('error :>> ', error);
  }
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public isValidEmail(emailString: string): boolean {
    try {
      let pattern = new RegExp("[a-zA-Z0-9_.+-]+@gmail+.com+");
      let valid = pattern.test(emailString);
      return valid;
    } catch (TypeError) {
      return false;
    }
  }




  copy() {
    setTimeout(() => [(this.isDisabled = false), this.tooltip.show()], 50);
    this.cd.detectChanges();
    setTimeout(() => (this.isDisabled = true), 3000);
  }
  withDraw() {
    this.toastr.success("Withdrawal only available after Listing on Pancakeswap. Vesting schedule applies.");
  }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => { },
        (reason) => { }
      );
  }
}
interface User {
  id: number;
  wallet_address: string;
  email: string;
  token: number;
  is_banned: boolean;
  is_first_time: false;
  user_id: number;
  email_verified_at: Date;
  created_at: Date;
  token_api: string;
  referrel_users: any[];
  referrel_by_user: any[];
}

class User implements User { }

