import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTooltipModule} from '@angular/material/tooltip';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from 'ngx-clipboard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { ReferralPageComponent } from './referral-page.component';
import { ReferralPageRoutingModule } from './referral-page-routing.module';


@NgModule({
  declarations: [
    ReferralPageComponent
  ],
  imports: [
    CommonModule,
    MatTooltipModule,ReferralPageRoutingModule,
    ClipboardModule, NgbModule,FormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReferralPageModule { }
