<span class="i">
  <nav class="sticky navbar navbar-expand-lg nav_ss" id="navigation">
    <button class="navbar-toggler" type="button" (click)="isShown = !isShown" data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span style="width: 20px" class="navbar-toggler-icon"><svg aria-hidden="true" focusable="false" data-prefix="fas"
          data-icon="bars" class="svg-inline--fa fa-bars fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512">
          <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163
              16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0
              8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163
              16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0
              8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163
              16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0
              8.837 7.163 16 16 16z"></path>
        </svg></span>
      <!-- <i class="fas fa-bars"></i> -->
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent height-top" [ngClass]="{ show: isShown }">
      <ul class="navbar-nav">

        <div class="col-md-12 col-12 col-sm-12 text-center logo d-md-block d-none  ">
          <img (click)="home()" class="logo w-25" src="../../../assets/png/Asset_Header_Logo.png" alt="" />
        </div>


      </ul>

      <ul class="navbar-nav  ml-auto">
        <!-- <li class="nav-item ">
          <a class="text-uppercase monsta_font" href="our-team"> Team & Advisors</a>
        </li> -->
        <li class="nav-item">
          <a class="text-uppercase monsta_font" href="partnership"> Partnership </a>
        </li>
        <li class="nav-item">
          <a class="text-uppercase monsta_font" href="public-sale"> PUBLIC SALE</a>
        </li>
        <li class="nav-item">
          <a class="text-uppercase monsta_font" href="referral"> REFERRAL </a>
        </li>
        <li class="nav-item">
          <a class="text-uppercase monsta_font" href="heroes"> HEROES </a>
        </li>
        <!-- <li class="nav-item ">
          <a class="text-uppercase monsta_font" href="presale"> PRESALE </a>
        </li> -->

        <!-- <li class="nav-item ">
          <a class="text-uppercase monsta_font" href="public-sale"> NFT SALE </a>
        </li> -->
        <li class="nav-item  nav__item--backgr">
          <a class="text-uppercase monsta_font" href="https://marketplace.leagueofancients.com/home"> MARKETPLACE
          </a>
        </li>
        <li class="nav-item ">
          <a class="text-uppercase monsta_font mr-2" href="https://dashboard.leagueofancients.com/">LOA Dashboard </a>
        </li>
      </ul>
    </div>
  </nav>
</span>
