import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss']
})
export class OurTeamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  listTeam = [
    { name: "Dwayne Ong", des: "Project Lead", img: "assets/png/Advisor/do.png" }
    // , { name: "Kenneth Choong", des: "Product Development Lead", img: "assets/png/Advisor/kc.png" }
    // , { name: "Abdulwahab Herbli", des: "Tech Lead", img: "assets/png/Advisor/Asset_Team-9.png" }
    // , { name: "Vivienne Chin", des: "Assistant Project Lead", img: "assets/png/Advisor/vc.png" }
    // , { name: "Gavin Liu", des: "Game Development Lead", img: "assets/png/Advisor/gl.png" }
    // , { name: "Arun", des: "Blockchain Developer", img: "assets/png/Advisor/aj.png" }
    // , { name: "Stephanie Yeong ", des: "Marketing Lead", img: "assets/png/Advisor/sy.png" }
    // , { name: "Boey Jin Huey", des: "Strategy and Partnership Lead", img: "assets/png/Advisor/Boey(W).jpg" }
    // , { name: "Chien", des: "Concept Artist", img: "assets/png/Advisor/Asset_Team-13.png" }
    // , { name: "Ryan Arquiza", des: "Investor Relations Lead", img: "assets/png/Advisor/Ryan(W).jpg" }
    // , { name: "Lee Jun Da", des: "Community and Ambassador Lead", img: "assets/png/Advisor/Asset_Team-15.png" }
  ]

  goTo() {
    window.location.href = "https://forms.gle/HAeYxmuFdKFSpGUk8"
  }
  whitePaper() {
    window.location.href = " https://league-of-ancients-v2.gitbook.io/whitepaper/"
  }
  referral() {
    window.location.href = 'https://leagueofancients.com/referral'
  }

  sendCv() {
    window.location.href = "https://forms.gle/Pq8zCN16Vd7UTZuRA"
  }
}
