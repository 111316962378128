import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
@Component({
  selector: 'app-hero-page',
  templateUrl: './hero-page.component.html',
  styleUrls: ['./hero-page.component.scss'],
})
export class HeroPageComponent implements OnInit {
  constructor(private router: Router) { }

  ngOnInit(): void {
    AOS.init();
  }
  onSelectHero(id: number) {
    console.log("id::", id)
    localStorage.setItem('id', JSON.stringify(id));
    this.router.navigate(['hero-detail'])
  }

  listImgHero = [
    {
      id: '0',
      img: 'assets/png/IMG-hero/HeroListingPage/Thumbnail_LeonFIst.png',
      name: 'Leon FIst',
    },
    {
      id: '1',
      img: 'assets/png/IMG-hero/HeroListingPage/Thumbnail_Lorie.png',
      name: 'Lorie',
    },
    {
      id: '2',
      img: 'assets/png/IMG-hero/HeroListingPage/Thumbnail_KekTheTOAD.png',
      name: 'Kek The TOAD',
    },
    {
      id: '3',
      img: 'assets/png/IMG-hero/HeroListingPage/Thumbnail_MaskedYasuke.png',
      name: 'Masked Yasuke',
    },
    {
      id: '4',
      img: 'assets/png/IMG-hero/HeroListingPage/Thumbnail_Neekomani.png',
      name: 'Neekomani',
    },

    {
      id: '5',
      img: 'assets/png/IMG-hero/HeroListingPage/Thumbnail_Atalanta.png',
      name: 'Atalanta',
    },
    {
      id: '6',
      img: 'assets/png/IMG-hero/HeroListingPage/Thumbnail_Tarlos.png',
      name: 'Tarlos',
    },
    {
      id: '7',
      img: 'assets/png/IMG-hero/HeroListingPage/Thumbnail_Nightshade.png',
      name: 'Nightshade',
    },

    {
      id: '8',
      img: 'assets/png/IMG-hero/HeroListingPage/Thumbnail_Mortus.png',
      name: 'Mortus',
    },
    {
      id: '9',
      img: 'assets/png/IMG-hero/HeroListingPage/Thumbnail_Calantha.png',
      name: 'Calantha',
    },
  ];
  onBuyClickListener() {
    location.href = "https://leagueofancients.com/public-sale"
  }
}
