<div id="main">
  <div class="col-12 row m-0 justify-content-between">
    <div class="col-md-3 col-6">
      <a href="https://leagueofancients.com"><img class="w-100" src="assets/png/Asset_Header_Logo.png" alt="LOA" /></a>
    </div>
    <div class="col-md-3 text-center col-6">
      <button *ngIf="!connected" (click)="connectWallet()" class="btn button-border mt-4">
        <i class="fas fa-wallet walletIcon"> </i> Connect wallet
      </button>
      <button *ngIf="connected" class="btn button-border mt-4">
        <i class="fas fa-wallet walletIcon"> {{fiveDigit}}...{{lastSixDigit}} </i>
      </button>
    </div>
  </div>
  <section>
    <div class="mainBlock col-md-10 col-12 text-center m-auto">

    </div>
  </section>
</div>

<section *ngIf="stillGoing" id=detail>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">

      </div>
      <div class=" col-12">
        <div class="countdown">
          <div class="countdown-item">
            <span id="day">-</span>
            <span>Day</span>
          </div>

          <div class="countdown-item">
            <span id="hour">-</span>
            <span>Hour</span>
          </div>

          <div class="countdown-item">
            <span id="minute">-</span>
            <span>Minute</span>
          </div>

          <div class="countdown-item">
            <span id="second">-</span>
            <span>Second</span>
          </div>

          <div class="pulse"></div>
        </div>
      </div>
      <div class="text-center">

      </div>
    </div>
  </div>
</section>
<section id="detail">
  <div class="container">
    <div class="row ">


      <!-- Beta -->
      <div *ngIf="betaCan" class="col-md-3 col-12 pt-2">
        <div class="card card_color card-shape">
          <img class="card-img-top" src="assets/png/GG/beta.png" alt="Beta">
          <div class="card-body">
            <h2 class="card-title font_title_size">Beta Capsule</h2>
            <p class="card-text subText">55% chance Basic <br> 30% chance Epic <br> 15% chance Elemental<br>
              +
              <strong>
                {{betaMBy}} $LOA
                Tokens</strong> <img class="card__logo_des img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" height="20" width="20" alt="" />
              <br> + Beta Access Key <img height="18" width="18" class="card__logo_des img_loa" src="assets/png/tokenPresaleAsset/key.png" alt="" />
            </p>
            <div class="text-center">
              <h4 class="font_price_unit">0.05 BNB / Unit</h4>
            </div>
          </div>

          <div class="card-body">
            <div class="justify-content-center d-flex text-center LOA_border">
              <img class="card__logo img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />
              <span> {{loaTokenBeta.toFixed(2)}}</span>
            </div>
            <div class="justify-content-center d-flex text-center pt-3">


              <button (click)="betaMinus('beta')" type="button" class="btn btn-default btn-number minus_style">
                -
              </button>



              <input (ngModelChange)="onChange($event)" [(ngModel)]="betaCount" type="number" value="{{betaCount}}" id="LOA" name="LOATOKEN" class="text-cetner" min="200" max="80000" disabled>



              <button (click)="betaPlus('beta')" type="button" class="btn btn-default btn-number plus_style"> +
              </button>




            </div>
          </div>
          <div class="card-footer text-muted justify-content-center text-center ">
            <div class="col-12 justify-content-center pt-1">
              <h5 class="orang-bnb">BNB: {{((betaPrice/BnbPrice) * betaCount).toFixed(2)}}</h5>
              <h4 class="font_price">${{betaCount * betaPrice}}</h4>
            </div>
            <div class="col-12 justify-content-center ">
              <span class="text-center">{{betaRemaining}} of {{betaTotal}} Remaining </span>
            </div>


          </div>
          <div class="col-12">
            <button *ngIf="betaRemaining>0" (click)="onBtnClickListner('beta')" type="button" class="btn btn-primary btnBuy">{{betaRemaining>0?'BUY NOW':'SOLD OUT'}}</button>
            <button *ngIf="betaRemaining<=0" (click)="onBtnClickListner('beta')" type="button" class="btn btn-primary btnBuy finished">{{betaRemaining>0?'BUY NOW':'SOLD OUT'}}</button>
          </div>
        </div>
      </div>

      <!-- Alpha -->
      <div *ngIf="alphaCan" class="col-md-3 col-12 pt-2">
        <div class="card card_color card-shape">
          <img class="card-img-top" src="assets/png/GG/alpha.png" alt="Beta">
          <div class="card-body">
            <h2 class="card-title font_title_size">Alpha Capsule</h2>
            <p class="card-text subText">55% chance Champion <br> 30% chance Legendary <br> 15% chance
              Mythical<br>
              +
              <strong>
                {{alphaMBy}} $LOA
                Tokens</strong><img class="card__logo_des img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" height="20" width="20" alt="" />
              <br> + Alpha Access Key <img height="18" width="18" class="card__logo_des img_loa" src="assets/png/tokenPresaleAsset/key.png" alt="" />
            </p>

            <div class="text-center">
              <h4 class="font_price_unit">0.5 BNB / Unit</h4>
            </div>
          </div>

          <div class="card-body">
            <div class="justify-content-center d-flex text-center LOA_border">
              <img class="card__logo img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />
              <span> {{loaTokenAlpha.toFixed(2)}}</span>
            </div>
            <div class="justify-content-center d-flex text-center pt-3">

              <button (click)="betaMinus('alpha')" type="button" class="btn btn-default btn-number minus_style">
                -
              </button>

              <input (ngModelChange)="onChange($event)" [(ngModel)]="alphaCount" type="number" value="{{alphaCount}}" id="LOA" name="LOATOKEN" class="text-cetner" min="200" max="80000" disabled>

              <button (click)="betaPlus('alpha')" type="button" class="btn btn-default btn-number plus_style"> +
              </button>


            </div>
          </div>
          <div class="card-footer text-muted justify-content-center text-center ">
            <div class="col-12 justify-content-center pt-1">
              <h5 class="orang-bnb">BNB: {{((alphaPrice/BnbPrice) * alphaCount).toFixed(2)}}</h5>
              <h4 class="font_price">${{alphaCount * alphaPrice}}</h4>
            </div>
            <div class="col-12 justify-content-center ">
              <span class="text-center">{{alphaRemaining}} of {{alphaTotal}} Remaining </span>
            </div>


          </div>
          <div class="col-12">
            <button *ngIf="alphaRemaining>0" (click)="onBtnClickListner('alpha')" type="button" class="btn btn-primary btnBuy">{{alphaRemaining>0?'BUY NOW':'SOLD OUT'}}</button>
            <button *ngIf="alphaRemaining<=0" (click)="onBtnClickListner('alpha')" type="button" class="btn btn-primary btnBuy finished">{{alphaRemaining>0?'BUY NOW':'SOLD OUT'}}</button>
          </div>
        </div>
      </div>

      <!-- Genisis -->
      <div *ngIf="genesisCan" class="col-md-3 col-12 pt-2">
        <div class="card card_color card-shape">
          <img class="card-img-top" src="assets/png/GG/genesis.png" alt="Beta">
          <div class="card-body">
            <h2 class="card-title font_title_size">Genesis Capsule</h2>
            <p class="card-text subText">100% chance Genesis <br> <br><br>
              +
              <strong>
                {{genisisMBy}} $LOA
                Tokens</strong> <img class="card__logo_des img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" height="25" width="25" alt="" />
              <br>
              <br>
            </p>
            <div class="text-center">
              <h4 class="font_price_unit"> 1.6 BNB / Unit</h4>
            </div>
          </div>

          <div class="card-body">
            <div class="justify-content-center d-flex text-center LOA_border">
              <img class="card__logo img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />
              <span>{{loaTokenGenesis}}</span>
            </div>
            <div class="justify-content-center d-flex text-center pt-3">


              <button (click)="betaMinus('genesis')" type="button" class="btn btn-default btn-number minus_style">
                -
              </button>



              <input (ngModelChange)="onChange($event)" [(ngModel)]="genesisCount" type="number" value="{{genesisCount}}" id="LOA" name="LOATOKEN" class="text-cetner" min="200" max="80000" disabled>



              <button (click)="betaPlus('genesis')" type="button" class="btn btn-default btn-number plus_style"> +
              </button>




            </div>
          </div>
          <div class="card-footer text-muted justify-content-center text-center ">
            <div class="col-12 justify-content-center pt-1">
              <h5 class="orang-bnb">BNB: {{((genesisPrice/BnbPrice) * genesisCount).toFixed(2)}}</h5>
              <h4 class="font_price">${{genesisPrice* genesisCount}}</h4>
            </div>
            <div class="col-12 justify-content-center ">
              <span class="text-center">{{genesisRemaining}} of {{genesisTotal}} Remaining </span>
            </div>


          </div>
          <div class="col-12">
            <button *ngIf="genesisRemaining>0" (click)="onBtnClickListner('genesis')" type="button" class="btn btn-primary btnBuy">{{genesisRemaining>0?'BUY NOW':'SOLD OUT'}}</button>
            <button *ngIf="genesisRemaining<=0" (click)="onBtnClickListner('genesis')" type="button" class="btn btn-primary btnBuy finished">{{genesisRemaining>0?'BUY NOW':'SOLD OUT'}}</button>
          </div>
        </div>
      </div>



      <!-- immortal -->
      <div *ngIf="immortalCan" class="col-md-3 col-12 pt-2">
        <div class="card card_color card-shape">
          <img class="card-img-top" src="assets/png/GG/immortal.png" alt="Beta">
          <div class="card-body">
            <h2 class="card-title font_title_size">Immortal Skin</h2>
            <p class="card-text subText">100% chance Immortal <br> <br><br>
              +
              <strong>
                {{immortalMBy}} $LOA
                Tokens</strong> <img class="card__logo_des img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" height="25" width="25" alt="" />
              <br>
              <br>

            </p>
            <div class="text-center">
              <h4 class="font_price_unit"> 16 BNB / Unit</h4>
            </div>
          </div>

          <div class="card-body">
            <div class="justify-content-center d-flex text-center LOA_border">
              <img class="card__logo img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />
              <span> {{loaTokenImmortal}}</span>
            </div>
            <div class="justify-content-center d-flex text-center pt-3">


              <button (click)="betaMinus('immortal')" type="button" class="btn btn-default btn-number minus_style">
                -
              </button>



              <input (ngModelChange)="onChange($event)" [(ngModel)]="immortalCount" type="number" value="{{immortalCount}}" id="LOA" name="LOATOKEN" class="text-cetner" min="200" max="80000" disabled>



              <button (click)="betaPlus('immortal')" type="button" class="btn btn-default btn-number plus_style"> +
              </button>




            </div>
          </div>
          <div class="card-footer text-muted justify-content-center text-center ">
            <div class="col-12 justify-content-center pt-1">
              <h5 class="orang-bnb">BNB: {{((immortalPrice/BnbPrice) * immortalCount).toFixed(2)}}</h5>
              <h4 class="font_price">${{immortalPrice * immortalCount}}</h4>
            </div>
            <div class="col-12 justify-content-center ">
              <span class="text-center">{{immortalRemaning}} of {{immortalTotal}} Remaining </span>
            </div>


          </div>
          <div class="col-12">
            <button *ngIf="immortalRemaning>0" (click)="onBtnClickListner('immortal')" type="button" class="btn btn-primary btnBuy ">{{immortalRemaning>0?'BUY NOW':'SOLD OUT'}}</button>
            <button *ngIf="immortalRemaning<=0" (click)="onBtnClickListner('immortal')" type="button" class="btn btn-primary btnBuy finished">{{immortalRemaning>0?'BUY NOW':'SOLD OUT'}}</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>


<!-- Roles  -->
<section id="detail">
  <div class="card-img position-relative card mt-5">

    <h5 class="display-5 card__desc--orange text-center fw-bolder new-color">
      Instructions & Rules
    </h5>



    <div class="card__divider"></div>

    <div class="row justify-content-end">
      <div class="ml-auto col-12 col-md-6">
        <div class="pb">
          <span class="card__desc--orange margin_rem text-center">
            1. There will be four NFT Public Sales. The date and time are as follows:<br>
            - Immortal Skin: 3 December 2021, 00:00 UTC<br>
            - Genesis Capsule: 5 December 2021, 00:00 UTC<br>
            - Alpha Capsule: 7 December 2021, 00:00 UTC<br>
            - Beta Capsule: 9 December 2021, 00:00 UTC<br>
            2. All previous Pre-Sales whitelisters will be given 5 minute early access, starting from 00:00 UTC until
            00:05 UTC for all four NFT Sales above.<br>
            3. The public will be able to start purchasing in all four NFT Sales from 00:05 UTC onwards.<br>
            4. Users will need to connect their Metamask wallet on the “Binance Smart Chain” network to participate.<br>
            5. 1 BNB = $625 and will be used in the above price calculations.<br>
            6. There is no maximum limit for the amount of purchase and will be first come, first serve basis during
            this NFT Public Sale.<br>
            7. League Of Ancients reserves the rights to end all NFT Public Sales earlier than the stipulated end date
            and time or when all units are sold out.<br>
            8. No refunds will be made on any purchase.<br>
            9. $LOA Tokens will only be available for claim by user (if successfully purchased) after the $LOA Token
            Generation Event (TGE).<br>
            10. $LOA Tokens that are obtained via NFT Public Sale will be vested linearly for 200 days and to be
            released daily after $LOA Token Generation Event (TGE).<br>
            11. Alpha & Beta Capsule HOLDERS will gain Alpha & Beta Access respectively before the official game
            launch.<br>
            12. Each unique wallet which holds at least ONE Alpha Capsule during the Alpha Testing Registration will be
            GUARANTEED a spot for Alpha Access.<br>
            13. Each unique wallet which holds at least ONE Beta Capsule during the Beta Testing Registration will be
            GUARANTEED a spot for Beta Access.
          </span>
        </div>

      </div>
    </div>

    <img class="img-back" src="assets/png/tokenPresaleAsset/PresaleAsset-06.png" alt="" />
  </div>
</section>





<!-- What you Get -->
<section id="detail">
  <div class="container">
    <div class="row ">
      <div class="col-12 text-center">
        <h1>Purchases Breakdown</h1>
      </div>
      <!-- Beta Purchase -->
      <div class="col-md-3 col-12 pt-2">
        <div class="card card_color card-shape">
          <img class="card-img-top" src="assets/png/GG/beta.png" alt="Beta">
          <div class="card-body text-center">
            <h2 class="card-title font_title_size">Beta Capsule</h2>
          </div>

          <div class="card-body">
            <div class="justify-content-center d-flex text-center LOA_border">
              <img class="card__logo img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />
              <span>{{betaAmout * betaMBy}}</span>
            </div>
            <div class="justify-content-center d-flex text-center LOA_border">
              <h4>Capsule: {{betaAmout}}</h4>

            </div>
            <div>

            </div>

          </div>


        </div>
      </div>

      <!-- Alpha Purchase -->
      <div class="col-md-3 col-12 pt-2">
        <div class="card card_color card-shape">
          <img class="card-img-top" src="assets/png/GG/alpha.png" alt="Beta">
          <div class="card-body text-center">
            <h2 class="card-title font_title_size">Alpha Capsule</h2>
          </div>

          <div class="card-body">
            <div class="justify-content-center d-flex text-center LOA_border">
              <img class="card__logo img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />
              <span>{{alphaMBy * alphaAmout}}</span>
            </div>
            <div class="justify-content-center d-flex text-center LOA_border">
              <h4>Capsule: {{alphaAmout}}</h4>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>

      <!-- Genisis Purchase -->
      <div class="col-md-3 col-12 pt-2">
        <div class="card card_color card-shape">
          <img class="card-img-top" src="assets/png/GG/genesis.png" alt="Beta">
          <div class="card-body text-center">
            <h2 class="card-title font_title_size">Genesis Capsule</h2>
          </div>
          <div class="card-body">
            <div class="justify-content-center d-flex text-center LOA_border">
              <img class="card__logo img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />
              <span>{{genesisAmout * genisisMBy}}</span>
            </div>
            <div class="justify-content-center d-flex text-center LOA_border">
              <h4>Capsule: {{genesisAmout}}</h4>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>

      <!-- Immortal Purchase -->
      <div class="col-md-3 col-12 pt-2">
        <div class="card card_color card-shape">
          <img class="card-img-top" src="assets/png/GG/immortal.png" alt="Beta">
          <div class="card-body text-center">
            <h2 class="card-title font_title_size">Immortal Skin</h2>
          </div>
          <div class="card-body">
            <div class="justify-content-center d-flex text-center LOA_border">
              <img class="card__logo img_loa" src="assets/png/tokenPresaleAsset/PresaleAsset_LOA_icon.png" alt="" />
              <span>{{immortalAmout * immortalMBy}}</span>
            </div>
            <div class="justify-content-center d-flex text-center LOA_border">
              <h4>Skin: {{immortalAmout}}</h4>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</section>

<section id="detail">
  <div class="card__divider"></div>
  <div class="row">
    <div class="col-md-6 col-12">
      <div>
        <span> Token Contract Address </span>
        <h5 class="card__desc--orange" style="color:#41c7e3 !important">
          0x94b69263FCA20119Ae817b6f783Fc0F13B02ad50
        </h5>
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div>
        <span> NFT Public Sale Contract Address </span>
        <h5 class="card__desc--orange" style="color:#41c7e3 !important">
          0x1534f1Fe489e4b9DF754bFcdBF92752489171254
        </h5>
      </div>
    </div>
  </div>
  <div class="row justify-content-between">
    <h3 class="col-12 col-md-8 card__title--bold">Audit Report</h3>
  </div>
  <div class="card__divider"></div>
  <div class="row">
    <div class="col-md-6 col-12">
      <div>
        <img class="w-80" src="assets/png/callisto-badge.png" alt="" />
      </div>
    </div>
    <div class="col-md-6 col-12">
      <div class="mt-3">
        <span> Verified By Callisto Security Audit </span>
        <h5 class="card__desc--orange">
          <a href=" https://gist.github.com/yuriy77k/79117d972fdc08aa67414cdf8c8959eb">Audit Report</a>
        </h5>
      </div>
    </div>
  </div>


</section>

<section style="height: auto !important" id="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-lg-4 col-12 col-sm-12 col-xs-12">
        <p class="d-flex justify-content-center mt-3">
          <img class="w-75" src="../../../assets/png/Asset_Footer_Logo.png" alt="" />
        </p>
      </div>
      <div class="col-md-8 col-lg-8 col-12 col-sm-12 col-xs-12 text-left
            m-auto">
        <!-- <div class="row">
                          <div class="col-md-12 col-lg-12 col-12 col-sm-12 col-xs-12">

                          </div>
                      </div> -->
        <h4 class="mt-3 mb-4 footer">FOLLOW US & JOIN THE COMMUNITY!</h4>
        <a class="m-1" href="https://t.me/leagueofancients">
          <img class="w-5" src="../../../assets/png/Asset-SMTG.png" alt="" />
        </a>
        <a class="m-1" href="https://discord.gg/pkuVvrhucR">
          <img class="w-5" src="../../../assets/png/Asset-SMDC.png" alt="" />
        </a>
        <a class="m-1" href="https://web.facebook.com/leagueofancientsofficial/">
          <img class="w-5" src="../../../assets/png/Asset-SMFB.png" alt="" />
        </a>
        <a class="m-1" href="https://www.instagram.com/leagueofancientsofficial/">
          <img class="w-5" src="../../../assets/png/Asset-SMIG.png" alt="" />
        </a>
        <a class="m-1" href="https://twitter.com/LOAOfficialMOBA/">
          <img class="w-5" src="../../../assets/png/Asset-SMTW.png" alt="" />
        </a>
        <a class="m-1" href=" https://www.youtube.com/c/LeagueofAncientsOfficial">
          <img class="w-5" src="../../../assets/png/Asset-SMYT.png" alt="" />
        </a>

        <p class="small-text-footer text-mute mt-4">
          copyright &#169; 2021 - 2023 League Of Ancients All Rights Reserved.
        </p>
      </div>
    </div>
  </div>
</section>