<section>
  <div id="HOME">
    <app-nav></app-nav>
    <div class="">
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
      <div class="snowflake"></div>
    </div>



    <div class="container main-landing justify-content-center text-center ">
      <div>
        <div class=" logo-index col-md-12 col-12 col-sm-12 text-center logo m-auto d-block d-sm-none ">
          <img data-aos="zoom-in" data-aos-duration="2000" class="logo w-75" src="../../../assets/png/Asset_Header_Logo.png" alt="" />
        </div>
        <div>
          <h4 class="mt-3 text-center sub-title-sec" style="color:#EE2FA0 !important">
            Website Maintenance and LP Migration <br>( 1st August 2023 - 1st September 2023)
          </h4>
          <a class="mt-3 text-center sub-title-sec" style="color:#4d28ea !important; font-size:36px !important" target="_blank"
            href="https://leagueofancients.medium.com/follow-up-announcement-loa-busd-staking-halt-and-introduction-of-new-farm-loa-usdt-2abfc9e8c408">
            LOA-BUSD Staking Halt <br>and<br> Introduction of New Farm LOA-USDT
          </a>
        </div>
        <h1 class="mt-3 text-center sub-title-sec">MOBA MADNESS <br />IN THE Metaverse</h1>

        <!-- <div>
          <video class="iframe-header mt-5" width="560" height="315" controls loop  muted autoplay oncanplay="this.play()"  onloadedmetadata="this.muted = true">
            <source src="assets/png/Anantarupa-Revea.mp4" type="video/mp4">
        </video>
        </div>
        <a type="button" target="_black" href="https://medium.com/@leagueofancients/league-of-ancients-reveals-anantarupa-studios-as-official-game-development-partner-be9c22bbc660" class="inv_btn btn-artical btn btn-secondary">
          Read more
        </a> -->
        <!-- <app-countdown-timer></app-countdown-timer> -->
        <div class="mt-5">
          <div class="col pb-3">
            <!-- <div class="line"></div> -->
            <h3 class="sub-title  text-center">
              BECOME A FOUNDING MEMBER • JOIN US NOW
            </h3>
            <div class="d-flex justify-content-center">
              <a class="m-1" href="https://t.me/leagueofancients">
                <img class="icon_width" src="../../../assets/png/Asset-SMTG.png" alt="" />
              </a>
              <a class="m-1" href="https://discord.gg/pkuVvrhucR">
                <img class="icon_width" src="../../../assets/png/Asset-SMDC.png" alt="" />
              </a>
              <a class="m-1" href="https://web.facebook.com/leagueofancientsofficial/">
                <img class="icon_width" src="../../../assets/png/Asset-SMFB.png" alt="" />
              </a>
              <a class="m-1" href="https://www.instagram.com/leagueofancientsofficial/">
                <img class="icon_width" src="../../../assets/png/Asset-SMIG.png" alt="" />
              </a>
              <a class="m-1" href="https://twitter.com/LOAOfficialMOBA/">
                <img class="icon_width" src="../../../assets/png/Asset-SMTW.png" alt="" />
              </a>
              <a class="m-1" href="   https://www.youtube.com/c/LeagueofAncientsOfficial">
                <img class="icon_width" src="../../../assets/png/Asset-SMYT.png" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex justify-content-center">
            <button type="button" (click)="goTo()" class="inv_btn btn btn-secondary">
              Contract Address
            </button>
            <!-- <button type="button" (click)="whitePaper()" class="inv_btn padding_btn btn btn-secondary">
              Whitepaper
            </button> -->
            <!-- <div class="col-12 mt-4 text-left">
              <img class="ga" src="../../../assets/png/Asset2_GPSAAS.png" alt="" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="AVAILABLE_ON" style="height: auto !important;">
    <div class="d-flex justify-content-center">
      <h3 class="yellow-top-text">
        AVAILABLE NOW ON
      </h3>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-4">
          <div class="row">
            <div (click)="ps()" class=" pointer col-md-12 col-12 d-flex justify-content-center">
              <img src="../../../assets/png/Asset-PS.png" class="avail-image-1" />
            </div>
            <div class="col-md-12 col-12 justify-content-center">
              <a (click)="goTo()">
                <h4 class="sub-title mt-4 text-center avail-text">
                  BUY $LOA token with BUSD
                </h4>
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-4">
          <div class="row">
            <div (click)="bs()" class="pointer col-md-12 col-12 d-flex justify-content-center">
              <img src="../../../assets/png/bs.png" class="avail-image-1" />
            </div>
            <div class="col-md-12 col-12 justify-content-center">
              <a (click)="goTo()">
                <h4 class="sub-title mt-4 text-center avail-text">
                  BUY $LOA token with BUSD
                </h4>
              </a>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-4">
          <div class="row">
            <div (click)="abs()" class="pointer col-md-12 col-12 d-flex justify-content-center">
              <img src="../../../assets/png/Asset-APE.png" class="avail-image-2" />
            </div>
            <div class="col-md-12 col-12 justify-content-center">
              <a (click)="goTo()">
                <h4 class="sub-title mt-4 text-center avail-text avail-text">
                  BUY $LOA token with BNB
                </h4>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="d-flex justify-content-center">
      <img class="animated faa-slow avail-bottom-image" src="../../../assets/png/Asset-BSC.png" />
    </div>
  </div>

  <div id="WE_ARE_LISTED" style="height: auto !important; padding-top: 60px;">
    <div class="">
      <div class="col-12 text-center" data-aos="fade-up" data-aos-duration="3000" style="margin-top: 60px;">
        <h2 class="mt-3" style="font-size: 40px; margin-top: 40px;">
          WE ARE LISTED ON
        </h2>

        <div class="col-12">
          <div class="row">
            <div class="col-md-4 col-sm-12 col-lg-4 text-left">
              <div class="row">
                <div class="col-md-12 col-12 d-flex justify-content-center" style="margin-top: 20px;">
                  <img class="binance-bottom" src="../../../assets/png/Asset-CG.png" />
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 col-lg-4 text-right">
              <div class="row">
                <div class="col-md-12 col-12 d-flex justify-content-center" style="margin-top: 20px;">
                  <img class="binance-bottom" src="../../../assets/png/Asset-CMC.png" />
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-12 col-lg-4 text-right">
              <div class="row">
                <div class="col-md-12 col-12 d-flex justify-content-center" style="margin-top: 20px;">
                  <img class="binance-bottom" src="../../../assets/png/gateio.labs.png" />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div style="height: auto !important;" id="MOBA_VERSE">
        <div class="container">
          <div class="space">&nbsp;</div>
          <h2 class="text-uppercase text-center m-4 sub-title-sec" data-aos="fade-up" data-aos-duration="2000">
            DISCOVER THE <br />
            MOBA-VERSE
          </h2>
          <div class="d-flex justify-content-center" data-aos="fade-up" data-aos-duration="2000">
            <p class="mt-4 px-md-4 px-1 text-center sub-title" style="max-width: 900px;">
              A MOBA-Verse where reality and virtual worlds converge into one by allowing gaming communities, eSports
              ambassadors & players and game builders
              to build a virtual metaverse ecosystem. Bringing everyone's gaming asset into the Ancient Realm to build
              an economy and community through
              playing League Of Ancients while monetizing your virtual experience. Immerse yourself into the first
              Metaverse MOBA NFT game through feeling
              truly present with everyone through empowering social blockchain technology within the community.
            </p>
          </div>

          <div class="space" style="height: 200px;">&nbsp;</div>
        </div>
      </div>
    </div>
  </div>

  <div id="FEATURES" style="height: auto !important;">
    <div class="container">
      <!-- <div class="col-12 text-center pt-5">
        <img
          class="animated faa-slow binance"
          src="../../../assets/png/Asset_BSC_Logo.png"
          data-aos="zoom-in"
          data-aos-duration="2000"
          alt=""
        />
      </div> -->
      <!-- <div class="col-12 text-center">
        <h1 class="mt-3 small_bi">
          FIRST FREE-TO-PLAY, <br />
          PLAY-TO-EARN NFT MOBA GAME
        </h1>
      </div> -->
      <div class="" style="padding-top: 80px;">
        <div class="col-md-9 col-12 m-auto">
          <div class="">
            <iframe class="video m-0 w-100 p-0 bo" src="https://www.youtube.com/embed/h1NFYM13_qo"></iframe>
          </div>
        </div>
      </div>
      <div style="height: auto !important;">
        <div class="container">
          <!-- <h2 class="mt-4 text-center" data-aos="fade-up" data-aos-duration="1000">
                          FIRST E-SPORT ON BLOCKCHAIN.
                      </h2>
                      <p class="mt-4 text-center small" data-aos="fade-up" data-aos-duration="2000">
                          A FREE TO PLAY multiplayer online battle arena for mobile (MOBA).<br /> Inspired by Defense of the Ancients (DoTA) and League of Legends (LoL), a custom map where two opposing teams (5 per team) are formed to fight and conquer opponent
                          strongholds to earn tokens through rising in ranks and tournaments. The game will be built on Blockchain enabling players , sponsors and fans to participate in the tournaments through betting and earn NFTs with $LOA tokens.
                      </p> -->
          <div class="space">&nbsp;</div>
          <h2 class="text-uppercase text-center m-4 sub-title-sec" data-aos="fade-up" data-aos-duration="2000">
            Features
          </h2>

          <div class="row" data-aos="fade-up" data-aos-duration="3000">
            <div class="col-md-3 col-lg-3 col-sm-6 col-xs-6 col-6 m-2 m-0-auto text-center">
              <div class="">
                <img class="w-100 img-feature faa-float animated faa-slow" src="../../../assets/png/Asset_Feature-4.png" alt="" />
                <p class="mt-4 text-small px-md-4 px-1">
                  Your hero skins are stored<br />
                  securely on the blockchain.
                </p>
              </div>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-6 col-xs-6 col-6 m-2 m-0-auto text-center">
              <div>
                <img class="img-feature w-100 faa-float animated faa-slow" src="../../../assets/png/Asset_Feature-3.png" alt="" />
                <p class="mt-4 text-small px-md-4 px-1">
                  Trade hero skins or fuse <br />
                  them together to get <br />
                  greater rewards!
                </p>
              </div>
            </div>

            <div class="col-md-3 col-lg-3 col-sm-6 col-xs-6 col-6 m-2 m-0-auto text-center">
              <span>
                <img class="img-feature w-100 faa-float animated faa-slow" src="../../../assets/png/Asset_Feature-2.png" alt="" />
                <p class="mt-4 text-small px-md-4 px-1">
                  Increase your $LOA token yields <br />
                  by holding onto your NFT skins.
                </p>
              </span>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-6 col-xs-6 col-6 m-2 m-0-auto text-center">
              <span>
                <img class="w-100 img-feature faa-float animated faa-slow" src="../../../assets/png/Asset_Feature-1.png" alt="" />
                <p class="mt-4 px-md-4 px-1 text-small">
                  Battle it out in the Ancient <br />
                  Realm for FREE and earn $LOA <br />
                  while doing it.
                </p>
              </span>
            </div>
          </div>
          <div class="space">&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="GAMEPLAY" class="section-100 d-flex">
  <div class="container m-auto">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 m-auto map">
        <img class="img-map faa-float animated faa-slow" src="../../../assets/png/Asset_Game_Map.png" data-aos="zoom-in" data-aos-duration="2000" alt="" />
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center m-auto">
        <h2 class="mb-4 text-uppercase text-align-center-left sub-title-sec">Play More <br />& Earn More !</h2>
        <p class="text-small text-align-center-left text-light-1">
          Engage in epic 5v5 strategic warfare in League of Ancients. 5 Heroes fight for Luminous and 5 for the Dark
          Souls. Your goal? Destroy the opposing teams Beacon of Power. Sounds easy? Maybe... Do you work as a team? Do
          you use terrain to your advantage? Do you strategically ambush the enemy? Do you gather gold and upgrade your
          heroes? Your skill, teamwork, wit and determination shall decide whether you return home a hero, or in a
          box...
        </p>
        <div class="row pb-md-5">
          <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6 col-6 mr-2">
            <img class="w-100" src="../../../assets/png/Asset_Team Icon-13.png" alt="" />
          </div>
          <div class="col-md-6 col-sm-6 col-lg-6 col-xs-6 col-6 mr-2">
            <img class="w-100" src="../../../assets/png/Asset_Team Icon-14.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="LOA-TOKEN-MAP">
  <section id="LOA-TOKEN">
    <div class="container">
      <div class="row">
        <h3 class="text-uppercase text-center sub-title-sec mt-2">
          $LOA Token
        </h3>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 d-flex">
          <img class="m-auto w-75" data-aos="zoom-in" data-aos-duration="2000" src="../../../assets/png/Asset_LOA_TOKEN.png" alt="" />
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 mt-4 m-auto">
          <div class="col-md-10 col-10 m-auto m-md-2 text-align-center-left">
            <p class="light-text mt-5">
              The backbone of our economy. Next to gameplay, NFT game tokens are the most important feature of a
              blockchain game. Token usages determine how the in-game economy will develop, players can achieve a
              rewarding experience. Through...
            </p>
          </div>
          <div class="col-12 col-md-12 m-auto">
            <div class="col-md-12 col-12 m-2 d-flex">
              <div>
                <img class="w-20 mr-1" src="../../../assets/png/Asset_Token_Icon-19.png" alt="" />
              </div>
              <div>
                <h4 class="font-sm mobile_font">Participation <br /></h4>
                <h6 class="font-sm __font mobile_ds">
                  Join in on a variety of special events, including tournaments,
                  <br />
                  raffles, gambling and draws; potentially earning far greater rewards!
                </h6>
              </div>
            </div>
            <div class="col-md-12 col-12 m-2 d-flex">
              <div>
                <img class="w-20 mr-1" src="../../../assets/png/Asset_Token_Icon-20.png" alt="" />
              </div>
              <div>
                <h4 class="font-sm mobile_font">LOA Staking</h4>
                <h6 class="font-sm __font mobile_ds">
                  Use your $LOA tokens to earn more currency! Watch it rise
                  <br />
                  in value as $LOA inevitably conquers the NFT game market
                </h6>
              </div>
            </div>
            <div class="col-md-12 col-12 m-2 d-flex">
              <div>
                <img class="w-20 mr-1" src="../../../assets/png/Asset_Token_Icon-21.png" alt="" />
              </div>
              <div>
                <h4 class="font-sm mobile_font">In Game Payment</h4>
                <h6 class="font-sm __font mobile_ds">
                  Use $LOA tokens to make in-game purchases, gaining new <br />
                  heroes skins or access to special events
                </h6>
              </div>
            </div>
            <!-- <div class="col-md-12 col-12 mr-2 __margin d-flex">
              <div>
                <img class="w-20 mr-1" src="../../../assets/png/Asset_Token_Icon-22.png" alt="" />
              </div>
              <div>
                <h4 class="font-sm mobile_font">GOVERNANCE</h4>
                <h6 class="font-sm __font mobile_ds">
                  League of Ancients is designed to have a player driven economy, that <br />
                  economies growth is contigent upon our player base <br />
                  earning, spending and staking $LOA tokens
                </h6>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="container" style="height: 100%;">
      <h2 class="text-center sub-title-sec">BACKERS / INVESTORS</h2>

      <div class="row" style="margin: 20px; display: flex; background-color: white; border-radius: 30px; padding: 20px;">
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-32.jpeg" alt="" class="investor_icon" style="padding-top: 30%;" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\inCollage_20220104_161701204.png" alt="" class="investor_icon" style="padding-top: 22%;" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-01.jpg" alt="" class="investor_icon" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-31.jpg" alt="" class="investor_icon" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-11.jpg" alt="" class="investor_icon" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-03.jpg" alt="" class="investor_icon" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-02.jpg" alt="" class="investor_icon" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-06.jpg" alt="" class="investor_icon" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-04.jpg" alt="" class="investor_icon" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-05.jpg" alt="" class="investor_icon" />
        </div>

        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-07.jpg" alt="" class="investor_icon" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-08.jpg" alt="" class="investor_icon" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-09.jpg" alt="" class="investor_icon" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-10.jpg" alt="" class="investor_icon" />
        </div>

        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-12.jpg" alt="" class="investor_icon" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-13.jpg" alt="" class="investor_icon" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-14.jpg" alt="" class="investor_icon" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-15.jpg" alt="" class="investor_icon" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-16.jpg" alt="" class="investor_icon" />
        </div>
        <div class="col-lg-3 col-4">
          <img src="assets\png\investors\investor_logo-17.jpg" alt="" class="investor_icon" />
        </div>


      </div>
    </div>
  </section>

  <section id="ROAD-MAP">
    <div class="container" style="height: 100%;">
      <h2 class="text-center sub-title-sec">ROADMAP</h2>
      <div id="timeline-container">
        <div class="d-flex justify-content-start timeline-width">
          <div class="timeline-content">
            <h2 class="row title">Phase 1</h2>
            <!-- <h4 class="row subtitle">Q2 2021</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Ideation</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Team formation</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Brand and strategy</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Game logic development</p>
            </div>
          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 2</h2>
            <!-- <h4 class="row subtitle">Q3 2021</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Social media channels launch</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Trailer video</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Pre-marketing & landing page</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Seed Fund</p>
            </div>
          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 3</h2>
            <!-- <h4 class="row subtitle">Q4 2021</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Website Launch</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Whitepaper</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Private Sale</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Smart Contract Audit Completion</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Pre-Sale</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>NFT Sale</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Coingecko Listing</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>CoinMarketcap Listing</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Token Generation Event</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Initial Dex Offering</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Centralized Exchange Listing</p>
            </div>

          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 4</h2>
            <!-- <h4 class="row subtitle">Q1 2022</h4> -->
            <div class="d-flex justify-content-start" style="padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>NFT Marketplace BETA test</p>
            </div>
            <!-- <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">NFT Marketplace Launch</p>
            </div> -->
          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 5</h2>
            <!-- <h4 class="row subtitle">Q2 2022</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>LOA Yield Farming</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Whitepaper Update</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <img src="assets\png\check.png" alt="" style="height: 20px; margin: 3px 5px 3px -15px;" />
              <p>Guild Partnerships</p>
            </div>
          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 6</h2>
            <!-- <h4 class="row subtitle">Q3 2022</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Public Testnet and Bug Bounty Event</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Marketplace Raffle</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">NFT Inventory</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Capsule Unlocking</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Partner Staking Pools</p>
            </div>
          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 7</h2>
            <!-- <h4 class="row subtitle">Q4 2022</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">New Website Launch</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Referral Program</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">In-game Map Integration</p>
            </div>
          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 8</h2>
            <!-- <h4 class="row subtitle">Q4 2022</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">NFT Trading</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">NFT Listing</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">NFT Transfer</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">NFT Skin Staking</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Account Level and Leaderboard</p>
            </div>
          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 9</h2>
            <!-- <h4 class="row subtitle">Q4 2022</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">NFT Fusion</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">LOA Official Soundtrack Release</p>
            </div>
          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 10</h2>
            <!-- <h4 class="row subtitle">Q4 2022</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Axion's Sphere (Attribute Rerolls)</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">In-game UI Integration</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">In-game Audio Integration</p>
            </div>
          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 11</h2>
            <!-- <h4 class="row subtitle">Q4 2022</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Governance Token</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">6 Playable Heroes</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Pre-Alpha Testing (Dev Team)</p>
            </div>
          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 12</h2>
            <!-- <h4 class="row subtitle">Q4 2022</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Alpha Launch (Alpha Capsule Holders)</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Esports Partnership</p>
            </div>
          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 13</h2>
            <!-- <h4 class="row subtitle">Q4 2022</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">10 Playable Heroes</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Closed Beta Test (Alpha & Beta Capsule Holders)</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Ambassador Program</p>
            </div>
          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 14</h2>
            <!-- <h4 class="row subtitle">Q4 2022</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Open Beta Test (Public)</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Beta Player Referral Program</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Esports Tournament Ecosystem Partner</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">NFT Land</p>
            </div>
          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 15</h2>
            <!-- <h4 class="row subtitle">Q4 2022</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Google Play Store Publishing</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Official Game Launch</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Limited Merch Release</p>
            </div>
          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 16</h2>
            <!-- <h4 class="row subtitle">Q4 2022</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">iOS App Store Publishing</p>
            </div>
          </div>

          <div class="timeline-content">
            <h2 class="row title">Phase 17</h2>
            <!-- <h4 class="row subtitle">Q4 2022</h4> -->
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Live Streaming Integration</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">Battle Pass</p>
            </div>
            <div class="d-flex justify-content-start" style="clear: both; padding-left: 0px;">
              <p style="margin-left: -10px;">First Esports Tournament Launch</p>
            </div>
          </div>
        </div>

        <div class="row timeline-ul timeline-width">
          <ul class="timeline">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <button type="button" (click)="slide('left')" class="inv_btn btn btn-secondary" style="width: 200px;">
          <b>
            < </b> PREV
        </button>
        <button type="button" (click)="slide('right')" class="inv_btn padding_btn btn btn-secondary" style="width: 200px;">
          NEXT <b>></b>
        </button>
      </div>

      <!-- <div style="margin: auto; display: flex;">
        <div class="col-12 m-auto">
          <img class="d-none d-md-flex m-auto" src="assets\png\Asset2_Roadmap.png" alt="" />
        </div>
      </div> -->
    </div>
  </section>
</section>

<app-footer></app-footer>