<div id="main">
    <app-nav></app-nav>
    <section>
  
      <div class="text-center">
        <h1 class="header_para mt-5">
         PARTNERS
        </h1>
        <h6 class="font-sm __font mobile_ds">
          Strategic partners from different areas of expertise all rolled into one.

        </h6>
      </div>
    </section>
  </div>
  
  
  
  
  <section id="logo">
    <div class="text-center">
      
  
      <div class="card">
          <div class="row">
            <div class="col-4 col-md-3" *ngFor="let logo of listLogo">

             
                    <img class="w-100" [src]="logo" alt="">
               
            </div>
          </div>
          
      </div>
    </div>
  </section>
 
  <section id="join" class="text-center">
    <div class="col-12 text-center header_text pt-4">
      <h1 class="header_para">JOIN THE ARMY TODAY!</h1>
      <h6 class="font-sm __font mobile_ds">Building the largest MOBA-Verse NFT Game</h6>
    </div>
  
    <button  class="btn button-border mt-5"> SEND RESUME</button>
  
  
  </section>
  
  <app-footer></app-footer>