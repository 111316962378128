import { HeroPageComponent } from './components/hero-page/hero-page.component';
import { HeroDetailComponent } from './components/hero-page/hero-detail/hero-detail.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { APPCONFIG, APP_CONFIG } from './config/config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpHelper } from './helper/http-helper';
import { Web3HelperPro } from './Helper/web3-helper_pro';
import { HashStr } from './helper/hash-str';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { CommonModule } from '@angular/common';
import { PresaleComponent } from './components/presale/presale.component';
import { OurTeamComponent } from './components/our-team/our-team.component';
import { NavComponent } from './components/nav/nav.component';
import { TokenPresaleComponent } from './components/token-presale/token-presale.component';
import { PresaletestComponent } from './components/presaletest/presaletest.component';
import { PartnershipComponent } from './components/partnership/partnership.component';
import { PrivateSaleComponent } from './components/private-sale/private-sale.component';
import { PublicSaleComponent } from './components/public-sale/public-sale.component';
import { CountdownTimerComponent } from './components/main/countdown-timer/countdown-timer.component';

import { ClipboardModule } from 'ngx-clipboard';
import { ReferralComponent } from './components/referral/referral.component';
import { ReferralPageModule } from './pages/referral-page/referral-page.module';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,

    PresaleComponent,
    OurTeamComponent,
    NavComponent,
    TokenPresaleComponent,
    PresaletestComponent,
    PartnershipComponent,
    PrivateSaleComponent,
    PublicSaleComponent,
    FooterComponent,
    HeroDetailComponent,
    HeroPageComponent,
    CountdownTimerComponent,
    ReferralComponent,



  ],
  exports:[ FooterComponent,],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FontAwesomeModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    RecaptchaFormsModule,
    FormsModule,
    CommonModule,
    RecaptchaModule,
    ClipboardModule,

    CommonModule,
    ReferralPageModule




  ],
  providers: [{ provide: APP_CONFIG, useValue: APPCONFIG, },
    HttpHelper,
    Web3HelperPro,
    HashStr],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
